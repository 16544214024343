import { Injectable } from '@angular/core';
import { Client, createInstance, Event, EventDispatcher, OptimizelyDecision, OptimizelyUserContext } from '@optimizely/optimizely-sdk';
import { v4 as uuidv4 } from 'uuid';
import { ConfigService } from '../config.service';
import { WindowRefService } from '../window.service';
import { ExperimentsService } from '../experiments.service';
import { IActiveViewports, ViewportDetectionService } from '../viewport-detection.service';
import { EventTags } from '@optimizely/optimizely-sdk/lib/shared_types';
import { HttpClient } from '@angular/common/http';
import { Experiment } from 'Shared/classes/experiment';
import { ReturningCustomerService } from '../returning-customer.service';
import { StateService } from '../state.service';
import { LocalStorageService } from '../local-storage.service';
import { LogLevel } from '@optimizely/js-sdk-logging';

interface Attribute {
  key: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class OptimizelyService {
  optimizelyClient: Client;
  optimizelyUserContext: OptimizelyUserContext;
  decisions: Record<string, OptimizelyDecision>;
  customEventDispatcher: EventDispatcher;
  debug: boolean = false;
  window: Window = this.windowRef.nativeWindow;

  public statisticsCookieAccepted: boolean = false;
  public trackingEnabled: boolean = false;

  private tempOptimizelyUserId: string;

  constructor(
    private configService: ConfigService,
    private windowRef: WindowRefService,
    private experimentService: ExperimentsService,
    private viewPortDetectionService: ViewportDetectionService,
    private http: HttpClient,
    private returningCustomerService: ReturningCustomerService,
    private stateService: StateService,
    private localStorageService: LocalStorageService
  ) {
    // Check if we are in debug mode
    this.debug = this.window?.location?.search.indexOf('analyticsDebug=true') > -1;

    this.customEventDispatcher = {
      dispatchEvent: (event: Event, callback): void => {
        this.http
          .post(event.url, event.params, {
            headers: { 'Content-Type': 'application/json' }
          })
          .subscribe({
            next: (response): void => {
              if (this.debug) {
                console.log('<Optimizely> Event', event);
                console.log('<Optimizely> Event dispatched:', response);
              }
            },
            error: (error): void => {
              if (this.debug) {
                console.error('<Optimizely> Error:', error);
              }
            },
            complete: (): void => {
              if (this.debug) {
                console.log('<Optimizely> Completed');
              }
            }
          });
      }
    };
  }

  /**
   * Initialise Optimizely, Setup User Context & Get decisions for the user.
   * @returns
   */
  init(): Promise<void> {
    if (window && window['optimizelyDatafile']) {
      this.optimizelyClient = createInstance({
        eventDispatcher: this.trackingEnabled
          ? this.customEventDispatcher
          : {
              dispatchEvent: (event: Event, callback: (response: { statusCode: number }) => void): void => {}
            },
        datafile: window['optimizelyDatafile'],
        datafileOptions: {
          autoUpdate: true,
          updateInterval: 6000
        },
        logLevel: 'ERROR',
        // Custom logger to handling all types of logging
        // Binding is needed due to this not being available as function call is within sdk instance
        logger: {
          log: this.handleLogging.bind(this)
        }
      });

      // Promise will resolve if the datafile still is not available after the timeout limit
      return this.optimizelyClient.onReady({ timeout: 5000 }).then((result): void => {
        // True if the instance fetched a datafile and is now ready to use
        if (result.success) {
          // Check if the user already has an assigned user ID.
          let userId: string = this.windowRef.getCookie('OptimizelyUserID');

          // If the user has not accepted the statistics cookie, we need to generate and store a user ID only temporarily.
          if (!userId && !this.tempOptimizelyUserId && !this.statisticsCookieAccepted) {
            // We need to generate and temporarily store a user ID.
            userId = uuidv4();
            this.tempOptimizelyUserId = userId;
          }

          // If the user has accepted the statistics cookie and there is a temporary Optimizely ID set,
          // we need to set the Optimizely user ID.
          if (!userId && this.tempOptimizelyUserId && this.statisticsCookieAccepted) {
            // Get the temporary Optimizely user ID.
            userId = this.tempOptimizelyUserId;
            // Set the Optimizely user ID as a cookie for 6 months
            this.windowRef.setCookie('OptimizelyUserID', userId, 180);
          }

          // Extract device type to determine Optimizely Audience
          const device = this.extractDevice(this.viewPortDetectionService.activeViewports);

          const attributes = {
            platform: 'web',
            market: this.configService.getConfig().site,
            device: device === 'largeTablet' || device === 'mediumTablet' ? 'tablet' : device,
            visitor_type: this.returningCustomerService.isReturning ? 'returning' : 'new',
            price_test_exclusion_flag: this.excludeUserFromPriceTest()
          };

          this.optimizelyUserContext = this.optimizelyClient.createUserContext(userId, attributes); // update cookie exp limit.
        }
      });
    }
  }

  /**
   * Bucket the user into a variant for the experiment ID provided
   * @param id - ID of Optimizely Experiment
   * @param attribute - attribute object containing key & value
   */
  // eslint-disable-next-line complexity
  decide(id: string, attribute?: Attribute): void {
    if (this.optimizelyUserContext && this.configService.getConfig().optimizelyEnabled) {
      if (this.excludeUserFromExperiment()) {
        return;
      }

      if (attribute && (id === 'hpt42_bw_uk_web_klarna' || id === 'new_klarna_payment_options')) {
        this.optimizelyUserContext.setAttribute(attribute.key, attribute.value);
      }

      if (
        (id === 'api_hpt108_paid_shipping_and_gc_rebuild' || id === 'api_hpt109_paid_shipping_and_gc_rebuild') &&
        this.excludeUserFromPaidShippingAndGiftCardTest()
      ) {
        return;
      }

      /**
       * Sku & Shipping Price Tests Keys are hardcoded, this is because price tests are run very frequently
       * and we don't want to do a code release every time a price test is setup.
       */

      let key = id;

      // If the user is excluded from the price test, don't bucket them into a variant.
      if (id === 'sku_price_test' && this.excludeUserFromPriceTest()) {
        return;
      }

      if (id === 'sku_price_test') {
        key = this.configService.getConfig().optimizelyExperimentKeys.sku;
      }

      if (id === 'shipping_price_test') {
        key = this.configService.getConfig().optimizelyExperimentKeys.shipping;
      }

      if (id === 'shipping_method_test') {
        key = this.configService.getConfig().optimizelyExperimentKeys.shippingMethod;
      }

      if (id === 'navigation_test') {
        key = this.configService.getConfig().optimizelyExperimentKeys.navigation;
      }

      if (id === 'range_test') {
        key = this.configService.getConfig().optimizelyExperimentKeys.range;
      }

      if (id === 'content_card_test') {
        key = this.configService.getConfig().optimizelyExperimentKeys.contentCard;
      }

      const decision = this.optimizelyUserContext.decide(key);

      /**
       * The if statement below is a fix put in place in November 2023 which effectively fixes a sync issue between Optimizely
       * and Heap.  If an Optimizely experiment is set to target 60% of users and then split the testing users 50/50 for two
       * variants - then the remaining 40% (that are not part of the test) SHOULD NOT be tracked via Heap.
       * The code below makes sure that we have valid variation keys and flag keys before we set up the experiment object
       * which in turn stops the heap event firing for users that are excluded from the control.
       */

      if (
        decision.variationKey === 'on' ||
        decision.variationKey === 'off' ||
        decision.variationKey === null ||
        decision.flagKey === null
      ) {
        return;
      }

      // Get experiments currently active on teh site.
      const experiments = this.experimentService.experimentsObj$.getValue();

      // Only create an Experiment Object if the ID does not exist on our active experiments
      // i.e it's a new experiments. This is to stop the below code from running everytime we run
      // this method.
      if (Object.values(experiments).every((exp): boolean => exp.id !== id)) {
        // Create our experiment object
        const experimentObj = this.experimentService.createExperiment({
          id: decision.flagKey,
          variant: (decision?.variables.variant || 0) as number // Need to find a better solution.
        });

        // Add it to the list of active experiments on the site
        this.experimentService.addExperiment(experimentObj);
      }
    }
  }

  /**
   * Tracking Optimizely Events
   * @param eventName
   * @param tags
   */
  trackEvent(eventName: string, tags: EventTags = {}): void {
    // if trackingEnabled (set via cookie service) is true and the user context exists
    if (this.trackingEnabled && this.optimizelyUserContext && this.configService.getConfig().optimizelyEnabled) {
      // Send the event to Optimizely
      this.optimizelyUserContext.trackEvent(eventName, tags);
    }
  }

  /**
   * The re-decide method is called when the user accepts the cookies.
   * Once the user accepts the cookies, we need to re-decide the experiments
   * this makes sure we are starting to track the user from the point they accept the cookies.
   */
  redecide(): void {
    const experiments = this.experimentService.experimentsObj$.getValue();

    Object.values(experiments).forEach((exp: Experiment): void => {
      this.decide(exp.id);
    });
  }

  /**
   * Based on the view port returns device type (desktop, mobile, tablet)
   * @param viewPorts
   * @returns
   */
  private extractDevice(viewPorts: IActiveViewports): string {
    const viewPort = Object.keys(viewPorts).filter((key): string => viewPorts[key]);
    return viewPort[0];
  }

  /**
   * Checks if the user should be excluded from the experiment
   * @returns {boolean}
   */
  private excludeUserFromExperiment(): boolean {
    const state = this.stateService.getInitial();

    return state?.params && Object.keys(state?.params).includes('experiment');
  }

  /**
   * Checks if the user should be excluded from the price test
   * @returns
   */
  private excludeUserFromPriceTest(): boolean {
    const state = this.stateService.getInitial();

    if (
      Object.values(state?.params).includes('product-feed') ||
      Object.values(state?.params).includes('performancemax') ||
      state?.params?.utm_campaign === 'discount' ||
      Object.values(state?.params).includes('facebook') ||
      state?.params?.fbclid ||
      state?.params?.gclid
    ) {
      return true;
    }

    return false;
  }

  /**
   * Checks if the user should be excluded from the paid shipping and gift card test
   * @returns
   */
  private excludeUserFromPaidShippingAndGiftCardTest(): boolean {
    const excludedUtmSources = ['product-feed'];
    const excludedUtmMediums = ['performancemax', 'paid', 'cpc'];
    const excludedUtmCampaigns = ['discount'];
    const state = this.stateService.getInitial();
    const params = state?.params;

    // Check if utm_source contains or equals any value from excludedUtmSources
    const excludeUtmSource: boolean = excludedUtmSources.some(
      (value: string): boolean => params?.utm_source?.includes(value) || params?.utm_source === value
    );

    // Check if utm_medium contains any value from excludedUtmMediums
    const excludeUtmMedium: boolean = excludedUtmMediums.some((value: string): boolean => params?.utm_medium?.includes(value));

    // Check if utm_campaign contains or equals any value from excludedUtmCampaigns
    const excludeUtmCampaign: boolean = excludedUtmCampaigns.some(
      (value: string): boolean => params?.utm_campaign?.includes(value) || params?.utm_campaign === value
    );

    return excludeUtmSource || excludeUtmMedium || excludeUtmCampaign;
  }

  /**
   * Handle logging for Optimizely
   * @param {LogLevel} level
   * @param {string} message
   */
  private handleLogging(level: LogLevel, message: string): void {
    if (this.debug) {
      const levelKey = Object.keys(LogLevel).find((key): boolean => LogLevel[key] === level);
      const consoleMessage = `[Optimizely] ${levelKey}: ${message}`;
      switch (level) {
        case LogLevel.INFO:
          console.log(consoleMessage);
          break;
        case LogLevel.DEBUG:
          console.log(consoleMessage);
          break;
        case LogLevel.WARNING:
          console.warn(consoleMessage);
          break;
        case LogLevel.ERROR:
          console.error(consoleMessage);
          break;
        default:
          console.log(consoleMessage);
          break;
      }
    }
  }
}
