
<div class="bw-auth-login">
  <ng-container *ngIf="showFullForm">
    <div class="modal-body modal-body-slim" #bwModal>
      <bw-form-text-input [bwFormControl]="controls.email" label="Your email" inputType="text" (didFocus)="formFocus()">
        <ng-container role="errors">
          <div for="pattern"><span *ngIf="showValidationMessage">Please enter a valid email address, (e.g johndoe@domain.com)</span></div>
          <div for="invalid"><span *ngIf="showValidationMessage">Please enter a valid email address, (e.g johndoe@domain.com)</span></div>
        </ng-container>
      </bw-form-text-input>
      <div class="form-items-icon">
        <bw-form-text-input [bwFormControl]="controls.password" label="Password" inputType="password" (didFocus)="formFocus()">
          <ng-container role="errors">
            <div for="invalid"><span *ngIf="showValidationMessage">Please enter your password</span></div>
          </ng-container>
        </bw-form-text-input>
        <bw-alert class="forgot-password-alert" *ngIf="showFailedLogin" header="Sorry, we don’t recognise those details." alertStyle="warning"> Double-check you’ve typed them both correctly and try again.</bw-alert>
        <div class="forgot-password">
          <bw-button buttonStyle="text-link" (click)="resetPassword()" bwTrackAs="component.auth-login.auto_key.string_5">Forgot password?</bw-button>
        </div>
        <bw-alert class="forgot-password-alert" *ngIf="requestResetPasswordSent" alertStyle="successInfo"> We’ve sent you an email with a link to reset your password.</bw-alert>
      </div>
      <!-- Login with email-->
      <bw-button buttonStyle="modal-primary" [fullWidth]="true" size="medium" bwTrackAs="checkout.userDetails.login" (clicked)="submit()"><span>Log in</span></bw-button>
      <bw-auth-third-party [showSeparator]="false" [isModal]="!checkoutOrigin" (didSucceed)="this.loginSuccess.emit($event)" (didFail)="didFailLogin()" (didLoad)="onLoading($event)"></bw-auth-third-party>
    </div>
    <div class="modal-footer modal-footer-small center text-center" footerContent>
      <div class="modal-footer__other text-center" *ngIf="enableRegister">
        <div><span>New customer? &nbsp;</span>
          <bw-button buttonStyle="text-link" (clicked)="tab('register')" bwTrackAs="checkout.userDetails.openCreateAccountModal">Create account</bw-button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!showFullForm">
    <div [ngClass]="{'modal-body modal-body-slim' : origin === 'default', 'modal-body-slim--no-top': isJoiningRewardsOnLogin}" #bwModal>
      <ng-container *ngIf="!showPasswordControl">
        <ng-container *ngIf="isJoiningRewardsOnLogin">
          <ui-box box_space="none" box_space_top="near" box_space_bottom="near">
            <ui-stack stack_gap="near">
              <ng-container *ngIf="origin !== 'checkout' &amp;&amp; !order">
                <p class="text-body--default" *inExperiment="{name: 'AUTO_OPT_IN_TO_REWARDS', variants: [1]}"> Log in or create an account to join Bloom & Wild Rewards, and you’ll enjoy all these perks:</p>
                <p class="text-body--default" *inExperiment="{name: 'AUTO_OPT_IN_TO_REWARDS', variants: [2]}"> Log in or create an account to join Bloom & Wild Rewards.</p>
              </ng-container>
              <ui-box *ngIf="origin === 'checkout'" box_space="none">
                <p class="text-body--default text-center" *inExperiment="{name: 'AUTO_OPT_IN_TO_REWARDS', variants: [1]}">Log in or create an account to join Bloom & Wild Rewards, and earn <span class="text-body--emphasis">{{loyaltyOrderPoints}}</span> points on this order. You’ll also get {{loyaltyWelcomePoints}} welcome points and all these lovely perks:</p>
                <p class="text-body--default text-center" *inExperiment="{name: 'AUTO_OPT_IN_TO_REWARDS', variants: [2]}">Log in or create an account to join Bloom & Wild Rewards, and earn <span class="text-body--emphasis">{{loyaltyOrderPoints}}</span> points on this order.</p>
              </ui-box>
              <ng-container *ngIf="origin !== 'checkout' &amp;&amp; order">
                <p class="text-body--default" *inExperiment="{name: 'AUTO_OPT_IN_TO_REWARDS', variants: [1]}">Log in or create an account to join Bloom & Wild Rewards, and earn <span class="text-body--emphasis">{{loyaltyOrderPoints}}</span> points on this order. You’ll also get {{loyaltyWelcomePoints}} welcome points and all these lovely perks:</p>
                <p class="text-body--default" *inExperiment="{name: 'AUTO_OPT_IN_TO_REWARDS', variants: [2]}">Log in or create an account to join Bloom & Wild Rewards, and earn <span class="text-body--emphasis">{{loyaltyOrderPoints}}</span> points on this order.</p>
              </ng-container>
              <ui-box *inExperiment="{name: 'AUTO_OPT_IN_TO_REWARDS', variants: [2]}" box_space="tight" box_border_width="primary" box_border_color="secondary" box_border_radius="layout">
                <ui-stack stack_gap="near" stack_direction="x" stack_align="center">
                  <ui-image class="bw-auth-login__joining-image" image_src="/assets/images/rewards/earn-points.svg" loading="lazy" image_alt_text="Earn points"></ui-image>
                  <p class="text-body--default" *ngIf="origin !== 'checkout' &amp;&amp; !order">It’s free to join, and you’ll get {{loyaltyWelcomePoints}} welcome points.</p>
                  <p class="text-body--default" *ngIf="origin === 'checkout'">It’s free to join, and you’ll get {{loyaltyWelcomePoints}} welcome points (on top of your order points).</p>
                  <p class="text-body--default" *ngIf="origin !== 'checkout' &amp;&amp; order">It’s free to join, and you’ll get {{loyaltyWelcomePoints}} welcome points (on top of your order points).</p>
                </ui-stack>
              </ui-box>
            </ui-stack>
            <ng-template #rewardsPerks let-alignment="alignment" let-stackDirection="stackDirection">
              <ui-box *inExperiment="{name: 'AUTO_OPT_IN_TO_REWARDS', variants: [2]}" box_space="none" box_space_bottom="tight">
                <p class="text-body--supporting text-color--supporting text-body--emphasis hidden-tablet hidden-laptop hidden-desktop">Plus all these lovely perks:</p>
                <p class="text-body--supporting text-color--supporting text-body--emphasis hidden-mobile" [ngClass]="{'text-center': alignment === 'center'}"> Plus all these lovely perks:</p>
              </ui-box>
              <ui-stack stack_gap="near" [stack_direction]="stackDirection" [stack_distribute]="alignment" stack_collapse_below="tablet" [stack_wrap]="true">
                <ui-box *ngFor="let item of [].constructor(4); let i = index" box_space="none" box_fill_mode="fit">
                  <ui-stack stack_gap="tight" stack_direction="x" stack_align="center">
                    <ui-box box_background="rewards-tertiary" box_space="tiny" box_fill_mode="fit" box_border_radius="rewards-icon">
                      <ui-icon icon_name="tick"></ui-icon>
                    </ui-box>
                    <p class="text-body--supporting" *ngIf="i === 0">£5 off every 500 points</p>
                    <p class="text-body--supporting" *ngIf="i === 1">Birthday treat</p>
                    <p class="text-body--supporting" *ngIf="i === 2">Save your addresses</p>
                    <p class="text-body--supporting" *ngIf="i === 3">Easy order tracking</p>
                  </ui-stack>
                </ui-box>
              </ui-stack>
            </ng-template>
            <ui-box *ngIf="origin !== 'checkout'" box_space="near" box_space_left="none" box_space_right="none">
              <ui-stack stack_gap="near">
                <ng-template [ngTemplateOutlet]="rewardsPerks" [ngTemplateOutletContext]="{alignment: 'start', stackDirection: 'y'}"></ng-template>
                <p class="text-body--supporting text-color--supporting">You can <a class="text-link--paragraph" href="/bw-rewards-terms" target="_blank">read our full T&Cs here</a>.</p>
              </ui-stack>
            </ui-box>
            <ui-box class="hidden-mobile" *ngIf="origin === 'checkout'" box_space="near" box_space_bottom="default">
              <ng-container [ngTemplateOutlet]="rewardsPerks" [ngTemplateOutletContext]="{alignment: loyaltyPerksAlignment ?? 'center', stackDirection: 'x'}"></ng-container>
            </ui-box>
            <ui-box class="hidden-tablet hidden-laptop hidden-desktop" *ngIf="origin === 'checkout'" box_space="near" box_space_left="none" box_space_bottom="default">
              <ng-container [ngTemplateOutlet]="rewardsPerks" [ngTemplateOutletContext]="{alignment: loyaltyPerksAlignment ?? 'center', stackDirection: 'x'}"></ng-container>
            </ui-box>
            <ui-box *ngIf="origin === 'checkout'" box_space="none" box_space_bottom="near" box_align_x="center">
              <ui-stack stack_gap="tight" stack_direction="x" stack_distribute="center" stack_align="center">
                <ui-box box_border_radius="round" box_border_width="primary" box_space="none" box_fill_mode="fit">
                  <ui-icon icon_name="info" icon_size="small"></ui-icon>
                </ui-box><a class="text-link--paragraph text-body--supporting" (click)="openRewardsInfoModal()" bwTrackAs="component.detail-user.login-info.rewards-link">What’s Bloom & Wild Rewards?</a>
              </ui-stack>
            </ui-box>
          </ui-box>
        </ng-container>
        <bw-auth-login-email [email]="controls.email" [origin]="origin" [showThirdPartySeparate]="origin === 'default' || origin === 'occasions'" [showSeparator]="origin !== 'default'" [showUserGuest]="origin === 'checkout'" [order]="order" [checkEmailOnInit]="checkEmailOnInit" (didSubmit)="submitEmail($event)" (didSuccessLogin)="this.loginSuccess.emit($event)" (didFailLogin)="didFailLogin()" (registerNewUser)="goToRegisterNewUser($event)" (registerGuestUser)="goToRegisterGuestUser($event)" (didGuestUser)="continueAsGuest.emit($event)"></bw-auth-login-email>
      </ng-container>
      <ng-container *ngIf="showPasswordControl">
        <ng-container *ngIf="inJoiningRewardsExperiment">
          <ui-box *ngIf="isJoiningRewardsOnLogin" box_space_top="near" box_space_left="none" box_space_right="none">
            <ui-stack stack_gap="near">
              <ng-container *ngIf="origin !== 'checkout'">
                <p class="text-body--default" *ngIf="!order">Log in to join Bloom & Wild Rewards, and enjoy all these lovely perks:</p>
                <p class="text-body--default" *ngIf="order">Log in to join Bloom & Wild Rewards, and earn <span class="text-body--emphasis">{{loyaltyOrderPoints}}</span> points on this order.</p>
              </ng-container>
              <ui-box *ngIf="origin === 'checkout'" box_space="none">
                <p class="text-body--default text-center">Log in to join Bloom & Wild Rewards, and earn <span class="text-body--emphasis">{{loyaltyOrderPoints}}</span> points on this order. Plus {{loyaltyWelcomePoints}} welcome points and all these lovely perks:</p>
              </ui-box>
            </ui-stack>
            <ng-template #rewardsPerks let-alignment="alignment" let-stackDirection="stackDirection">
              <ui-stack stack_gap="near" [stack_direction]="stackDirection" [stack_distribute]="alignment" stack_collapse_below="tablet" [stack_wrap]="true">
                <ui-box *ngFor="let item of [].constructor(4); let i = index" box_space="none" box_fill_mode="fit">
                  <ui-stack stack_gap="tight" stack_direction="x" stack_align="center">
                    <ui-box box_background="rewards-tertiary" box_space="tiny" box_fill_mode="fit" box_border_radius="rewards-icon">
                      <ui-icon icon_name="tick"></ui-icon>
                    </ui-box>
                    <p class="text-body--supporting" *ngIf="i === 0">£5 off every 500 points</p>
                    <p class="text-body--supporting" *ngIf="i === 1">Birthday treat</p>
                    <p class="text-body--supporting" *ngIf="i === 2">Save your addresses</p>
                    <p class="text-body--supporting" *ngIf="i === 3">Easy order tracking</p>
                  </ui-stack>
                </ui-box>
              </ui-stack>
            </ng-template>
            <ui-box *ngIf="origin !== 'checkout'" box_space="near" box_space_left="none" box_space_right="none" box_space_bottom="none">
              <ui-stack stack_gap="near">
                <ng-template [ngTemplateOutlet]="rewardsPerks" [ngTemplateOutletContext]="{alignment: 'start', stackDirection: 'y'}"></ng-template>
                <p class="text-body--supporting text-color--supporting">You can <a class="text-link--paragraph" href="/bw-rewards-terms" target="_blank">read our full T&Cs here</a>.</p>
              </ui-stack>
            </ui-box>
            <ui-box class="hidden-mobile" *ngIf="origin === 'checkout'" box_space="near" box_space_bottom="default">
              <ng-template [ngTemplateOutlet]="rewardsPerks" [ngTemplateOutletContext]="{alignment: loyaltyPerksAlignment ?? 'center', stackDirection: 'x'}"></ng-template>
            </ui-box>
            <ui-box class="hidden-tablet hidden-laptop hidden-desktop" *ngIf="origin === 'checkout'" box_space="near" box_space_left="none" box_space_bottom="default">
              <ng-template [ngTemplateOutlet]="rewardsPerks" [ngTemplateOutletContext]="{alignment: loyaltyPerksAlignment ?? 'center', stackDirection: 'x'}"></ng-template>
            </ui-box>
            <ui-box *ngIf="origin === 'checkout'" box_space="none" box_align_x="center">
              <ui-stack stack_gap="tight" stack_direction="x" stack_distribute="center" stack_align="center">
                <ui-box box_border_radius="round" box_border_width="primary" box_space="none" box_fill_mode="fit">
                  <ui-icon icon_name="info" icon_size="small"></ui-icon>
                </ui-box><a class="text-link--paragraph text-body--supporting" (click)="openRewardsInfoModal()" bwTrackAs="component.detail-user.login-info.rewards-link">What’s Bloom & Wild Rewards?</a>
              </ui-stack>
            </ui-box>
          </ui-box>
          <ui-box *ngIf="isRewardsMember" box_space_top="near" box_space_left="none" box_space_right="none">
            <ui-stack stack_gap="near">
              <ng-container *ngIf="origin !== 'checkout'">
                <p class="text-body--default" *ngIf="!order">Log in to earn points on this order.</p>
                <p class="text-body--default" *ngIf="order">Log in to earn <span class="text-body--emphasis">{{loyaltyOrderPoints}}</span> points on this order.</p>
              </ng-container>
              <ui-box *ngIf="origin === 'checkout'" box_space="none">
                <p class="text-body--default text-center">Log in to earn <span class="text-body--emphasis">{{loyaltyOrderPoints}}</span> points on this order.</p>
              </ui-box>
            </ui-stack>
          </ui-box>
        </ng-container>
        <bw-auth-login-password [password]="controls.password" [email]="controls.email.value" [identifiedUser]="identifiedUser" [isRewardsMember]="isRewardsMember" [showValidationMessage]="showValidationMessage || validateForm" [showFailedLogin]="showFailedLogin" [origin]="origin" [submitError]="submitError" [validateForm]="validateForm" [showTandC]="showTandC" (didSubmit)="submit($event)" (didFail)="onFailedPassword()"></bw-auth-login-password>
      </ng-container>
      <ng-container *ngIf="showFailedLogin &amp;&amp; origin === 'checkout' &amp;&amp; order?.type !== 'subscription'">
        <div class="user-details__separator-horisontal">
          <div class="line">
            <div class="text">Or</div>
          </div>
        </div>
        <bw-button buttonStyle="secondary" bwTrackAs="checkout.userDetails.continueToOrderDetails" (clicked)="continueGuest()" [fullWidth]="true">Continue as guest</bw-button>
      </ng-container>
    </div>
    <div class="modal-footer" *ngIf="showPasswordControl"><span *ngIf="identifiedUser">Not {{identifiedUser}}?</span>
      <bw-button buttonStyle="text-link" (clicked)="goBack()" bwTrackAs="component.auth-modal.login-go-back-email">Go back</bw-button>
    </div>
  </ng-container>
  <bw-loading-spinner [fullscreen]="false" message="Logging in..." *ngIf="loading"></bw-loading-spinner>
</div>