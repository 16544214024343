import { Injectable } from '@angular/core';
import { APISerialisedJSONResponse, BackendService } from 'Shared/services/backend.service';
import { UserService } from 'Shared/services/user.service';
import {
  Delivery,
  TrackingDetails,
  ApiMyDeliveriesResponse,
  ApiRefundResponse,
  ApiDeliveryResponse,
  ApiQualityResponse,
  QualityItem,
  ApiQualityItemResponse,
  ApiQualityResolutionResponse,
  QualityResolution,
  QualityIssue,
  ApiQualityIssueResponse,
  ApiCurrentQualityIssue,
  ReportedQualityIssue,
  ApiDeliveryResponseV1,
  DeliveryToPayload,
  ApiFetchDeliveriesResponse,
  GiftCardResponse
} from 'Shared/classes/delivery';
import { AddressModelService } from 'Shared/models/address-model.service';
import { ShippingOption } from 'Shared/classes/shipping-option';
import { GiftCard } from 'Shared/classes/gift-card';
import { Address } from 'Shared/classes/address';
import { Product } from 'Shared/classes/product';
import { Addon } from 'Shared/classes/addon';
import { Order } from 'Shared/classes/order';
import { CurrencyCode, Price } from 'Shared/classes/price';
import { ProductModelService } from 'Checkout/models/product-model.service';
import { ResendChangeAddressPayload } from 'Shared/types/backend-api/addresses-api.typings';

/**
 * TODO: Split this service into multiple services https://bloomon.atlassian.net/browse/SR-1978
 */
@Injectable({
  providedIn: 'root'
})
export class DeliveryModelService {
  constructor(private backend: BackendService, private userService: UserService, private addressModelService: AddressModelService) {}

  /**
   * To Payload
   * @param delivery
   */
  static toPayload(delivery: Delivery, all?: boolean): DeliveryToPayload {
    const cover = delivery.giftCard?.cover ?? undefined;
    const msg = delivery.giftCard?.message ?? undefined;
    const date = delivery.date?.format('YYYY-MM-DD') ?? undefined;
    const product = delivery.product;
    const skuId = product ? (product.id === -1 ? null : product.id) : undefined;
    const updateMethod: string = all ? 'update_all' : 'update_single';

    return {
      id: delivery.id,
      delivery: {
        update_method: updateMethod,
        delivery_on: date,
        gift_card_image_id: cover?.id,
        gift_card_image_url: cover?.imageUrls?.[0],
        delivery_message: msg,
        shipping_note: delivery.note,
        shipping_option_id: delivery.shippingOption?.id ?? undefined,
        sku_id: skuId,
        shipping_address_id: delivery?.address?.id ?? undefined
      }
    };
  }

  /**
   * From Payload
   * @param res
   */
  fromPayload(res: ApiDeliveryResponseV1): Delivery {
    const delivery = new Delivery();
    delivery.id = parseInt(res.id, 10);
    delivery.setDate(res.delivery_on);
    delivery.trackingDetails = this.getTrackingUrl(delivery, res);
    delivery.billedAt = res.billed_at ? Delivery.setStringToDate(res.billed_at) : null;

    if (res.state) {
      delivery.backendState = res.state;
      delivery.setState(res.state);
    }

    delivery.editable = res.is_editable;
    delivery.note = res.shipping_note === null ? undefined : res.shipping_note;
    delivery.address = res.shipping_address ? this.addressModelService.fromPayload(res.shipping_address) : null;

    delivery.giftCard = this.getGiftCardFromPayload(res);

    delivery.shippingOption = res.shipping_option ? this.fromShippingOptionPayload(res) : undefined;

    delivery.price = this.getCurrencyFromPayload(res);

    delivery.product = this.getDeliveryProductFromPayload(res);

    delivery.diversion = res?.diversion?.length > 0;

    return delivery;
  }

  /**
   * Update the delivery
   * @param delivery
   */
  update(delivery: Delivery, all: boolean): Promise<Delivery> {
    const user = this.userService.getUser();
    return this.backend
      .put(user, `/v1/deliveries/${delivery.id}`, DeliveryModelService.toPayload(delivery, all))
      .then((res: ApiDeliveryResponseV1): Promise<Delivery> => {
        if (res) {
          const data = this.fromPayload(res);
          return Promise.resolve(data);
        }
        return Promise.reject();
      })
      .catch((e): Promise<never> => Promise.reject(e));
  }

  /**
   * Return all delivery information for the current user
   * @param recent - if true, returns back deliveries only with +- 2 week period
   * @param resent - if true, only resents, if false, no resents
   */
  getAll(recent = false, resent?: boolean): Promise<Delivery[]> {
    const user = this.userService.getUser();
    return this.backend
      .get(user, '/v2/my_deliveries', {
        params: {
          'filter[recent]': recent,
          'filter[resent]': resent
        }
      })
      .then((res: ApiMyDeliveriesResponse): Delivery[] => (res.data as ApiDeliveryResponse[]).map((r): Delivery => this.fromPayloadV2(r)));
  }

  /**
   * Return upcoming deliveries for an order
   * @param order
   * @param past
   * @returns
   */
  getUpcomingDeliveries(order: Order): Promise<void | Delivery[]> {
    const user = this.userService.getUser();
    return this.backend
      .get(user, `/v1/orders/${order.id}/fetch_deliveries`, {
        params: {
          future: true
        }
      })
      .then((res: ApiFetchDeliveriesResponse): Delivery[] =>
        res?.deliveries?.map((r: ApiDeliveryResponseV1): Delivery => this.fromPayload(r))
      )
      .catch((e: Error): void => {
        console.warn(e);
      });
  }

  /**
   * Return past deliveries for an order
   * @param order
   * @param past
   * @returns
   */
  getPastDeliveries(order: Order): Promise<void | Delivery[]> {
    const user = this.userService.getUser();
    return this.backend
      .get(user, `/v1/orders/${order.id}/fetch_deliveries`, {
        params: {
          future: false
        }
      })
      .then((res: ApiFetchDeliveriesResponse): Delivery[] =>
        res?.deliveries?.map((r: ApiDeliveryResponseV1): Delivery => this.fromPayload(r))
      )
      .catch((e: Error): void => {
        console.warn(e);
      });
  }

  /**
   * Given a delivery Id and a token return the delivery tracking information
   * @param deliveryId
   * @param token
   */
  getByDelivery(delivery: Delivery, deliveryToken?: string): Promise<Delivery | void> {
    const user = this.userService.getUser();

    return this.backend
      .get(user, `/v2/my_deliveries/${delivery.id}`, {
        params: {
          token: deliveryToken
        }
      })
      .then((res: ApiMyDeliveriesResponse): Promise<Delivery> => {
        if (res && !!res.data) {
          const data = this.fromPayloadV2(res.data as ApiDeliveryResponse);
          return Promise.resolve(data);
        }
        return Promise.reject();
      })
      .catch((e): void => {
        console.warn(e);
      });
  }

  /**
   * Get deliveries based on user
   * @param orderToken
   * @param recent
   * @param resent - if true, only resents, if false, no resents
   * @returns Delivery[]
   */
  getDeliveriesByUser(orderToken: string, recent = false, resent?: boolean): Promise<Delivery[]> {
    const user = this.userService.getUser();

    return this.backend
      .get(user, '/v2/my_deliveries', {
        headers: {
          'x-user-email': user?.email.address || undefined,
          'x-order-token': orderToken || ''
        },
        params: {
          'filter[recent]': recent,
          'filter[resent]': resent
        }
      })
      .then((res: ApiMyDeliveriesResponse): Promise<Delivery[]> => {
        if (res?.data) {
          const data = (res.data as ApiDeliveryResponse[]).map((r): Delivery => this.fromPayloadV2(r));
          return Promise.resolve(data);
        }

        return Promise.reject();
      });
  }

  /**
   * Get deliveries by order
   * @param order
   * @param orderToken
   * @param recent
   * @returns Delivery[]
   */
  getDeliveriesByOrder(order: Order, orderToken: string, recent = false): Promise<Delivery[]> {
    return this.backend
      .get(null, '/v2/my_deliveries', {
        headers: {
          'x-order-id': order.id.toString(),
          'x-order-token': orderToken || ''
        },
        params: {
          'filter[recent]': recent
        }
      })
      .then((res: ApiMyDeliveriesResponse): Promise<Delivery[]> => {
        if (res?.data) {
          const data = (res.data as ApiDeliveryResponse[]).map((r): Delivery => this.fromPayloadV2(r));
          return Promise.resolve(data);
        }

        return Promise.reject();
      });
  }

  /**
   * Map returned products
   * @param res
   */
  fromPayloadProducts(
    res: APISerialisedJSONResponse<'/v2/availability/products'>['data'][number][] | APISerialisedJSONResponse<'/v2/skus/:productId'>[]
  ): Product[] {
    if (res) {
      return res.map((item): Product => ProductModelService.fromPayload(item));
    }
    return null;
  }

  /**
   * Given a delivery Id and a token return the delivery tracking information
   * @param deliveryId
   * @param token
   */
  getAvailableProductsByDelivery(delivery: Delivery): Promise<void | Product[]> {
    const user = this.userService.getUser();

    if (delivery) {
      return this.backend
        .get(user, `/v2/my_deliveries/${delivery.id}/available_skus`)
        .then((res): Promise<Product[]> => {
          if (res && !!res.data) {
            const data = this.fromPayloadProducts(res.data);
            return Promise.resolve(data);
          }
          return Promise.reject();
        })
        .catch((e): void => {
          console.warn(e);
        });
    }
    return Promise.reject();
  }

  /**
   * Apply the credit automatically
   * @param delivery
   * @param deliveryToken
   */
  applyCredit(delivery: Delivery, deliveryToken: string): Promise<void> {
    const user = this.userService.getUser();

    return this.backend.put(
      user,
      `/v2/my_deliveries/${delivery.id}/compensate`,
      {},
      {
        headers: {
          'x-order-token': deliveryToken
        }
      }
    );
  }

  /**
   * Apply full credit automatically
   * @param delivery
   * @param deliveryToken
   */
  applyRefund(delivery: Delivery, deliveryToken: string): Promise<Price> {
    const user = this.userService.getUser();

    return this.backend
      .put(
        user?.isLoggedIn() ? user : null,
        `/v2/my_deliveries/${delivery.id}/refund`,
        {},
        {
          headers: {
            'x-order-token': deliveryToken,
            'x-order-id': delivery.orderId.toString()
          }
        }
      )
      .then((res: ApiRefundResponse): Price => this.fromRefundPayload(res.data));
  }

  /**
   * Apply the credit automatically
   * @param delivery
   * @param deliveryToken
   */
  applyResend(delivery: Delivery, deliveryToken: string, notifyMe?: boolean, changed?: boolean): Promise<Delivery> {
    const user = this.userService.getUser();
    const dataChanged = changed ? this.toResendChangeAddressPayload(delivery, notifyMe) : this.toResendPayload(delivery, notifyMe);
    return this.backend
      .post(user?.isLoggedIn() ? user : null, `/v2/my_deliveries/${delivery.id}/resend`, dataChanged, {
        headers: !user.isLoggedIn() // Auth headers for logged insent in backend.service
          ? {
            'x-order-token': deliveryToken || undefined,
            'x-order-id': delivery.orderId.toString()
          }
          : null
      })
      .then((res: ApiMyDeliveriesResponse): Delivery => this.fromPayloadV2(res.data as ApiDeliveryResponse));
  }

  /**
   * From Payload for V2 apis
   * @param res
   */
  fromPayloadV2(res: ApiDeliveryResponse): Delivery {
    if (res) {
      const delivery = new Delivery();
      delivery.id = parseInt(res.id, 10);
      if (res?.attributes) {
        delivery.billedAt = Delivery.setStringToDate(res.attributes.billed_at);
        delivery.createdAt = Delivery.setStringToDate(res.attributes.created_at);

        delivery.isEditable = (): boolean => res.attributes.is_editable;
        delivery.orderId = res.attributes.order_id;
        delivery.product = this.mapProduct(res);
        delivery.state = res.attributes.state;

        delivery.address = this.mapAddressDetails(res);
        delivery.note = res.attributes.shipping_note;
        delivery.shippingOption = this.mapShippingDetails(res);
        delivery.trackingDetails = this.mapTrackingDetails(res);
        delivery.userIsRegistered = res.meta.user.is_registered;
      }
      return delivery;
    }
    return null;
  }

  /**
   * Get gift card covers
   * @param product
   */
  getGiftCardCovers(delivery: Delivery): Promise<Addon[]> {
    const user = this.userService.getUser();

    return this.backend
      .get(user, `/v2/my_deliveries/${delivery.id}/gift_card_images`, {
        useUrlAsCache: true
      })
      .then((res): Addon[] => res.data.map((r: GiftCardResponse): Addon => this.fromGiftCardPayload(r)));
  }

  /**
   * Get quality issues
   * @param delivery
   * @param token
   */
  getQualityIssues(delivery: Delivery, token: string): Promise<QualityItem[]> {
    return this.backend
      .get(null, `/v2/my_deliveries/${delivery.id}/quality_issue_names`, {
        headers: {
          'x-order-token': token,
          'x-order-id': delivery.orderId.toString()
        }
      })
      .then((res: ApiQualityResponse): QualityItem[] => res.data.map((r): QualityItem => this.fromQualityPayload(r)));
  }

  /**
   * Get quality stems
   * @param delivery
   * @param token
   */
  getQualityStems(delivery: Delivery, token: string): Promise<QualityItem[]> {
    return this.backend
      .get(null, `/v2/my_deliveries/${delivery.id}/quality_issue_stems`, {
        headers: {
          'x-order-token': token,
          'x-order-id': delivery.orderId.toString()
        }
      })
      .then((res: ApiQualityResponse): QualityItem[] => res.data.map((r): QualityItem => this.fromQualityPayload(r)));
  }

  /**
   * Get quality resolution
   * @param delivery
   * @param token
   * @param issue
   * @param stems
   */
  getQualityResolution(delivery: Delivery, token: string, issue: QualityItem, stems: QualityItem[]): Promise<QualityResolution> {
    return this.backend
      .post(null, `/v2/my_deliveries/${delivery.id}/quality_issue_options/resolution`, this.toQualityResolutionPayload(issue, stems), {
        headers: {
          'x-order-token': token,
          'x-order-id': delivery.orderId.toString()
        }
      })
      .then((res: ApiQualityResolutionResponse): QualityResolution => this.fromQualityResolutionPayload(res));
  }

  /**
   * From quality issue payload
   * @param res
   * @returns QualityIssue
   */
  fromQualityIssuePayload(res: ApiQualityIssueResponse): QualityIssue {
    const data = res.data;
    const issue = new QualityIssue();
    issue.id = +data.id;
    issue.deliveryId = data.attributes.delivery_id;
    issue.refunded = data.attributes.refunded;
    issue.resent = data.attributes.resent;

    return issue;
  }

  /**
   * Report quality issue
   * @param delivery
   * @param token
   * @param issueId
   * @param stemIds
   * @param comment
   * @param fileNames
   */
  reportQualityIssue(
    delivery: Delivery,
    token: string,
    resolution: QualityResolution,
    stems: QualityItem[],
    comment: string,
    fileNames: string[]
  ): Promise<QualityIssue> {
    return this.backend
      .post(null, `/v2/my_deliveries/${delivery.id}/quality_issues`, this.toQualityIssuePayload(resolution, stems, comment, fileNames), {
        headers: {
          'x-order-token': token,
          'x-order-id': delivery.orderId.toString()
        }
      })
      .then((res: ApiQualityIssueResponse): QualityIssue => this.fromQualityIssuePayload(res));
  }

  /**
   * To quality compensate payload
   * @param issueOptionId
   */
  toCompensatePayload(issueOptionId: number): {
    data: { attributes: { quality_issue_id: string } };
  } {
    return {
      data: {
        attributes: {
          quality_issue_id: issueOptionId.toString()
        }
      }
    };
  }

  /**
   * Apply the credit automatically
   * @param delivery
   * @param deliveryToken
   */
  applyQualityCredit(delivery: Delivery, token: string, issueOptionId: number): Promise<void> {
    return this.backend.put(null, `/v2/my_deliveries/${delivery.id}/quality_issues/compensate`, this.toCompensatePayload(issueOptionId), {
      headers: {
        'x-order-token': token,
        'x-order-id': delivery.orderId.toString()
      }
    });
  }

  /**
   * Apply refund for quality flow
   * @param delivery
   * @param orderToken
   * @param issueOptionId
   * @param issueId
   * @returns Promise<Price>
   */
  applyQualityRefund(delivery: Delivery, token: string, issueOptionId: number, issueId: number): Promise<Price> {
    return this.backend
      .put(null, `/v2/my_deliveries/${delivery.id}/quality_issues/refund`, this.toRefundPayload(issueOptionId, issueId), {
        headers: {
          'x-order-token': token,
          'x-order-id': delivery.orderId.toString()
        }
      })
      .then((res: ApiRefundResponse): Price => this.fromRefundPayload(res.data));
  }

  /**
   * Apply quality issue resend
   * @param delivery
   * @param token
   * @param issueId
   * @param deliveryChanged
   * @param notify
   * @returns Delivery
   */
  applyQualityResend(delivery: Delivery, token: string, issueId: number, deliveryChanged: boolean, notify: boolean): Promise<Delivery> {
    const data = deliveryChanged
      ? this.toResendChangeAddressPayload(delivery, notify, issueId)
      : this.toResendPayload(delivery, notify, issueId);

    return this.backend
      .post(null, `/v2/my_deliveries/${delivery.id}/quality_issues/resend`, data, {
        headers: {
          'x-order-token': token,
          'x-order-id': delivery.orderId.toString()
        }
      })
      .then((res: ApiMyDeliveriesResponse): Delivery => this.fromPayloadV2(res.data as ApiDeliveryResponse));
  }

  /**
   * From check current quality issue payload
   * @param res
   * @returns
   */
  fromCurrentQualityIssue(res: ApiCurrentQualityIssue): ReportedQualityIssue {
    const data = res.data;
    const attributes = data.attributes;
    const qualityAttributes = attributes.quality_issue_option;

    const issue = new QualityIssue();
    issue.id = +data.id;
    issue.deliveryId = attributes.delivery_id;
    issue.refunded = attributes.refunded;
    issue.resent = attributes.resent;

    const resolution = new QualityResolution();
    resolution.id = attributes.quality_issue_option_id.toString();
    resolution.isCompensable = qualityAttributes.compensable;
    resolution.compensationPennies = {
      GBP: new Price('GBP', 1, qualityAttributes.compensation_gbp_pennies),
      EUR: new Price('EUR', 1, qualityAttributes.compensation_eur_pennies)
    };
    resolution.refundPercentage = qualityAttributes.refund_percentage;
    resolution.isRefundable = qualityAttributes.refundable;
    resolution.isResendable = qualityAttributes.resendable;
    resolution.resolutionMessage = qualityAttributes.resolution_message;

    return {
      resolution,
      issue
    };
  }

  /**
   * Check for exist quality issue
   * @param delivery
   * @param token
   * @returns Quality Resolution
   */
  checkQualityIssue(delivery: Delivery, token: string): Promise<ReportedQualityIssue> {
    return this.backend
      .get(null, `/v2/my_deliveries/${delivery.id}/quality_issues/current`, {
        headers: {
          'x-order-token': token,
          'x-order-id': delivery.orderId.toString()
        }
      })
      .then((res: ApiCurrentQualityIssue): ReportedQualityIssue => this.fromCurrentQualityIssue(res));
  }

  /**
   * Update and validate quality resolution
   * @param delivery
   * @param token
   */
  validateResolutionForQualityIssue(delivery: Delivery, token: string): Promise<ReportedQualityIssue> {
    return this.backend
      .post(
        null,
        `/v2/my_deliveries/${delivery.id}/quality_issues/validate_resolution`,
        {},
        {
          headers: {
            'x-order-token': token,
            'x-order-id': delivery.orderId.toString()
          }
        }
      )
      .then((res: ApiCurrentQualityIssue): ReportedQualityIssue => this.fromCurrentQualityIssue(res));
  }

  /**
   * From Refund Payload
   * @param res
   */
  private fromRefundPayload(res: ApiRefundResponse['data']): Price {
    const r = res?.attributes;
    return new Price(r.credits_currency as CurrencyCode, r.credits, r.credits_pennies);
  }

  /**
   * To Resend Address Change Payload
   * @param delivery
   * @param notify
   * @param qualityIssueId
   */
  /* eslint complexity: ["error", 16]*/
  private toResendChangeAddressPayload(delivery: Delivery, notify = false, qualityIssueId?: number): ResendChangeAddressPayload {
    return {
      data: {
        id: delivery.id || undefined,
        attributes: {
          notify,
          quality_issue_id: qualityIssueId?.toString() ?? undefined,
          shipping_address_id: delivery.address.id ?? undefined,
          shipping_note: delivery.note ?? undefined,
          shipping_address_attributes: {
            name: delivery.address.name ?? undefined,
            postcode: delivery.address.postcode ?? undefined,
            company: delivery.address.company ?? undefined,
            line1: delivery.address.line1 ?? undefined,
            line2: delivery.address.line2 ?? undefined,
            city: delivery.address.city ?? undefined,
            shipping_country_id: delivery.address.country?.id ?? undefined,
            postcode_anywhere_uid: delivery.address.pcaID ?? undefined,
            phone_number: delivery.address.phone ?? undefined
          }
        }
      }
    };
  }

  /**
   * To Resend Payload
   * @param delivery
   * @param notify
   * @param qualityIssueId
   */
  private toResendPayload(
    delivery: Delivery,
    notify = false,
    qualityIssueId?: number
  ): { data: { id: number; attributes: { notify: boolean; quality_issue_id: string } } } {
    return {
      data: {
        id: delivery.id,
        attributes: {
          notify,
          quality_issue_id: qualityIssueId?.toString() ?? undefined
        }
      }
    };
  }

  /**
   * Product mapping on fromPayloadV2
   * @param res
   */
  private mapProduct(res: ApiDeliveryResponse): Product {
    const attr = res.attributes?.sku_attributes;
    attr.media = attr.media?.length ? attr.media : [];
    const product = new Product();
    product.id = attr.id;
    product.slug = attr.slug;
    product.name = attr.name;
    product.bundleOnly = attr.bundle_only || false;
    product.singleOnly = attr.single_only || false;
    product.subscriptionOnly = attr.subscription_only || false;
    product.lilyFree = attr.lily_free;
    product.over18Only = attr.eighteen_plus;
    product.collectionName = attr.collection_name;
    product.collectionId = attr.collection_id;
    product.isPreorder = attr.is_pre_order;

    product.description = attr.description;
    product.longDescription = attr.long_description;
    product.shortDescription = attr.email_description;
    product.imageUrls = attr.media?.length ? attr.media?.map((m): string => m.url) : attr.imageUrls || [];

    return product;
  }

  /**
   * Map tracking details
   * @param res
   * @returns
   */
  private mapTrackingDetails(res: ApiDeliveryResponse): TrackingDetails {
    const trackingDetails = new TrackingDetails();
    trackingDetails.trackedNumber = res.attributes.tracked_number;
    trackingDetails.trackingUrl = res.attributes.tracking_url;
    trackingDetails.deliveredAt = Delivery.setStringToDate(res.attributes.delivered_at);
    trackingDetails.deliveryMessage = res.attributes.delivery_message;
    trackingDetails.deliveryOn = Delivery.setStringToDate(res.attributes.delivery_on);
    trackingDetails.lockedAt = Delivery.setStringToDate(res.attributes.locked_at);
    trackingDetails.shippedAt = Delivery.setStringToDate(res.attributes.shipped_at);
    trackingDetails.updatedAt = Delivery.setStringToDate(res.attributes.updated_at);
    this.mapTrackingAttributes(res, trackingDetails);
    return trackingDetails;
  }
  /**
   * Map Tracking Attributes from Payload
   * @param res
   * @param trackingDetails
   */
  private mapTrackingAttributes(res: ApiDeliveryResponse, trackingDetails: TrackingDetails): void {
    const apiTrackingAttributes = res.attributes.tracking_attributes;

    if (apiTrackingAttributes) {
      trackingDetails.compensable = apiTrackingAttributes.compensable;

      if (trackingDetails.compensable) {
        trackingDetails.compensationPrice = new Price(
          apiTrackingAttributes.compensation_amount_currency as CurrencyCode,
          1,
          apiTrackingAttributes.compensation_amount_pennies
        );
      }

      // TODO: migrate delivery tracking to use new compensationPrice instead
      trackingDetails.compensationCurrency = apiTrackingAttributes.compensation_amount_currency;
      trackingDetails.compensationAmount = apiTrackingAttributes.compensation_amount_pennies;

      trackingDetails.refundable = apiTrackingAttributes.refundable;
      trackingDetails.resendable = apiTrackingAttributes.resendable;
      trackingDetails.status = apiTrackingAttributes.status || 'due';
      trackingDetails.statusDescription = apiTrackingAttributes.description;
      trackingDetails.estimatedDeliveryDate = Delivery.setStringToDate(apiTrackingAttributes.estimated_delivery_date);
    }
  }

  /**
   * To quality resolution payload
   * @param issue
   * @param stems
   */
  private toQualityResolutionPayload(
    issue: QualityItem,
    stems: QualityItem[]
  ): {
      data: { attributes: { quality_issue_name_id: string; quality_issue_stem_ids: string[] } };
    } {
    return {
      data: {
        attributes: {
          quality_issue_name_id: issue.id,
          quality_issue_stem_ids: stems.map((s): string => s.id)
        }
      }
    };
  }

  /**
   * From quality resolution payload
   * @param res
   */
  private fromQualityResolutionPayload(res: ApiQualityResolutionResponse): QualityResolution {
    const r = new QualityResolution();
    r.id = res.data.id;
    const attributes = res.data.attributes;
    r.isCompensable = attributes.compensable;
    r.compensationPennies = {
      GBP: new Price('GBP', 1, attributes.compensation_gbp_pennies),
      EUR: new Price('EUR', 1, attributes.compensation_eur_pennies)
    };
    r.refundPercentage = attributes.refund_percentage;
    r.isRefundable = attributes.refundable;
    r.isResendable = attributes.resendable;
    r.resolutionMessage = attributes.resolution_message;
    return r;
  }

  /**
   * Map Shipping Option from Shipping Option Payload
   * @param res
   * @returns
   */
  private fromShippingOptionPayload(res: ApiDeliveryResponseV1): ShippingOption {
    const shippingOption = new ShippingOption();
    shippingOption.id = parseInt(res.shipping_option.id, 10);
    shippingOption.subsequentDeliveries = res.shipping_option.subsequent_deliveries_option;
    const method = res.shipping_option.shipping_method || {};
    shippingOption.name = method.display_name;
    shippingOption.description = method.description;
    shippingOption.maxNoteLength = method.note_length;

    shippingOption.hasPhoneNumber = typeof method.show_phone_number_field !== 'undefined' ? method.show_phone_number_field : false;

    shippingOption.hasPhoneNumberRequired = typeof method.phone_number_required !== 'undefined' ? method.phone_number_required : false;

    shippingOption.hasGiftCard = typeof method.show_gift_card_fields !== 'undefined' ? method.show_gift_card_fields : true;

    return shippingOption;
  }

  /**
   * Get and map Tracking Url from payload
   * @param delivery
   * @param res
   * @returns
   */
  private getTrackingUrl(delivery: Delivery, res: ApiDeliveryResponseV1): TrackingDetails {
    const trackingDetails = new TrackingDetails();
    trackingDetails.trackingUrl = res.tracking_url;
    return trackingDetails;
  }

  /**
   * To quality refund payload
   * @param issueOptionId
   * @param issueId
   */
  private toRefundPayload(
    issueOptionId: number,
    issueId: number
  ): { data: { attributes: { quality_issue_option_id: string; quality_issue_id: string } } } {
    return {
      data: {
        attributes: {
          quality_issue_option_id: issueOptionId.toString(),
          quality_issue_id: issueId.toString()
        }
      }
    };
  }

  /**
   * To payload for quality issue
   * @param issueId
   * @param stemIds
   * @param comment
   * @param fileNames
   * @returns
   */
  private toQualityIssuePayload(
    resolution: QualityResolution,
    stems: QualityItem[],
    comment: string,
    files: string[]
  ): {
      data: {
        attributes: {
          quality_issue_option_id: string;
          quality_issue_stem_ids: number[];
          comment: string;
          quality_issue_image_urls: string[];
        };
      };
    } {
    return {
      data: {
        attributes: {
          comment,
          quality_issue_option_id: resolution.id,
          quality_issue_stem_ids: stems.map((s): number => +s.id),
          quality_issue_image_urls: files
        }
      }
    };
  }

  /**
   * Map from Gift Card Payload
   * @param cover
   * @returns
   */
  private fromGiftCardPayload(cover: GiftCardResponse): Addon {
    const c = new Addon(+cover.id);
    c.type = cover.type;
    c.imageUrls = [cover.attributes.url];
    c.tags = cover.attributes.tags || [];
    return c;
  }

  /**
   * Mapping quality issue
   * @param issue
   * @returns
   */
  private fromQualityPayload(res: ApiQualityItemResponse): QualityItem {
    return new QualityItem(res.id, res.attributes.display_name, res.attributes.url);
  }

  /**
   * Map Address Details
   * @param res
   * @returns
   */
  private mapAddressDetails(res: ApiDeliveryResponse): Address {
    return res.attributes.address_attributes ? this.addressModelService.fromPayload(res.attributes.address_attributes) : null;
  }

  /**
   * Map Shipping Details from Payload
   * @param res
   * @returns
   */
  private mapShippingDetails(res: ApiDeliveryResponse): ShippingOption {
    if (res.attributes.shipping_attributes) {
      const shippingOption = new ShippingOption();
      shippingOption.id = parseInt(res.attributes.shipping_attributes.carrier_id, 10);
      shippingOption.name = res.attributes.shipping_attributes.carrier_name;
      shippingOption.description = res.attributes.shipping_attributes.description;
      shippingOption.successRateMessage = res.attributes.shipping_attributes.delivery_pill || null;
      return shippingOption;
    }
    return undefined;
  }

  /**
   * Get Delivery Product From Payload
   * @param res
   * @returns
   */
  private getDeliveryProductFromPayload(res: ApiDeliveryResponseV1): Product {
    if (!!res.sku_attributes && !!res.sku_attributes?.id && !!res.sku_attributes?.name) {
      const product = new Product();
      product.id = res.sku_attributes?.id;
      product.name = res.sku_attributes?.name;
      product.imageUrls = [res.sku_attributes?.image_url];
      return product;
    }
    return undefined;
  }

  /**
   * Get Currency From Payload
   * @param res
   * @param delivery
   * @returns
   */
  private getCurrencyFromPayload(res: ApiDeliveryResponseV1): Price {
    if (res.currency && res.total_cost_pre_discount_pennies && res.total_cost_pennies) {
      return new Price(res.currency, 1, res.total_cost_pennies, res.total_cost_pre_discount_pennies);
    }
    return undefined;
  }

  /**
   * Get Gift Card From Payload
   * @param res
   * @returns
   */
  private getGiftCardFromPayload(res: ApiDeliveryResponseV1): GiftCard {
    let giftCard;
    if (res.delivery_message || res.gift_card_image_id || res.gift_card_image_url) {
      giftCard = new GiftCard();
      giftCard.message = res.delivery_message;
      giftCard.cover = new Addon(res.gift_card_image_id);
      giftCard.cover.imageUrls = res.gift_card_image_url ? [res.gift_card_image_url] : [];
    }
    return giftCard;
  }
}
