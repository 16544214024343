
<div class="bw-reset-password-modal">
  <div class="modal-header">
    <div class="modal-title">{{modalTitle}}</div>
    <ui-button button_size="small" button_type="tertiary" button_icon="close" button_icon_placement="iconOnly" (clicked)="onCancel()"><span aria-hidden="true">&times;</span></ui-button>
  </div>
  <div class="modal-body" #bwModal>
    <ui-stack stack_distribute="fill">
      <ui-stack *ngIf="email" stack_direction="x" stack_gap="near" stack_distribute="start" stack_align="center">
        <ui-box box_fill_mode="fit" box_space="tight" box_border_radius="round" box_border_width="primary" box_background="transparent">
          <ui-icon icon_name="user"></ui-icon>
        </ui-box>
        <p>Welcome back {{identifiedUser}}</p>
      </ui-stack>
      <ui-box box_space="none">
        <form [formGroup]="form" [class.ng-submitted]="isSubmitted">
          <ui-form-group group_title="Reset Password Form" [group_title_hidden]="true">
            <ui-text-input *ngIf="experiment===0" formControlName="password" show_password_string="Show" hide_password_string="Hide" name="password" id="password" autocomplete="new-password" label="Password" hint="Minimum 8 characters" type="password" [optional]="false" [minlength]="8">
              <ui-form-errors [errors]="form.get('password')?.errors" [error_messages]="{&quot;minlength&quot;:&quot;Your Password must be at least 8 characters long&quot;,&quot;required&quot;:&quot;Your password must be at least 8 characters long &quot;,&quot;invalid&quot;:&quot;Password is required&quot;}"></ui-form-errors>
            </ui-text-input>
            <ui-text-input *ngIf="experiment!==0" formControlName="password" show_password_string="Show" hide_password_string="Hide" name="password" id="password" autocomplete="new-password" label="Password" hint="8 characters or more. Create a strong password by including things like numbers, upper and lower case letters, or special characters, e.g. !?#@£" type="password" [optional]="false" [minlength]="8">
              <ui-form-errors [errors]="form.get('password')?.errors" [error_messages]="{&quot;minlength&quot;:&quot;Your password must be 8 characters or more.&quot;,&quot;required&quot;:&quot;Oops! Please enter a password of 8 characters or more.&quot;,&quot;passwordStrength&quot;:&quot;Please choose a stronger password. Try adding numbers, upper case and lower case letters, or special characters, e.g. !?#@£&quot;,&quot;strongCommonPassword&quot;:&quot;Please choose a stronger password. Try adding numbers, upper case and lower case letters, or special characters, e.g. !?#@£&quot;}"></ui-form-errors>
            </ui-text-input>
            <ui-text-input *ngIf="experiment===0" formControlName="repeatPassword" show_password_string="Show" hide_password_string="Hide" name="repeatPassword" id="repeatPassword" autocomplete="new-password" label="Confirm password" hint="Minimum 8 characters" type="password" [optional]="false" [minlength]="8">
              <ui-form-errors [errors]="form.get('repeatPassword')?.errors" [error_messages]="{&quot;minlength&quot;:&quot;Your password must be at least 8 characters long &quot;,&quot;required&quot;:&quot;Please type the password again&quot;,&quot;invalid&quot;:&quot;Please type the password again&quot;,&quot;notMatch&quot;:&quot;Your passwords do not match&quot;}"></ui-form-errors>
            </ui-text-input>
            <ui-text-input *ngIf="experiment!==0" formControlName="repeatPassword" show_password_string="Show" hide_password_string="Hide" name="repeatPassword" id="repeatPassword" label="Confirm password" hint="8 characters or more. Create a strong password by including things like numbers, upper and lower case letters, or special characters, e.g. !?#@£" type="password" autocomplete="new-password" [optional]="false" [minlength]="8">
              <ui-form-errors [errors]="form.get('repeatPassword')?.errors" [error_messages]="{&quot;minlength&quot;:&quot;Your password must be 8 characters or more.&quot;,&quot;required&quot;:&quot;Oops! Please enter a password of 8 characters or more.&quot;,&quot;passwordStrength&quot;:&quot;Please choose a stronger password. Try adding numbers, upper case and lower case letters, or special characters, e.g. !?#@£&quot;,&quot;strongCommonPassword&quot;:&quot;Please choose a stronger password. Try adding numbers, upper case and lower case letters, or special characters, e.g. !?#@£&quot;,&quot;notMatch&quot;:&quot;Your passwords do not match&quot;}"></ui-form-errors>
            </ui-text-input>
          </ui-form-group>
        </form>
      </ui-box>
    </ui-stack>
  </div>
  <div class="modal-footer">
    <ui-button button_type="primary" button_size="large" button_full_width="true" (clicked)="submit()">Save password</ui-button>
  </div>
  <bw-loading-spinner [fullscreen]="false" *ngIf="loading"></bw-loading-spinner>
</div>