import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SMSSignupService } from 'Content/services/sms-signup.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryService, Country } from 'Shared/services/country.service';
import { ToastrService } from 'Base/app/toastr/services/toastr.service';
import { TelephoneNumberUtilsService } from 'Shared/utils/telephone-number-utils.service';

@Component({
  selector: 'bw-sms',
  templateUrl: './sms-signup.component.html',
  styleUrls: ['./sms-signup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SMSSignupComponent implements OnInit {
  showPrefixDropdown: boolean;
  isLoading: boolean;
  formSubmitted: boolean;
  success: boolean;
  error: boolean;
  selectedCountry: any;
  number: string;
  dialCode: any;
  smsSignupForm: FormGroup;
  countries: any;

  constructor(
    private smsSignupService: SMSSignupService,
    private toastr: ToastrService,
    private countryService: CountryService,
    private telephoneNumberUtils: TelephoneNumberUtilsService
  ) {
    this.formSubmitted = false;
    this.success = false;
    this.error = false;
    this.isLoading = false;
    this.selectedCountry = null;

    this.smsSignupForm = new FormGroup({
      country: new FormControl('', [Validators.required]),
      number: new FormControl('', [Validators.required, Validators.minLength(9)])
    });
  }

  getCountries(): Country[] {
    const countries = this.countryService.getCountries();

    return countries;
  }

  selectCountry(country): void {
    this.selectedCountry = country;
    this.smsSignupForm.get('country').setValue(country);
  }

  /**
   * Send the SMS
   * @returns {Promise}
   */
  sendSMS(): Promise<any> {
    this.formSubmitted = true;

    if (!this.smsSignupForm.valid) {
      return Promise.resolve('Form invalid');
    }

    this.isLoading = true;
    this.success = false;
    this.error = false;
    return this.smsSignupService
      .sendSMS(this.selectedCountry.phonePrefix, this.smsSignupForm.get('number').value)
      .then(() => {
        this.isLoading = false;
        this.success = true;
      })
      .catch(error => {
        this.isLoading = false;
        this.error = true;
        this.toastr.error(error.message, '', {
          autoDismiss: false
        });
      });
  }

  /**
   * onInit
   */
  ngOnInit(): void {
    this.countries = this.getCountries();

    if (this['dial-code']) {
      this.selectCountry(
        TelephoneNumberUtilsService.getCountryMatchesDialCode(this['dial-code'], this.countries)
      );
    }
  }
}
