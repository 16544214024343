import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as dayjs from 'dayjs';
export interface PasswordComplexityErrorModel {
  passwordStrength: {
    passwordLength: boolean;
    hasUpperCase: boolean;
    hasLowerCase: boolean;
    hasNumeric: boolean;
    hasSpecialCharacters: boolean;
  };
}

const MIN_DAY = 1;
const MAX_DAY = 31;
const MIN_MONTH = 1;
const MAX_MONTH = 12;
const DAY_PATTERN = '^([1-9]|[12][0-9]|3[01])$';
const MONTH_PATTERN = '^([1-9]|1[0-2])$';
const YEAR_PATTERN = '^(19|20)\\d{2}$';

export class ValidationService {
  /**
   * Get Strong Common Password Validator
   */
  static get strongCommonPassword() {
    return { strongCommonPassword: true };
  }

  /**
   * Day validator
   * @param formGroup
   */
  static day(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      control?.value &&
      Number(control.value).toString().trim().match(DAY_PATTERN) &&
      Number(control.value) >= MIN_DAY &&
      Number(control.value) <= MAX_DAY
        ? null
        : { pattern: true };
  }

  /**
   * Month validator
   * @param formGroup
   */
  static month(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      control?.value &&
      Number(control.value).toString().trim().match(MONTH_PATTERN) &&
      Number(control.value) >= MIN_MONTH &&
      Number(control.value) <= MAX_MONTH
        ? null
        : { pattern: true };
  }

  /**
   * Year validator
   * @param formGroup
   */
  static year(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      control?.value && control.value.trim().match(YEAR_PATTERN) ? null : { pattern: true };
  }

  /**
   * Year validator
   * @param formGroup
   */
  static noFutureYear(): ValidatorFn {
    const year = dayjs().year();
    return (control: AbstractControl): ValidationErrors | null =>
      control?.value ? (control.value <= year ? null : { futureYear: true }) : { required: true };
  }

  /**
   * Year validator
   * @param formGroup
   */
  static tooOldBdayYear(): ValidatorFn {
    const year = dayjs().year();
    return (control: AbstractControl): ValidationErrors | null => (control?.value && control.value <= year - 100 ? { tooOld: true } : null);
  }

  /**
   * Ensure full name
   * @param formGroup
   */
  static fullNameRequired(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      control?.value && control.value.trim().match(/\S+\s\S+/) ? null : { fullNameRequired: true };
  }

  /**
   * EmailValidators
   * @returns
   */
  static email(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        if (control.value.match(/^[^ @]*@[^ @]*.[^ .]$/)) {
          return null;
        } else {
          return { emailPattern: { requiredPattern: '^[^ @]*@[^ @]*.[^ .]$', actualValue: control.value } };
        }
      }
    };
  }
  /* eslint complexity: ["error", 15]*/
  static createPasswordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }
      const passwordLength = value.match(/.{8,}/);

      const hasUpperCase = value.match(/[A-Z]+/);

      const hasLowerCase = value.match(/[a-z]+/);

      const hasNumeric = value.match(/[0-9]+/);

      // eslint-disable-next-line no-useless-escape
      const hasSpecialCharacters = value.match(/[-*%$£,.;:/"'|{}\[\]^~+=!?\\#@&]/);

      const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecialCharacters && passwordLength;

      return !passwordValid
        ? ({
            passwordStrength: {
              passwordLength: passwordLength ?? false,
              hasUpperCase: hasUpperCase ?? false,
              hasLowerCase: hasLowerCase ?? false,
              hasNumeric: hasNumeric ?? false,
              hasSpecialCharacters: hasSpecialCharacters ?? false
            }
          } as PasswordComplexityErrorModel)
        : null;
    };
  }
}
