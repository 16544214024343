
<ng-container *ngIf="splashCategories.active === true">
  <ng-container *ngIf="(viewportSizeIs$ | async)?.mobile">
    <ui-box box_space="none" box_fill_mode="fill" [box_space_top]="experimentVariant === 1 ? 'default' : 'near'" [box_space_bottom]="experimentVariant === 1 ? 'default' : 'near'">
      <div class="splash-categories">
        <ng-container *ngFor="let category of splashCategories.categories">
          <ng-container *ngIf="pageType !== 'gridPage' || category.hideOnGrid === false">
            <div class="splash-category" box_space="none" box_fill_mode="fit"><a (click)="navigate(category)">
                <ng-container *ngIf="experimentVariant === 1">
                  <div class="splash-category__image-with-text"><img [src]="category.squareImage" [alt]="category.name">
                    <div class="text-body--supporting text-color--supporting text-body--emphasis">{{ category.name }}</div>
                  </div>
                </ng-container>
                <ng-container *ngIf="experimentVariant === 2">
                  <ui-stack stack_gap="tiny" align="center" stack_align="center">
                    <div class="splash-category__image-above-text"><img [src]="category.roundImage" [alt]="category.name">
                      <div class="text-body--supporting">{{ category.name }}</div>
                    </div>
                  </ui-stack>
                </ng-container></a></div>
          </ng-container>
        </ng-container>
      </div>
    </ui-box>
  </ng-container>
</ng-container>