import { Component, OnInit, Input } from '@angular/core';
import { ModalComponent, ModalService } from 'Shared/services/modal.service';
import { BehaviorSubject } from 'rxjs';
import { User, UserService } from 'Shared/services/user.service';
import { PurchaseService } from 'Checkout/services/purchase.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { CookieService } from 'Shared/services/third-parties/cookie.service';
import { ICookieConsent } from 'Shared/interfaces/cookie-consent';
import { StateService } from 'Shared/services/state.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ConfigService } from 'Shared/services/config.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { LoyaltyService } from 'Shared/services/loyalty.service';
import { Error } from 'Shared/classes/error';
import { ACCOUNT_OFFERINGS, AccountOffering, AuthOrigin, AuthSteps, AuthTypes } from 'Shared/components/modals/auth-modal/auth';
import { FeaturesService } from 'Shared/services/features.service';
import { Order } from 'Shared/classes/order';

type ModalResolveData = { email?: string };
type ModalRejectData = { continueAsGuest?: boolean };

/* bw:view-encapsulation */
@Component({
  selector: 'bw-auth-modal',
  templateUrl: './auth-modal.component.html'
})
export class AuthModalComponent extends ModalComponent<ModalResolveData, ModalRejectData> implements OnInit {
  @Input() title!: string;
  @Input() selectedTab: AuthTypes = 'login';
  @Input() enableRegister: boolean = true;
  @Input() defaultEmail!: string;
  @Input() defaultHeader: boolean = true;
  @Input() defaultSubtitle: boolean = true;
  @Input() checkoutOrigin: boolean = false;
  @Input() shouldRestorePurchase: boolean = true;
  @Input() showFullForm: boolean = false;
  @Input() origin: AuthOrigin;
  @Input() fullOrigin: string; // Used for Joining Rewards experiment heap events
  @Input() order: Order;
  authStep: AuthSteps = 'email';

  isGuest: boolean = false;

  showClose: boolean = true;
  loading: boolean = false;
  cookieConsent$: BehaviorSubject<ICookieConsent>;
  returnUrl: string;
  window: Window;
  authMethod: string = '';
  preferredName: string;
  preferredEmail: string;
  accountOfferings: AccountOffering[] = [];
  activeLoginFormControl: 'email' | 'password' = 'email';
  loyaltyWelcomePoints: number = 0;
  loyaltyOrderPoints: number;

  /**
   * Constructor
   * @param userService
   */
  constructor(
    private purchaseService: PurchaseService,
    private userService: UserService,
    public experimentService: ExperimentsService,
    public cookieService: CookieService,
    private stateService: StateService,
    private windowRef: WindowRefService,
    private configService: ConfigService,
    private analyticsService: AnalyticsService,
    private featureService: FeaturesService,
    private loyaltyService: LoyaltyService,
    private modalService: ModalService
  ) {
    super();

    this.window = this.windowRef.nativeWindow;
  }

  /**
   * Check if joining rewards on login
   */
  get isJoiningRewardsOnLogin(): boolean {
    return (
      (this.featureService.getFeature('JOINING_REWARDS').active &&
        (this.experimentService.isActive('AUTO_OPT_IN_TO_REWARDS', 1) || this.experimentService.isActive('AUTO_OPT_IN_TO_REWARDS', 2))) ??
      false
    );
  }

  /**
   * Check if joining rewards variant 2
   */
  get isJoiningRewardsVariant2(): boolean {
    return this.experimentService.isActive('AUTO_OPT_IN_TO_REWARDS', 2);
  }

  /**
   * Check if new forms can be enabled
   * This experiment is for testing only
   */
  get newAuthFormsExperiment(): boolean {
    return this.experimentService.isActive('NEW_AUTH_FORMS', 1);
  }

  /**
   * On init
   */
  ngOnInit(): Promise<void> {
    this.cookieConsent$ = this.cookieService.cookieConsent$;
    this.returnUrl = this.checkIfNeedsToRedirect();
    this.accountOfferings = this.setAccountOfferings();

    return this.loyaltyService.getLoyaltyWelcomePoints().then((points: number): void => {
      this.loyaltyWelcomePoints = points;
      this.calculateLoyaltyPoints();
    });
  }

  /**
   * =========================================
   * New forms logic
   * =========================================
   */
  /**
   * Validate and lookup user based on email
   */
  submitEmail(): void {
    this.authStep = 'password';
  }

  /**
   * Go to introduce email view
   */
  goToEmailView(): void {
    this.authStep = 'email';
  }

  /**
   * Show loading UI
   * @param {boolean} load
   */
  onLoading(load: boolean): void {
    this.loading = load;
  }

  /**
   * Launch untrustworthy login modal
   * @param {Error} error
   * @returns
   */
  onUntrustworthyLogin(error: Error): Promise<void> {
    return this.modalService
      .showLazyModal(
        { name: 'UntrustworthyLoginModalComponent' },
        {
          initialState: {
            title: error.title,
            message: error.message
          },
          class: 'modal-xs',
          trackingKey: 'untrustworthyLoginAttempt',
          trackingValue: 'untrustworthyLoginAttempt',
          dismissDisplayingModals: true
        }
      )
      .then((): void => {})
      .catch((): void => {});
  }

  /**
   * Go to register new user, unless register flow not enabled
   * @param {{ email: string }} event
   */
  goToRegisterNewUser(event: { email: string }): void {
    this.defaultEmail = event.email;
    this.isGuest = false;
    this.selectedTab = this.enableRegister ? 'register' : 'login';
    this.authStep = this.enableRegister ? 'register' : this.authStep;
  }

  /**
   * Select tab
   * @param {AuthTypes} tab
   */
  selectTab(tab: AuthTypes): void {
    this.selectedTab = tab;
  }

  /**
   * On cancel
   */
  onCancel(): void {
    super.closeAsReject({});
  }

  /**
   * On continue as guest
   * @param {string} email
   */
  registerGuestUser(guest: { email: string; user: string }): void {
    this.selectedTab = 'register';
    this.isGuest = true;
    if (guest) {
      this.preferredName = guest.user;
      this.preferredEmail = guest.email;
      this.defaultEmail = this.preferredEmail;
    }
  }

  /**
   * On continue as guest
   * @param {string} email
   */
  continueAsGuest(guest: { email: string; user: string }): void {
    super.closeAsResolve(guest);
  }

  /**
   * Reject login due to third party failure to login
   * Continue as guest
   */
  rejectAndContinueAsGuest(): void {
    super.closeAsReject({
      continueAsGuest: true
    });
  }

  /**
   * On login registered click event
   * @param {string} authMethod
   */
  onLoginRegisterButtonClicked(authMethod?: string): void {
    this.authMethod = authMethod;
  }

  /**
   * These are temporary events(for Universal Analytics only) because the marketing team
   * needs to continue to use these event which exists on the current Bloomon subs, to monitor the performance.
   * Once the Universal Analytics support will be dropepd, also the feature can be removed
   * Card to remove them: https://bloomon.atlassian.net/browse/AD-327
   */
  tempAnalyticsTracking(): void {
    if (this.selectedTab === 'login') {
      this.analyticsService.trackUserLogin(this.authMethod);
      if (this.featureService.getFeature('TRACK_TRANSITION_EVENTS')) {
        this.analyticsService.track('component.auth-modal.user-logged-in', {
          event: 'Log In',
          category: 'Login',
          label: 'email'
        });
      }
    }

    if (this.selectedTab === 'register') {
      this.analyticsService.trackUserRegister(this.authMethod);
      if (this.featureService.getFeature('TRACK_TRANSITION_EVENTS')) {
        this.analyticsService.track('component.auth-modal.user-signed-up', {
          event: 'Sign up',
          category: 'Login',
          label: 'email'
        });
      }
    }
  }

  /**
   * On success, close the modal
   */
  onSuccess(options?: { authMethod?: string }): Promise<void> {
    this.onLoginRegisterButtonClicked(options?.authMethod);

    if (this.returnUrl) {
      this.window.location.href = this.returnUrl;
    }

    const currentPurchase = this.purchaseService.getPurchase();
    const user = this.userService.getUser();

    const joinRewards =
      user.isLoggedIn() && user.loyaltySchemeMembershipId === undefined && this.isJoiningRewardsOnLogin
        ? this.loyaltyService
            .joinLoyaltyMembership()
            .then((): void => this.analyticsService.trackJoiningRewards(this.fullOrigin))
            .catch((): void => {})
        : Promise.resolve;

    const restorePurchase =
      user.isLoggedIn() && this.shouldRestorePurchase && currentPurchase.id
        ? this.purchaseService.restorePurchase(currentPurchase).catch((): void => {})
        : Promise.resolve();

    this.tempAnalyticsTracking();

    return Promise.all([joinRewards, restorePurchase]).then((): void => {
      this.loading = false;
      super.closeAsResolve({});
    });
  }

  /**
   * Renew cookie consent
   */
  renewCookieConsent(): void {
    this.cookieService.renewConsent();
  }

  /**
   * Check if returns valid url
   * @param {string} returnUrl
   * @returns {boolean}
   */
  checkIfReturnUrlIsValid(returnUrl: string): boolean {
    const config = this.configService.getConfig();
    const a = this.window.document.createElement('a');
    a.href = returnUrl;
    const subsPlatformAllowedUrls = config.subsPlatformAllowedUrls || [];
    const urlIsPresent = subsPlatformAllowedUrls.filter((item): boolean => a.hostname.endsWith(item));
    return urlIsPresent.length ? true : false;
  }

  /**
   * Checks if needs to redirect
   * @returns {string}
   */
  checkIfNeedsToRedirect(): string {
    const urlParams = this.stateService.getInitial().params;
    const returnUrl = urlParams?.returnUrl;

    if (returnUrl && this.checkIfReturnUrlIsValid(returnUrl)) {
      return returnUrl;
    }
    return;
  }

  /**
   * On changing active login form control
   * @param {'email' | 'password'} formControl
   */
  onChangingLoginFormControl(formControl: 'email' | 'password'): void {
    this.activeLoginFormControl = formControl;
  }

  /**
   * Set up which account offerings to show
   * @returns {AccountOffering[]}
   */
  private setAccountOfferings(): AccountOffering[] {
    const offerings = this.featureService.getFeature('AUTH_REGISTER_OFFERINGS');

    return ACCOUNT_OFFERINGS.map((o: AccountOffering): AccountOffering => {
      o.show = offerings[o.key];
      return o;
    });
  }

  /**
   * Calculate loyalty points
   */
  private calculateLoyaltyPoints(): void {
    const order = this.order;
    const price = Order.calculateOrderTotal({ ...order });
    this.loyaltyOrderPoints = this.loyaltyService.calculateTotalOrderLoyaltyPoints(price);
  }
}
