import { Review } from 'Shared/classes/review';

export class ProductDetails {
  seoTitle: string;
  descriptionComponents: DescriptionComponents[];
  reviews: Review[];
  contentSpecification?: string;
  aboutSku?: string;
  sustainabilityInfo?: string;
  deliveryInfo?: string;
}

export class DescriptionComponents {
  backgroundColour: string;
  description: string;
  iconName: string;
  kind: string;
}
