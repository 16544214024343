import * as dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import { Country, CountryService } from 'Shared/services/country.service';
import { LocalStorageService } from 'Shared/services/local-storage.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { Product } from 'Shared/classes/product';
import { ProductService } from 'Checkout/services/product.service';
import { PurchaseService } from 'Checkout/services/purchase.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ProductGridService } from 'Project/checkout/services/product-grid.service';
interface IRecentlyViewed {
  productId: number;
  expiresAt: number;
}
@Injectable({
  providedIn: 'root'
})
export class RecentlyViewedService {
  serviceInitialised: boolean = false;
  recentlyViewed: IRecentlyViewed[] = [];
  recentlyViewed$: BehaviorSubject<any> = new BehaviorSubject(this.recentlyViewed);
  expiry: number = dayjs().unix() + 14 * 24 * 60 * 60; // +14 days in seconds

  constructor(
    private localstorageService: LocalStorageService,
    private productService: ProductService,
    private countryService: CountryService,
    private purchaseService: PurchaseService,
    private experimentsService: ExperimentsService,
    private productGridService: ProductGridService
  ) {}

  /**
   * Updates the recently viewed product list
   * TO DO: call this from 'analyticsService.trackProductView'
   * not possible now as product view is not tracked when navigating using product modal or when using subs modal
   * @param product
   */
  update(product: Product): void {
    if (this.serviceInitialised) {
      const recentlyViewed = this.recentlyViewed
        .filter((item) => product.id !== item.productId)
        .slice(0, 9);
      recentlyViewed.unshift({ productId: product.id, expiresAt: this.expiry });
      this.recentlyViewed = recentlyViewed;
      // Update the localStorage
      this.updateLocalStorage(recentlyViewed);
      // Update Observable
      this.recentlyViewed$.next(this.recentlyViewed);
    }
  }

  /**
   * Updates local storage
   * @param recentlyViewedProducts
   * @param validity number of days
   */
  updateLocalStorage(recentlyViewed: IRecentlyViewed[]): void {
    const shippingCountry: Country = this.countryService.forShipping;
    this.localstorageService.set(`recentlyViewed-${shippingCountry.id}`, recentlyViewed);
  }

  /**
   * Gets the skuIds from localstorage, check if data is older than 2 weeks
   * @param shippingCountry
   */
  getFromLocalStorage(): IRecentlyViewed[] {
    const shippingCountry: Country = this.countryService.forShipping;
    const recentlyViewed: IRecentlyViewed[] =
      this.localstorageService.get(`recentlyViewed-${shippingCountry.id}`) || [];

    return recentlyViewed.filter((item) => item.expiresAt > dayjs().unix());
  }

  /**
   * Gets the products using the saved ids
   * @param currentProduct
   * @param maxItems
   */
  getRecentlyViewed(currentProduct?: Product, maxItems: number = 6): Promise<Product[]> {
    const shippingCountry: Country = this.countryService.forShipping;
    const skuIds = this.recentlyViewed.map((item) => item.productId);
    const purchase = this.purchaseService.getPurchase();
    return this.productService
      .getQuickShopProducts(shippingCountry, skuIds, purchase.orders.length, purchase.discount)
      .then((products) =>
        this.productGridService.toGridProduct(
          products.filter((product) => product.id !== currentProduct?.id).slice(0, maxItems),
          [],
          null
        )
      )
      .catch(() => {
        return [];
      });
  }

  /**
   * Init
   */
  init(): void {
    this.recentlyViewed = this.getFromLocalStorage();
    this.serviceInitialised = true;
  }
}
