import { Injectable } from '@angular/core';
import { ExperimentsService } from 'Shared/services/experiments.service';
import * as dayjs from 'dayjs';
import { ConfigService } from 'Shared/services/config.service';
import { CountryService } from 'Shared/services/country.service';

@Injectable({
  providedIn: 'root'
})
export class PeakShippingService {
  public readonly peakShippingCutoffDate: dayjs.Dayjs = dayjs('2024-03-10');

  constructor(
    private experimentService: ExperimentsService,
    private configService: ConfigService,
    private countryService: CountryService
  ) {}

  /**
   * Check if the user is in the peak shipping experiment
   */
  public inFreeDeliveryExperiment(): boolean {
    return this.experimentService.isActive('HPT101_BW_UK_WEB_UK_MDAY_DAY_24_FREE_DELIVERY_MESSAGING', 1);
  }

  /**
   * Check if default for date is free shipping
   * @returns {boolean}
   */
  public isDefaultFreeShipping(): boolean {
    const country = this.countryService.forShipping;
    return this.configService.getConfig().web_default_to_next_free_delivery_date[country.id];
  }
}
