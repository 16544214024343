import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, Input } from '@angular/core';
import { FeaturesService } from 'Shared/services/features.service';
import { OasysLayoutModule } from 'oasys-lib';
import { ExperimentsService } from 'Project/shared/services/experiments.service';
import { ViewportDetectionService, IActiveViewports } from 'Shared/services/viewport-detection.service';
import { BehaviorSubject } from 'rxjs';
import { StateService } from 'Shared/services/state.service';
import { LocationService } from 'Shared/services/location.service';
import { ActivatedState } from 'Shared/classes/activated-state';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { OptimizelyService } from 'Shared/services/third-parties/optimizely.service';

// This component is visible on mobile only

//Create interface for splash categories
export interface SplashCategories {
  active: boolean;
  categories: {
    name: string;
    url: string;
    roundImage: string;
    squareImage: string;
    altText: string;
    hideOnGrid: boolean;
  }[];
}
/* bw:view-encapsulation */
@Component({
  selector: 'bw-splash-categories',
  templateUrl: './splash-categories.component.html',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, OasysLayoutModule]
})
export class SplashCategoriesComponent implements OnInit {
  @Input() pageType: string;

  splashCategories: SplashCategories;
  experimentVariant: number;
  viewportSizeIs$: BehaviorSubject<IActiveViewports> = this.viewportDetectionService.viewportSizeIs$;

  constructor(
    private featuresService: FeaturesService,
    private experimentsService: ExperimentsService,
    private viewportDetectionService: ViewportDetectionService,
    private locationService: LocationService,
    private stateService: StateService,
    private analyticsService: AnalyticsService,
    private optimizelyService: OptimizelyService
  ) {}

  ngOnInit() {
    // If the experiment will be successful, we should get the categories from the backend
    this.splashCategories = this.featuresService.getFeature('SPLASH_CATEGORIES');
    this.experimentVariant = this.experimentsService.getVariantFor('SPLASH_CATEGORIES');

    if (this.splashCategories && this.splashCategories.active && this.viewportSizeIs$.value.mobile) {
      setTimeout(() => {
        this.optimizelyService.trackEvent('viewSplashCategories');

        this.analyticsService.trackInHeap('viewSplashCategories', {
          location: this.pageType === 'gridPage' ? this.stateService.href('checkout.base') : '/'
        });
      }, 100);
    }
  }

  navigate(category): void {
    const url = category ? category.url : '';

    // we split the url here so we can have clean url for state.go
    const splitUrl = url.split('?');
    let params = {};

    if (splitUrl.length > 1) {
      params = this.locationService.getParamsAsObject(`?${splitUrl[1]}`);
    }

    // Creating a new state
    const state = new ActivatedState('checkout.tagOnly', splitUrl[0]);
    this.stateService.go(state, params);

    this.analyticsService.trackInHeap('visualNavInteraction', {
      visualNavItem: category.name,
      cardUrl: category.url,
      location: this.pageType === 'gridPage' ? this.stateService.href('checkout.base') : '/'
    });
  }
}
