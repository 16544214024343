export type CardType =
  | 'card'
  | 'saved-card'
  | 'paypal'
  | 'zero-value'
  | 'new-card'
  | 'ideal'
  | 'bancontact'
  | 'sofort'
  | 'braintree'
  | 'sepa_debit'
  | 'apple_pay'
  | 'android_pay'
  | 'google_pay'
  | 'klarna';

export type CardBrand = 'visa' | 'americanexpress' | 'mastercard' | 'sepa' | 'paypal';

export class Card {
  public brand: string;
  public brandTidied: CardBrand;
  public kind: CardType;
  public expiry: {
    year: number;
    month: number;
  };
  public id: number;
  public last4: string;
  public name: string;
  public token: string;
  public isTemporary: boolean = true;
}
