import * as clone from 'clone';
import * as dayjs from 'dayjs';
import { Price, CurrencyCode } from 'Shared/classes/price';
import { Product } from 'Shared/classes/product';

export class ShippingOption {
  public product: Product; // Each shippingOption needs the correct product - for express upsell
  currency: CurrencyCode;
  cutoff: dayjs.Dayjs;
  hasSlots: boolean;
  message: string;
  maxNoteLength: number;
  name: string;
  description: string;
  price: Price;
  id: number;
  subsequentDeliveries: ShippingOption;
  successRateMessage?: string;

  hasPhoneNumber: boolean;
  hasPhoneNumberRequired: boolean;
  hasGiftCard?: boolean;
  daysForDelivery: number;
  /**
   * Set the cut off
   * @param date
   */
  setCutOff(date: string): void {
    this.cutoff = dayjs(date);
  }

  /**
   * Clone the instance
   */
  clone(): ShippingOption {
    return clone(this);
  }

  /**
   * Get the total price for number of deliveries that use this shipping option
   * @param quantity
   */
  getPriceForNumberOfDeliveries(quantity: number): Price {
    let original = this.price.original;
    let discounted = this.price.price;

    const numberOfSubsequentDeliveries = quantity === -1 ? 0 : quantity - 1;

    const subsequentPrice = this.subsequentDeliveries ? this.subsequentDeliveries.price : this.price || new Price('GBP', 0, 0);

    original += numberOfSubsequentDeliveries * subsequentPrice.original;
    discounted += numberOfSubsequentDeliveries * subsequentPrice.price;

    return new Price(this.price.currency, quantity, original, discounted);
  }
}
