/* eslint-disable */
export const translations = {
  "js.account.modals.edit_address.alert_delivery_heading": "Hit save to send your next delivery here",
  "js.account.modals.edit_address.alert_subs_heading": "Hit save to send all future deliveries here",
  "js.account.occasions.login.error": "Sorry, unable to login!",
  "js.auth-register.auto_key.string_9_1": "Keep me updated with special offers, exciting products and news from Bloom & Wild.",
  "js.auth-register.auto_key.string_9_2": "You can view our #[a(href=\"/privacy-statement\" target=\"_blank\") privacy statement here] and unsubscribe at any time.",
  "js.blog.prefix": "the-blog",
  "js.branchio.buttons.download": "Download",
  "js.branchio.buttons.open": "OPEN",
  "js.branchio.labels.title": "Bloom & Wild - Flower Delivery",
  "js.bwFlashMessage.checkout.bwCarousel.error.no_blooms_found": "No Blooms found, please try again later.",
  "js.bwFlashMessage.checkout.bwCarousel.info.no_bouquets_matched": "No bouquets matched your search. Redirecting you to a full list of our flowers.",
  "js.bwFlashMessage.checkout.bwCarousel.sku_selected": "%s successfully selected",
  "js.bwFlashMessage.checkout.bwCarousel.success.dicount_code_applied": "%s applied successfully - %s",
  "js.bwFlashMessage.checkout.bwSummary.paypal_error": "Sorry, PayPal could not process your payment request. Please try paying with a card or get in touch <b><a href=\"/help\" target=\"_blank\">here</a></b>",
  "js.bwFlashMessage.checkout.bwSummary.paypal_popup_blocked": "PayPal was unable to load. Please check that your browser is not blocking pop-ups",
  "js.bwFlashMessage.checkout.bwSummary.paypal_popup_closed": "The PayPal payment window was closed. Please try again if you wish to pay via PayPal",
  "js.bwFlashMessage.checkout.now.includes_delivery": "You're now viewing our same day range - prices include delivery.",
  "js.bwFlashMessage.checkout.polling_timeout": "Sorry, We could not process your payment request. Please get in touch <b><a href=\"/help\" target=\"_blank\">here</a></b>",
  "js.bwFlashMessage.generic.address.details_saved": "Your delivery has been updated",
  "js.bwFlashMessage.generic.auth.404": "404 - Not found",
  "js.bwFlashMessage.generic.auth.fb_login_failed": "Facebook login failed",
  "js.bwFlashMessage.generic.auth.fb_login_success": "You are now logged in",
  "js.bwFlashMessage.generic.auth.logged_in": "You are now logged in",
  "js.bwFlashMessage.generic.auth.registartion_complete": "Registration complete. You're logged in as %s",
  "js.bwFlashMessage.generic.auth.reset_password_sent": "Reset password email sent",
  "js.bwFlashMessage.generic.auth.youve_logged_in": "You are now logged in as %s",
  "js.bwFlashMessage.generic.auth.youve_logged_out": "You've logged out",
  "js.bwFlashMessage.generic.cards.card_deleted": "Your card has been deleted",
  "js.bwFlashMessage.generic.cards.card_saved": "Your card has been saved",
  "js.bwFlashMessage.generic.details.details_saved": "Your details have been saved",
  "js.bwFlashMessage.generic.details.password_saved": "Your password has been updated successfully",
  "js.bwFlashMessage.generic.occasions.added": "New Occasion Added",
  "js.bwFlashMessage.generic.occasions.unable_to_refresh": "Unable to refresh occasions",
  "js.bwFlashMessage.generic.occasions.updated": "Occasion Updated",
  "js.bwFlashMessage.generic.unknown_error": "Unknown error",
  "js.bwFlashMessage.myaccount.addresses.address_added": "Address has been created",
  "js.bwFlashMessage.myaccount.addresses.address_edited": "Address has been edited",
  "js.bwFlashMessage.myaccount.addresses.billing_selected": "Billing address set",
  "js.bwFlashMessage.myaccount.addresses.deleted": "Address deleted",
  "js.bwFlashMessage.myaccount.order_date.unable_message": "To update the delivery date to tomorrow please contact customer support (details below)",
  "js.bwFlashMessage.myaccount.subscription.resumed": "Subscription resumed.",
  "js.checkout.order_details.delivery_message.default_text": "Delivery is available via %s for %s. %s",
  "js.checkout.payment.info_modal.close": "Close",
  "js.checkout.summary.labels.paying_with_ending": "ending",
  "js.checkoutctrl.add_to_basket.success": "Item added to the basket",
  "js.checkoutctrl.order_update.success": "Order has been updated",
  "js.classes.AvailabilityFactory.single": "Single Bouquet",
  "js.classes.AvailabilityFactory.subscription": "subscription",
  "js.classes.AvailabilityFactory.subscription_1": "Subscription",
  "js.classes.CountriesFactory.regex_fail_message": "Please enter a valid postcode",
  "js.classes.DiscountFactory.invalid_tracking_code": "Invalid tracking code",
  "js.classes.DiscountFactory.no_tracking_code": "No tracking code present",
  "js.classes.OccasionsFactory.suggestions.other": "Other",
  "js.component.account.occasions-entry.invalid-discount-error": "Sorry, discount invalid for user",
  "js.component.account.occasions-entry.not-enough-error": "Sorry, currently not enough occasions",
  "js.component.auth-register.offering-account": "Edit your orders",
  "js.component.auth-register.offering-checkout": "Speedy checkout",
  "js.component.auth-register.offering-invoices": "Download invoices",
  "js.component.auth-register.offering-occasions": "Save occasions",
  "js.component.bday.chip.rewards.line1": "Your birthday treat",
  "js.component.bday.chip.rewards.line2": "We’ll email you a code three days before your birthday.",
  "js.component.bday.chip.rewards.line3": "50% discount",
  "js.component.bday.configure.btn": "Add your birthday",
  "js.component.bday.configure.date.age": "Oops! You must be at least 16 years old.",
  "js.component.bday.configure.date.invalid": "Please enter a valid date",
  "js.component.bday.configure.date.not-born": "Oops! That’s in the future. Please try again.",
  "js.component.bday.configure.date.too-old": "Oops! That’s over 100 years ago. Please try again.",
  "js.component.bday.configure.modal-btn": "Confirm birthday",
  "js.component.bday.configure.modal-title": "When’s your birthday?",
  "js.component.bday.rewards.line1": "Psst... fancy a birthday treat?",
  "js.component.bday.rewards.line2": "Tell us your birthday, and we’ll send you an exclusive 50% off.",
  "js.component.checkout.gift-options.footer.checkout_btn": "Checkout",
  "js.component.checkout.gift-options.footer.continue_btn": "Continue",
  "js.component.checkout.gift-options.footer.continue_default_btn": "Continue with default",
  "js.component.checkout.gift-options.footer.skip_btn": "Skip",
  "js.component.delivery-view-modal.greeting-card-cover-alt": "Greeting card cover",
  "js.component.discount-code.invalid_with_discount": "Oh no! We could not find a discount for code %s. Please feel free to use code %s for 10% off.",
  "js.component.feedback.quality-question": "How easy was it to report your quality issue?",
  "js.component.footer-breadcrumb.send-flowers": "Send flowers",
  "js.component.footer-breadcrumb.shop": "Shop",
  "js.component.gift-card-modal.cover-tab.name": "Card cover",
  "js.component.gift-card-modal.cover-tab.shortname": "Cover",
  "js.component.gift-card-modal.message-tab.name": "Message",
  "js.component.greeting-card-confirm-modal.body": "Are you sure you want to remove this greeting card from your order? <br/ >You will lose any message that you have written.",
  "js.component.greeting-card-confirm-modal.cancel": "Cancel",
  "js.component.greeting-card-confirm-modal.confirm": "Confirm",
  "js.component.greeting-card-confirm-modal.title": "Remove greeting card",
  "js.component.greeting-card-message.character-count": "%s of %s characters",
  "js.component.greeting-card-message.placeholder": "A few heartfelt words can mean the world. Remember to include your name so they know it's from you.",
  "js.component.greeting-card.actions.initial.message.textarea": "To ,From",
  "js.component.greeting-card.actions.message.label.textarea": "Card Message",
  "js.component.greeting-card.actions.message.textarea": "To %s,\nFrom %s",
  "js.component.help-center-quality-modal.cd-reason": "Help Centre | Contact from quality self serve",
  "js.component.help-center.modal.contact-us-reason": "Where are flowers - Bloom & Wild Delivery",
  "js.component.help-center.modal.contact-us-resolution-1": "[Select from dropdown]",
  "js.component.help-center.modal.contact-us-resolution-2": "I would like a new bouquet to be delivered",
  "js.component.help-center.modal.contact-us-resolution-3": "I would like to request credit to the sender’s bloom & wild account",
  "js.component.help-center.modal.contact-us-resolution-4": "I just want to let you know...",
  "js.component.info-message.deliveries": "%s deliveries",
  "js.component.info-message.delivery": "delivery",
  "js.component.info-message.subs-upgrade-default": "%s per bouquet with %s. Subsequent bouquets will be selected for you by our expert florists. You can pause or cancel whenever you need to.",
  "js.component.info-message.subs-upgrade-discount": "%s for your first %s, starting with %s. After that, you’ll be charged the usual %s per delivery. You can pause or cancel whenever you need to.",
  "js.component.info-message.subscription-default": "%s per bouquet. You can pause or cancel whenever you need to.",
  "js.component.info-message.subscription-discount": "%s for your first %s. After that, you’ll be charged the usual %s per delivery. You can pause or cancel whenever you need to.",
  "js.component.marketing.label.discount_promotions_preference": "Discount Promotions",
  "js.component.marketing.label.fathers_day_preference": "Father's Day",
  "js.component.marketing.label.grandmothers_day_preference": "Grandmother's Day",
  "js.component.marketing.label.grandparents_day_preference": "Grandparent's Day",
  "js.component.marketing.label.holiday_season_preference": "Holiday Season",
  "js.component.marketing.label.mothers_day_preference": "Mother's Day",
  "js.component.marketing.label.valentines_day_preference": "Valentine's Day",
  "js.component.mega-nav-buttom.sort-options.available-soonest": "Available soonest",
  "js.component.mega-nav-buttom.sort-options.available-soonest.cta": "Date",
  "js.component.mega-nav-buttom.sort-options.price-high-low": "Price high to low",
  "js.component.mega-nav-buttom.sort-options.price-low-high": "Price low to high",
  "js.component.mega-nav-buttom.sort-options.price.cta": "Price",
  "js.component.mega-nav-buttom.sort-options.recommended": "Default",
  "js.component.occasions-modal.configure.date.invalid": "Oops! That’s not a valid date. Please try again.",
  "js.component.occasions-modal.configure.day.required": "Enter a number between 1 and 31",
  "js.component.occasions-modal.configure.month.required": "Enter a number between 1 and 12",
  "js.component.occasions-modal.configure.other.required": "Occasion is required",
  "js.component.occasions-modal.configure.recipient_name.required": "Recipient name is required",
  "js.component.order-list.success-referral-reward.message": "This means your next full-price subscription delivery will be free. Zilch. Nada.",
  "js.component.order-list.success-referral-reward.title": "You’ve earned a free bouquet!",
  "js.component.order-subscription-status-modal.alert-pause-header": "We’ll hold tight until you’re ready. Just click resume to start your deliveries again!",
  "js.component.order-subscription-status-modal.cancel-label": "Cancel my subscription",
  "js.component.order-subscription-status-modal.pause-label": "Pause my deliveries",
  "js.component.order-subscription-status-modal.reason-1": "Delivery problems",
  "js.component.order-subscription-status-modal.reason-2": "Unhappy with the flower quality",
  "js.component.order-subscription-status-modal.reason-3": "Too expensive",
  "js.component.order-subscription-status-modal.reason-4": "Not in my budget this month",
  "js.component.order-subscription-status-modal.reason-5": "Planned to cancel after a certain date",
  "js.component.order-subscription-status-modal.reason-6": "Just need a short break",
  "js.component.order-subscription-status-modal.reason-7": "Found an alternative I like better",
  "js.component.order-subscription-status-modal.reason-8": "Something else",
  "js.component.order-view-subscription-delivery.billed-header": "We’ve now charged you for this delivery.",
  "js.component.order-view-subscription-delivery.delivered-header": "It's been delivered.",
  "js.component.order-view-subscription-delivery.locked-header": "Your delivery is almost on its way.",
  "js.component.order-view-subscription-delivery.paused-default-action": "Sorry, it looks like this subscription is paused. Please resume your subscription to manage your deliveries",
  "js.component.order-view-subscription-delivery.shipped-header": " Your delivery has now been shipped.",
  "js.component.order-view-subscription-delivery.uneditable-default-action": "Sorry, we're now processing your delivery and are no longer able to make changes",
  "js.component.order-view.self-cancellation.fail": "There was a problem cancelling your order. Please contact our Customer Delight team who can help you further.",
  "js.component.order-view.self-cancellation.success": "All good – we’ve cancelled your order, and refunded your account.",
  "js.component.payment-option-modal.saved-display-name": "Card ending %s",
  "js.component.payment-options.add_new_card": "Add new card",
  "js.component.product-addons.bundle-upsell.string": "We’ll send this with their first delivery only.",
  "js.component.product-addons.description.string_1": "There's still space in the box for one extra treat",
  "js.component.product-addons.description.string_2": "Your extra something will be included in the box",
  "js.component.product-addons.title.string_1": "Add a little",
  "js.component.product-addons.title.string_2": "something",
  "js.component.product-addons.title.string_3": "They're going to love it",
  "js.component.product-addons.toaster.invalid": "Oh no... That extra little treat isn't available for delivery then!",
  "js.component.product-addons.toaster.invalid-gift-card": "Oh no.. that gift card isn’t available for delivery then!",
  "js.component.product-addons.toaster.invalid-gift-card.detail": "But you can choose another one or we will select one for you.",
  "js.component.product-addons.toaster.invalid-greeting-card": "Oh no.. that gift card isn’t available for delivery then!",
  "js.component.product-addons.toaster.invalid-greeting-card.detail": "But you can choose another one or we will select one for you.",
  "js.component.product-addons.toaster.invalid.detail": "But you can swap it for something else or change your delivery date.",
  "js.component.product-card-modal.5-discount": "save 5%",
  "js.component.product-card-modal.8-discount": "save 8%",
  "js.component.product-card-modal.fixed": "Fixed / Pre-paid",
  "js.component.product-card-modal.ongoing": "Ongoing subscription",
  "js.component.product-card-modal.ongoing-short": "Ongoing",
  "js.component.product-card-modal.six-months": "6 months",
  "js.component.product-card-modal.three-months": "3 months",
  "js.component.product-card-modal.twelve-months": "12 months",
  "js.component.product-card-subscription.generic-name": "Monthly Flowers",
  "js.component.product-filter.all": "All",
  "js.component.product-filter.any": "Any",
  "js.component.product-filter.style.classic": "Timeless favourites that are guaranteed to please.",
  "js.component.product-filter.style.expanded.classic": "Timeless favourites that are guaranteed to please.",
  "js.component.product-filter.style.expanded.limitededition": "Luxury bouquets like nothing else they've been sent before.",
  "js.component.product-filter.style.expanded.luxury": "Those rare, extra-special stems you’d usually find at flower markets.",
  "js.component.product-filter.style.expanded.petfriendly": "Some flowers and plants are toxic to our furry friends. <a class='bwLink strong' target='_blank' href='/pet-friendly'> Learn More</a>",
  "js.component.product-filter.style.expanded.wild": "Whimsical wildflowers with a real meadowy charm.",
  "js.component.product-filter.style.limitededition": "Luxury bouquets like nothing else they've been sent before.",
  "js.component.product-filter.style.luxury": "Those rare, extra-special stems you’d usually find at flower markets.",
  "js.component.product-filter.style.petfriendly": "Some flowers and plants are toxic to our furry friends.",
  "js.component.product-filter.style.wild": "Whimsical wildflowers with a real meadowy charm.",
  "js.component.recipient-search.address.label": "Recipient address",
  "js.component.recipient-search.address.label-subs-search": "Search Address",
  "js.component.recipient-search.address.label-subs-set": "Delivery Address",
  "js.component.recipient-search.name.manual": "Add as a new recipient",
  "js.component.reviews-display.intro": "#[p But don’t just take our word for it. Here’s what everyone’s been saying about us...]",
  "js.component.reviews-display.tagline": "We're the UK's most-loved online florist*",
  "js.component.rewards-summary.encouraging": "You’re nearly there! Send %s more before <b>%s</b> to get your last stamp!",
  "js.component.special-code.is-applied": "is applied",
  "js.component.special-code.label.applied_discount": "Special code applied",
  "js.component.special-code.label.applied_voucher": "Gift code applied",
  "js.component.special-code.label.empty_1": "Do you have a special code?",
  "js.component.special-code.label.empty_2": "Gift card or special code",
  "js.component.timeslots.error": "Sorry we don't offer same day delivery to this area, but you can still get free nationwide delivery within 1-2 days",
  "js.component.user-details.opt-in.all": "Everything! I don't like missing out.",
  "js.component.user-details.opt-in.none": "Nothing, thank you",
  "js.component.user-details.opt-in.seasonal": "A little nudge when big calendar dates come up.",
  "js.component.user-details.opt-in.weekly": "A weekly round up of newness and seasonal treats.",
  "js.component.user-details.soft_gdpr.label": "Nach dem Kauf senden wir Ihnen regelmäßig ausgesuchte Angebote zu ähnlichen Produkten aus unserer Kollektion. Sie können dem Erhalt von Werbe-Emails jederzeit widersprechen.",
  "js.component.whisper-usps.content-a": "Free next day & chosen day delivery",
  "js.component.whisper-usps.content-b": "Free greetings cards in lovely designs",
  "js.component.whisper-usps.content-c": "Rated Excellent on Trustpilot",
  "js.component.whisper-usps.content-d": "Proudly B Corp™ certified",
  "js.component.whisper-usps.logo-a": "delivery",
  "js.component.whisper-usps.logo-b": "/assets/brand-icons/greeting-card.svg",
  "js.component.whisper-usps.logo-c": "/assets/images/Trustpilot_ratings_5star-RGB.svg",
  "js.component.whisper-usps.logo-d": "//images.ctfassets.net/ucgi79tscdcj/4AZwZ3wf5nmnP0ArwhkKD8/d75f0dc087abc6e3ceae5635fae0de7f/B-Corp-Logo-Black-RGB.png",
  "js.components.account.occasion-option-date.mothers_day": "2022-03-27",
  "js.components.account.occasion-option.anniversary": "Anniversary",
  "js.components.account.occasion-option.anniversary_variant": "Special Anniversary",
  "js.components.account.occasion-option.birthday": "Birthday",
  "js.components.account.occasion-option.friend_birthday": "Friend's Birthday",
  "js.components.account.occasion-option.mothers_day": "Mother's Day",
  "js.components.account.occasion-option.mum_birthday": "Mum's Birthday",
  "js.components.account.occasion-option.other": "Something else",
  "js.components.account.occasion-option.valentines_day": "Valentine's Day",
  "js.components.account.occasion-recipent.mum": "Mum",
  "js.components.address-form.apartment.label": "Apartment",
  "js.components.address-form.building.label": "Building",
  "js.components.address-form.door-code.label": "Door code",
  "js.components.address-form.floor.label": "Floor",
  "js.components.address-form.phone.helper_new": "We use this to make sure we can successfully deliver to the recipient but we won't spoil the surprise.",
  "js.components.address-form.phone.helper_test": "We use this to make sure we can successfully deliver to the recipient but we won't spoil the surprise.",
  "js.components.addresss-list.cancel": "Cancel",
  "js.components.addresss-list.delete.body": "Are you sure you want to permanently delete this address",
  "js.components.addresss-list.delete.title": "Delete address",
  "js.components.addresss-list.modal.billing_title": "Add new billing address",
  "js.components.addresss-list.modal.title": "Add new address",
  "js.components.addresss-list.success": "Delete address",
  "js.components.app.discount.message.already.message": "Discount code already applied",
  "js.components.app.discount.messages.already.title": "Your basket already has a discount code applied. Please proceeed to payment to change your discount code",
  "js.components.app.discount.messsage.applied.message": "Code %s successfully added",
  "js.components.app.persistent.basket.error": "Unfortunately, we could not fully restore your basket. Please review your basket and continue shopping.",
  "js.components.auth-reset-password.success.message": "Password reset email sent",
  "js.components.billing-address.heading": "Billing Address",
  "js.components.blogs-landing-page.placeholder-alt-text": "Blog placeholder image, displaying the Bloom and Wild logo",
  "js.components.blogs-list-page.tag.base_url": "blog/tagged",
  "js.components.bundle-upsell.month": "month",
  "js.components.burger-menu.confirm-logout.bodyMultiple": "You currently have %s items in your basket. Logging out will clear your basket and your progress will be lost, are you sure you want to logout?",
  "js.components.burger-menu.confirm-logout.bodySingle": "You currently have %s item in your basket. Logging out will clear your basket and your progress will be lost, are you sure you want to logout?",
  "js.components.burger-menu.confirm-logout.cancel": "Cancel",
  "js.components.burger-menu.confirm-logout.success": "Logout and clear basket",
  "js.components.burger-menu.confirm-logout.title": "Clear basket and log out",
  "js.components.burger-menu.logout.confirm": "You have logged out",
  "js.components.bwAccountCredit.msg.success": "Credit has been applied to you account",
  "js.components.bwAccountEmail.labels.all": "All emails",
  "js.components.bwAccountEmail.labels.all.description": "Don’t miss a single offer, collection launch, or inspirational trend. We only send thoughtful emails.",
  "js.components.bwAccountEmail.labels.seasonal": "Seasonal events",
  "js.components.bwAccountEmail.labels.seasonal-newsletters": "Seasonal newsletters",
  "js.components.bwAccountEmail.labels.seasonal-newsletters-description": "Only get special offers for our biggest occasions like Mother's Day, Christmas, Valentine's Day and birthdays",
  "js.components.bwAccountEmail.labels.unsubscribe": "Unsubscribe from all emails",
  "js.components.bwAccountEmail.labels.unsubscribe-description": "We'll still email you about your order. But you won’t get any offers, sale reminders or marketing emails.",
  "js.components.bwAccountEmail.labels.weekly": "Weekly newsletter",
  "js.components.bwAccountEmail.labels.weekly-description": "Get just one well-chosen email a week. (But you may miss out on some offers.)",
  "js.components.bwAccountEmail.messages.all": "VIP access for new blooms and reminders for your most important occasions, sent with love.",
  "js.components.bwAccountEmail.messages.seasonal": "Like a clear inbox? Only the special offers for our biggest occasions like Mother's Day, Christmas, Valentine's Day and birthdays.",
  "js.components.bwAccountEmail.messages.unsubscribe": "We'll still email you in relation to your orders, but won't send any marketing emails",
  "js.components.bwAccountEmail.messages.weekly": "All the good stuff, just once a week.",
  "js.components.bwAccountOrderview.labels.cancelled": "cancelled",
  "js.components.bwAccountOrderview.labels.confirmed": "confirmed",
  "js.components.bwAccountOrderview.labels.delivered": "delivered",
  "js.components.bwAccountOrderview.labels.paused": "paused",
  "js.components.bwAccountOrderview.labels.resent": "resent",
  "js.components.bwAccountOrderview.labels.shipped": "shipped",
  "js.components.bwGiftCardMessage.generated_message": "To %s,\n\nFrom %s",
  "js.components.bwGiftCardModal.cover_title": "Card cover",
  "js.components.bwGiftCardModal.message_title": "Card message",
  "js.components.bwMultipleDeliveryDiscount.message": "%s for your first bouquet, %s per subsequent delivery",
  "js.components.bwNextDeliveryDateMessage.confirm_title": "Confirm delivery date",
  "js.components.bwNextDeliveryDateMessage.message": "Please note - the earliest delivery date for your postcode is %s",
  "js.components.bwNextDeliveryDateMessage.message_date_format": "dddd, D MMMM",
  "js.components.bwProductSort.sort": "Sort",
  "js.components.cards-list.delete.body": "Are you sure you want to permanently delete this card",
  "js.components.cards-list.delete.cancel": "Cancel",
  "js.components.cards-list.delete.success": "Delete card",
  "js.components.cards-list.delete.title": "Delete card",
  "js.components.checkout_neue.log_in_check_delivery": "Please check and confirm the recipient name and address before continuing",
  "js.components.contact_us.form.problem-1": "Subscriptions",
  "js.components.contact_us.form.problem-2": "The webshop",
  "js.components.contact_us.form.problem-3": "Something else",
  "js.components.contact_us.form.reason": "Flowers and gifts - Send us a message",
  "js.components.credit.redeem.success": "Credit has been applied",
  "js.components.delivery-search.format": "dddd, D MMMM",
  "js.components.error-msg.delivery-dates": "No delivery dates available",
  "js.components.gift-card.generated_message": "To %s,\nFrom %s",
  "js.components.info-messages.bundle": "%s deliveries pre-paid at %s per bouquet.<br /> Starting with %s, subsequent bouquets will be selected for you by our expert florists. If you’ve added on an extra gift, this will arrive with your first delivery only.",
  "js.components.info-messages.bundle_with_saving": "%s deliveries pre-paid at %s per bouquet. (Saving %s)<br /> Starting with %s, subsequent bouquets will be selected for you by our expert florists. If you’ve added on an extra gift, this will arrive with your first delivery only.",
  "js.components.info-messages.subscription": "%s per bouquet, starting with %s. <br /> Subsequent bouquets will be selected for you by our expert florists. Pause or cancel at any time.",
  "js.components.info-messages.subscription_is_discounted": "%s per bouquet (first bouquet %s), starting with %s.<br /> Subsequent bouquets will be selected for you by our expert florists. Pause or cancel at any time.",
  "js.components.info-messages.subscription_is_discounted_new": "Your subscription will start with %s at a welcome price of %s.<br /> After that, our florists will send you different bouquets and you'll be charged the usual %s per delivery.<br />You can pause or cancel whenever you need to.",
  "js.components.launch-modal.createAccount.title": "Create account",
  "js.components.launch-modal.resetPassword.title": "Create new password",
  "js.components.location-reviews.review-action-text_0": "sent flowers to",
  "js.components.location-reviews.review-action-text_1": "delivered a bouquet to",
  "js.components.location-reviews.review-action-text_2": "delivered flowers to",
  "js.components.location-reviews.review-action-text_3": "sending flowers to",
  "js.components.logout.success": "You have logged out",
  "js.components.meta-breadcrumb.home": "Homepage",
  "js.components.newsletter.invalid-email": "Invalid email address",
  "js.components.occasions-entry-modal.occasion.other": "other",
  "js.components.occasions-list.delete.body": "Are you sure you want to permanently delete this occasion?",
  "js.components.occasions-list.delete.cancel": "Cancel",
  "js.components.occasions-list.delete.success": "Delete occasion",
  "js.components.occasions-list.delete.title": "Delete occasion",
  "js.components.occasions-reminders.modal.add_gifting_reminder": "Add gifting reminder",
  "js.components.occasions-reminders.modal.auth.subtitle": "We just need to create an account for you so we can save these somewhere safe",
  "js.components.occasions-reminders.modal.auth.title": "Nearly there",
  "js.components.occasions-reminders.modal.offer.success": "%s credit has been added",
  "js.components.occasions-reminders.modal.reminder_saved_subtitle": "We will send you a reminder near the date",
  "js.components.occasions-reminders.modal.reminder_saved_title": "Occasion saved",
  "js.components.order-edit-form.update.error.message": "No product selected",
  "js.components.order-edit-form.update.error.title": "Please select a gift to send or cancel to return to your basket",
  "js.components.order-edit-form.update.message": "Updating your order",
  "js.components.order-express-form.loading.message": "Adding to basket",
  "js.components.order-form.messages.discount.applied": "Code %s successfully added",
  "js.components.order-form.messages.discount.applied_amount": "%s discount applied",
  "js.components.order-form.messages.loading.checking": "Checking address...",
  "js.components.order-form.messages.no-products.message": "Redirecting you to a full list of our flowers.",
  "js.components.order-form.messages.no-products.title": "No bouquets matched your search.",
  "js.components.order-form.messages.no_addons_for_sub_message": "Unfortunately we can't include extras like %s on Subscriptions",
  "js.components.order-form.messages.no_addons_for_sub_title": "To add extras switch back to Single Bouquet and select it again.",
  "js.components.order-new-form.loading.message": "Adding to basket",
  "js.components.password-modal.message.success": "Your password has been changed",
  "js.components.payment-credit.message": "%s Credit has been applied",
  "js.components.payment-options.types.apple_pay": "Apple Pay",
  "js.components.payment-options.types.bancontact": "Bancontact",
  "js.components.payment-options.types.card": "Card",
  "js.components.payment-options.types.google_pay": "Google Pay",
  "js.components.payment-options.types.ideal": "iDEAL",
  "js.components.payment-options.types.klarna": "Klarna",
  "js.components.payment-options.types.paypal": "PayPal",
  "js.components.payment-options.types.sepa": "Sepa",
  "js.components.payment-options.types.sofort": "Sofort",
  "js.components.payment.loading.basket": "Loading Basket",
  "js.components.payment.loading.completing": "Completing purchase",
  "js.components.payment.loading.confirming": "Confirming basket",
  "js.components.payment.loading.message": "Contacting payment provider",
  "js.components.reset-password-modal.message.success": "Your password has been changed",
  "js.components.restore.fail_title": "Sorry, we weren't able to fetch your basket",
  "js.components.seo_links.links.flower_delivery": "Flower Delivery",
  "js.components.subs_promo.about-modal-title": "Meet our flower subscriptions",
  "js.components.subscription.invoices": "Subscription invoices",
  "js.components.subscrition-cancel.reason0": "Delivery problems",
  "js.components.subscrition-cancel.reason1": "Quality of flowers",
  "js.components.subscrition-cancel.reason2": "Bouquets looked different to their pictures",
  "js.components.subscrition-cancel.reason3": "Bored with the bouquet variety",
  "js.components.subscrition-cancel.reason4": "Service not flexible enough",
  "js.components.subscrition-cancel.reason5": "Too expensive",
  "js.components.subscrition-cancel.reason6": "Changing my monthly spending budget",
  "js.components.subscrition-cancel.reason7": "Planned to cancel after a certain date",
  "js.components.subscrition-cancel.reason8": "Only wanted flowers once",
  "js.components.subscrition-cancel.reason9": "Something else ",
  "js.components.web-payment.label": "Bloom & Wild",
  "js.confirm.page.banners.desktop.mention-me.sub": "Invite your friends and get 50% off your next bouquet!",
  "js.countdown.stopwatch_days": "d",
  "js.countdown.stopwatch_hours": "h",
  "js.countdown.stopwatch_minutes": "m",
  "js.countdown.stopwatch_seconds": "s",
  "js.countdown.time_over": "It's too late",
  "js.country.at": "Austria",
  "js.country.be": "Belgium",
  "js.country.de": "Germany",
  "js.country.dk": "Denmark",
  "js.country.fr": "France",
  "js.country.gb": "United Kingdom",
  "js.country.ie": "Ireland",
  "js.country.nl": "Netherlands",
  "js.cutoff.countdown.free_delivery": "for free next day delivery",
  "js.cutoff.countdown.hours_plural": "hours",
  "js.cutoff.countdown.hours_single": "hour",
  "js.cutoff.countdown.minutes_plural": "minutes",
  "js.cutoff.countdown.minutes_single": "minute",
  "js.cutoff.countdown.paid_delivery": "for next day delivery",
  "js.cutoff.countdown.send_next": "Send in the next <b> %s </b>",
  "js.dateHelper.days.asap": "ASAP",
  "js.dateHelper.days.today": "Today",
  "js.dateHelper.days.tomorrow": "Tomorrow",
  "js.dateHelper.days.yesterday": "Yesterday",
  "js.dateHelper.days_since": "%s days ago",
  "js.dateHelper.frequency.12_month_singular": "12 Month",
  "js.dateHelper.frequency.12_months": "12 Months",
  "js.dateHelper.frequency.3_month_singular": "3 Month",
  "js.dateHelper.frequency.3_months": "3 Months",
  "js.dateHelper.frequency.6_month_singular": "6 Month",
  "js.dateHelper.frequency.6_months": "6 Months",
  "js.dateHelper.frequency.every": "Every",
  "js.dateHelper.frequency.every-n-week": "%s week",
  "js.dateHelper.frequency.every-n-weeks": "%s weeks",
  "js.dateHelper.frequency.fortnightly": "Two Weeks",
  "js.dateHelper.frequency.monthly": "Month",
  "js.dateHelper.frequency.on_going": "Ongoing",
  "js.dateHelper.frequency.weekly": "Week",
  "js.datepicker.label.day.friday": "Friday",
  "js.datepicker.label.day.fridays": "Fridays",
  "js.datepicker.label.day.monday": "Monday",
  "js.datepicker.label.day.mondays": "Mondays",
  "js.datepicker.label.day.saturday": "Saturday",
  "js.datepicker.label.day.saturdays": "Saturdays",
  "js.datepicker.label.day.sunday": "Sunday",
  "js.datepicker.label.day.sundays": "Sundays",
  "js.datepicker.label.day.thursday": "Thursday",
  "js.datepicker.label.day.thursdays": "Thursdays",
  "js.datepicker.label.day.tuesday": "Tuesday",
  "js.datepicker.label.day.tuesdays": "Tuesdays",
  "js.datepicker.label.day.wednesday": "Wednesday",
  "js.datepicker.label.day.wednesdays": "Wednesdays",
  "js.datepicker.label.month.april": "April",
  "js.datepicker.label.month.august": "August",
  "js.datepicker.label.month.december": "December",
  "js.datepicker.label.month.february": "February",
  "js.datepicker.label.month.january": "January",
  "js.datepicker.label.month.july": "July",
  "js.datepicker.label.month.june": "June",
  "js.datepicker.label.month.march": "March",
  "js.datepicker.label.month.may": "May",
  "js.datepicker.label.month.november": "November",
  "js.datepicker.label.month.october": "October",
  "js.datepicker.label.month.september": "September",
  "js.datepicker.label.next.month": "Next Month",
  "js.datepicker.label.previous.month": "Previous Month",
  "js.datepicker.label.short-day.friday": "Fri",
  "js.datepicker.label.short-day.monday": "Mon",
  "js.datepicker.label.short-day.saturday": "Sat",
  "js.datepicker.label.short-day.sunday": "Sun",
  "js.datepicker.label.short-day.thursday": "Thu",
  "js.datepicker.label.short-day.tuesday": "Tue",
  "js.datepicker.label.short-day.wednesday": "Wed",
  "js.directives.checkout.bwAvailabilityCategories.bundleButton.buttonObject.subtitleAppend": "<span class=\"cta-discount\">%s</span>",
  "js.directives.checkout.bwAvailabilityCategories.bundleButton.buttonObject.subtitlePrepend": "<span class=\"cta-small\">FROM </span>",
  "js.directives.checkout.bwAvailabilityCategories.bundleButton.buttonObject.title": "Gift subscription",
  "js.directives.checkout.bwAvailabilityCategories.oneOffButton.buttonObject.title": "Send %s",
  "js.directives.checkout.bwAvailabilityCategories.oneOffButton.buttonObject.title_long": "Send bouquet",
  "js.directives.checkout.bwAvailabilityCategories.subscriptionButton.buttonObject.subtitle": "%s <span class=\"cta-small cta-no-transform\">Per Bouquet</span>",
  "js.directives.checkout.bwAvailabilityCategories.subscriptionButton.buttonObject.subtitleAppend": "<span class=\"cta-small cta-no-transform\">(First Bouquet %s)</span>",
  "js.directives.checkout.bwAvailabilityCategories.subscriptionButton.buttonObject.title": "Subscribe from",
  "js.directives.checkout.bwBasket.removeFromBasket.confirmModal.cancelText": "Cancel",
  "js.directives.checkout.bwBasket.removeFromBasket.confirmModal.content": "Are you sure you want to remove this gift from your order?",
  "js.directives.checkout.bwBasket.removeFromBasket.confirmModal.successText": "Remove",
  "js.directives.checkout.bwBasket.removeFromBasket.confirmModal.title": "Remove gift",
  "js.directives.checkout.bwCarousel.successfulDiscountToast.amount": "%s discount applied",
  "js.directives.checkout.bwCarousel.successfulDiscountToast.percentage": "%s% discount applied",
  "js.directives.checkout.bwCheckout.confirmAddressModal.cancelText": "Edit address",
  "js.directives.checkout.bwCheckout.confirmAddressModal.content": "It appears that no building number has been included in the delivery address.<br /><br />%s<br />%s<br /><br />Please confirm this address is correct, or press edit to update the address details.",
  "js.directives.checkout.bwCheckout.confirmAddressModal.successText": "Confirm address",
  "js.directives.checkout.bwCheckout.confirmAddressModal.title": "Confirm address",
  "js.directives.checkout.bwDatePicker.showDateErrorModalForDate.confirmModal.cancelText": "Change gift",
  "js.directives.checkout.bwDatePicker.showDateErrorModalForDate.confirmModal.content": "%s is available from %s to %s. Please change the delivery date or select another gift",
  "js.directives.checkout.bwDatePicker.showDateErrorModalForDate.confirmModal.successText": "Change delivery date",
  "js.directives.checkout.bwDatePicker.showDateErrorModalForDate.confirmModal.title": "%s is not available",
  "js.directives.checkout.bwDeliveryDetails.confirmModal.cancelText": "Cancel",
  "js.directives.checkout.bwDeliveryDetails.confirmModal.content": "Prices, availability and shipping services will automatically be updated to reflect the local market.<br /><br />You may need to re-select your bouquet and shipping date when changing delivery country.",
  "js.directives.checkout.bwDeliveryDetails.confirmModal.successText": "Change",
  "js.directives.checkout.bwDeliveryDetails.confirmModal.title": "Change delivery country",
  "js.directives.checkout.bwDeliveryDetails.countryChanged": "Delivery country changed to %s. Please double check your item and delivery date before continuing.",
  "js.directives.checkout.bwDeliveryDetails.createAddressArray.add_new_address": "Add a new address",
  "js.directives.checkout.bwDeliveryDetails.deliveryCountry": "Delivery Country",
  "js.directives.checkout.bwDeliveryDetails.ship_to": "Ship to",
  "js.directives.checkout.bwDeliveryDetails.shippingName.free": " (FREE)",
  "js.directives.checkout.bwDeliveryDetails.shippingName.price_str": " (%s)",
  "js.directives.checkout.bwDeliveryDetailsAddressLookup.bwFooterText": "Click to enter address manually",
  "js.directives.checkout.bwDeliveryMessage.nextFree": "Next free delivery is %s",
  "js.directives.checkout.bwSummary.credit_message": "The special code you have used is not combinable with credit. Please remove the discount code if you want to apply your credit.",
  "js.directives.checkout.bwSummary.new_payment_method": "Or use a new payment method",
  "js.directives.download_app.invalid_fallback": "Number is not a valid phone number",
  "js.directives.nav.confirmModal.cancelText": "Cancel",
  "js.directives.nav.confirmModal.content": "Logging out will empty your basket. Any information you have entered relating to your current order will be lost.",
  "js.directives.nav.confirmModal.successText": "Empty basket and logout",
  "js.directives.nav.confirmModal.title": "Are you sure you want to log out?",
  "js.directives.nav.resetModal.successText": "Your password has been reset and you have been logged in",
  "js.directives.newsletterSignupDirective.enter_your_email": "Enter your email",
  "js.facebook.error.message.general-error": "We're really sorry but there was a problem with Facebook, please try again or get in touch.",
  "js.facebook.error.message.permissions-error": "We're really sorry but there was a problem with Facebook, please try again or get in touch.",
  "js.filters.freeCurrencyFilter.free": "FREE",
  "js.filters.freeCurrencyFilter.free.shipping": "FREE",
  "js.filters.orderSummaryFilter.delivery_from": "Delivery from",
  "js.filters.orderSummaryFilter.gift_subscription": "Gift subscription starting with %s",
  "js.filters_refresh.delivery.next-day": "Next-day",
  "js.filters_refresh.delivery.specific-date": "Specific date...",
  "js.filters_refresh.new-in.alt": "New in gifts",
  "js.filters_refresh.packaging.hand-tied-alt": "Comes pre-arranged",
  "js.filters_refresh.packaging.letterbox-alt": "Letterbox friendly",
  "js.filters_refresh.pet-friendly.alt": "Safe for pets if eaten",
  "js.generic.button.cancel": "Cancel",
  "js.generic.button.continue": "Continue",
  "js.generic.site.app_download.iOS": "https://itunes.apple.com/gb/app/bloom-wild/id936651689?utm_source=website&utm_medium=click&utm_campaign=app_download&utm_content=%s",
  "js.guard.has-logged-in.title": "Login or logout?",
  "js.guards.has-addon-modal.guard.cannot-meet-requirements": "Oops, our bad. This gift isn't available right now. You can check on it later or shop our other lovely gifts now.",
  "js.homectrl.additionalBodyCopy": "Our unique design means our flowers can be delivered straight through the letterbox by post, without the recipient needing to be in to receive them. All flowers are hand-packed with protective covers over the larger flowers to ensure they arrive in pristine condition, ready for styling into a beautiful bouquet or posy set.<br /><br />Shop now to find out why we're the UK's top rated online flower delivery company on independent review sites. You can also read about new product launches on our <a href=\"/content/flower-news\" title=\"What's New\">What's New</a> page.<br></br> We're also the UK's leading flower delivery app and we're one of a small number of apps to support Apple Pay when it first launched in the UK. We have been featured in multiple official App Store top-rated app categories, and constantly strive to improve our flower app to ensure customers have the best experience possible.",
  "js.homectrl.explainInStepsContent.item_1.blurb": "Our flowers are packed by hand, with protective netting on larger flowers to keep them in perfect condition on their journey.",
  "js.homectrl.explainInStepsContent.item_2.blurb": "We send our flowers in bud which means they last longer and can be delivered through the letterbox, so no need to wait in to receive them.",
  "js.homectrl.explainInStepsContent.item_3.blurb": "Each flower bouquet comes with care tips and arrangement instructions to keep the flowers blooming for 7 days or more.",
  "js.homectrl.getOurGiftCollections.buttonCopy": "View collection",
  "js.homectrl.getOurGiftCollections.item_1.headerCopy": "Gift subscriptions",
  "js.homectrl.getOurGiftCollections.item_1.link_url": "/send-flowers/type/subscription",
  "js.homectrl.getOurGiftCollections.item_1.paragraphCopy": "Frequent flowers to make you smile every month, or week if you prefer. You can pause or cancel our flower subscriptions at any time.",
  "js.homectrl.getOurGiftCollections.item_2.headerCopy": "Hand tied flowers",
  "js.homectrl.getOurGiftCollections.item_2.link_url": "/send-flowers/tagonly/hand-tied",
  "js.homectrl.getOurGiftCollections.item_2.paragraphCopy": "Need extra wow? Our hand-tied flower bouquets filled with luxury stems arrived pre-arranged in a presentation gift box.",
  "js.homectrl.getOurGiftCollections.item_3.headerCopy": "Business flowers",
  "js.homectrl.getOurGiftCollections.item_3.link_url": "/business",
  "js.homectrl.getOurGiftCollections.item_3.paragraphCopy": "Show your clients you care with our corporate flower gifts to say thank you or sorry. Find out more about our business flower gift service.",
  "js.homectrl.standardTextBlockCopy": "Bloom & Wild is redefining the <a href=\"%s\" title=\"Flower Delivery\">flower delivery</a> experience, making the giving and receiving of beautiful fresh flowers the joy it is meant to be. We are the original letterbox flower delivery company and now offer a number of new products including the Posy Party, premium letterbox flowers and hand-tied bouquets, in addition to our current letterbox flower range.<br /><br />We have free next day flower delivery across the UK via Royal Mail, guaranteed next day flower delivery via DPD courier as an option and 2 day delivery to Republic of Ireland. We also offer Bloom & Wild Now, a two-hour flower delivery service in London.",
  "js.meganav.base.breadcrumb": "Our full collection",
  "js.modals.info.content.setions.about": "About",
  "js.modals.info.content.setions.delivery": "Delivery",
  "js.modals.info.content.setions.reviews": "Reviews",
  "js.modals.warnDeliveryDate.confirm_title": "Confirm  delivery date",
  "js.modals.warnDeliveryDate.edit_order_button": "Edit Order",
  "js.modals.warnDeliveryDate.message": "The delivery date selected is %s <br /><br />If you'd like to view all available delivery dates, please click 'Edit Order' and scroll up to the Delivery Details section.",
  "js.modals.warnDeliveryDate.message_date_format": "dddd D MMMM",
  "js.modules.bwAccount.back_text.my_account": "My account",
  "js.modules.bwAccount.back_text.my_orders": "My orders",
  "js.navigation.items.addresses": "Addresses",
  "js.navigation.items.basket": "Basket",
  "js.navigation.items.birthday_gifts": "Birthday  gifts",
  "js.navigation.items.cards": "Cards",
  "js.navigation.items.credit": "Credit",
  "js.navigation.items.details": "Details",
  "js.navigation.items.email_preferences": "Email  settings",
  "js.navigation.items.fathers_day": "Father's  day",
  "js.navigation.items.gift_sets": "Gift  sets",
  "js.navigation.items.handtied": "Hand- tied",
  "js.navigation.items.letterbox": "Letterbox",
  "js.navigation.items.my_account": "Account",
  "js.navigation.items.my_occasions": "Occasions",
  "js.navigation.items.new_in": "New  in",
  "js.navigation.items.occasions": "Occasions",
  "js.navigation.items.orders": "Orders",
  "js.navigation.items.peonies": "Peonies",
  "js.navigation.items.subscription": "Subscriptions",
  "js.navigation.items.trees_wreaths": "Trees &  wreaths",
  "js.newsletter_signup.content.gdpr_disclaimer": "Enter your email address to receive special offers, exciting products and news from Bloom & Wild. You can view our #[a(href=\"/privacy-statement\",target=\"_blank\") privacy statement] and unsubscribe at any time.",
  "js.order-delivery.pickup-bouquet-banner-default-name": "A surprise",
  "js.order-form.modal.date-correct.confirm_title": "Confirm delivery date",
  "js.order-form.modal.date-correct.edit_order_button": "Edit order",
  "js.order-form.modal.date-correct.message": "The delivery date selected is %s <br /><br />If you'd like to view all available delivery dates, please click 'Edit Order' and scroll up to the Delivery Details section.",
  "js.order-form.modal.date-correct.message_date_format": "dddd D MMMM",
  "js.order-view-subscription.delivery.skip-delivery": "Skip delivery",
  "js.order-view-subscription.delivery.skip-line1": "Are you sure you want to skip this delivery?",
  "js.order-view-subscription.delivery.skip-line2": "You can’t undo this.",
  "js.order-view-subscription.delivery.skip-no": "No, keep",
  "js.order-view-subscription.delivery.skip-yes": "Yes, skip",
  "js.order.states.billed": "Billed",
  "js.order.states.confirmed": "Confirmed",
  "js.order.states.failed": "Failed",
  "js.order.states.failed_shipping": "Failed",
  "js.order.states.locked": "Locked",
  "js.order.states.paused": "Paused",
  "js.order.states.pending": "Pending",
  "js.order.states.printed": "Printed",
  "js.order.states.shipped": "Shipped",
  "js.page.contact_us.cancel_sub": "Can I cancel my subscription?",
  "js.page.contact_us.change_date": "Can I change the date of my order?",
  "js.page.contact_us.deliver_outside_uk": "Do you deliver outside the UK?",
  "js.page.contact_us.delivery_issue": "Royal Mail took my flowers back",
  "js.page.contact_us.forgot_discount": "I forgot to enter a discount code",
  "js.page.contact_us.gift_message": "Can I change the gift message?",
  "js.page.contact_us.havent_arrived": "My flowers haven’t arrived yet",
  "js.page.contact_us.how_do_i_care": "How do I care for my flowers?",
  "js.page.contact_us.looking_sad": "My flowers arrived looking sad",
  "js.page.contact_us.other": "Other",
  "js.page.contact_us.track_online": "How can I track my flowers?",
  "js.payment-result.error": "Oh no, that didn't work. Please contact our Customer Delight team.",
  "js.payment-result.success": "Your payment has now gone through. Woohoo!",
  "js.payment.bank-redirect.failed.message": "Please try again or pick a different way to pay",
  "js.payment.bank-redirect.failed.title": "We’re sorry - we had a problem putting through your payment.",
  "js.payment.discount-invalid.discountNotFound.message": "But tap here to apply 10% off",
  "js.payment.discount-invalid.discountNotFound.title": "Oh no! Code invalid",
  "js.payment.discount-invalid.discountNotFound2.message": "But tap here to apply 10% off",
  "js.payment.discount-invalid.discountNotFound2.title": "Code only applies to first order",
  "js.payment.discount-invalid.discountNotFound3.message": "But tap here to apply 10% off",
  "js.payment.discount-invalid.discountNotFound3.title": "Oh no! Code expired",
  "js.payment.discount-invalid.discountNotFound4.message": "-",
  "js.payment.discount-invalid.wrongPlatform.title": "Oh no! This discount code is only available for our iPhone and Android apps.",
  "js.payment.error.paypal.account_tokenization_failed.message": "Please try again or pick a different way to pay",
  "js.payment.error.paypal.account_tokenization_failed.title": "We’re sorry - we had a problem putting through your payment.",
  "js.payment.error.paypal.flow_failed.message": "Please try again or pick a different way to pay",
  "js.payment.error.paypal.flow_failed.title": "We’re sorry - we had a problem putting through your payment.",
  "js.payment.error.paypal.popup_closed.message": "Please try again or pick a different way to pay",
  "js.payment.error.paypal.popup_closed.title": "Oops! The pop-up PayPal window was closed too soon so we couldn’t take your payment.",
  "js.payment.error.paypal.tokenisation_error.title": "PayPal could not be opened. Please try again or choose a different payment method.",
  "js.payment.error.stripe.approve_with_id.message": "You can try again. But if this keeps happening, get in touch with your bank.",
  "js.payment.error.stripe.approve_with_id.title": "We’re sorry - your payment didn’t go through.",
  "js.payment.error.stripe.authentication_required.message": "You can try again. But if this keeps happening, get in touch with your bank.",
  "js.payment.error.stripe.authentication_required.title": "We’re sorry - your payment didn’t go through.",
  "js.payment.error.stripe.card-declined": "We’re sorry - your card was declined",
  "js.payment.error.stripe.card_not_supported.title": "We’re sorry - you can’t use this card for your order.",
  "js.payment.error.stripe.currency_not_supported.title": "It looks like your card won’t let you pay in different currencies.",
  "js.payment.error.stripe.duplicate_transaction.message": "And we don’t want to charge you twice. Check your recent orders in My Account. If it’s not there, get in touch with,Customer Delight.",
  "js.payment.error.stripe.duplicate_transaction.title": "Oops - look like you’ve already made this order!",
  "js.payment.error.stripe.expired_card.title": "Oops! The card you’re trying to use has expired. So you’ll need to pick a different way to pay",
  "js.payment.error.stripe.generic.bank-touch": "If this keeps happening, try using a different card. And get in touch with your bank for help.",
  "js.payment.error.stripe.generic.declined": "We’re sorry - your card was declined",
  "js.payment.error.stripe.generic.different.card": "So you’ll need to use a different card. If you think this is wrong, get in touch with your bank.",
  "js.payment.error.stripe.generic.incomplete-number": "This card number doesn’t look right to us...",
  "js.payment.error.stripe.generic.invalid-number": "This card number doesn’t look right to us...",
  "js.payment.error.stripe.generic.message": "We had a problem processing your card, please try again in a minute and get in touch if you continue to have a problem.",
  "js.payment.error.stripe.generic.problem": "We’re sorry - we had a problem putting through your payment.",
  "js.payment.error.stripe.generic.title": "Problem with your card",
  "js.payment.error.stripe.generic.try-again": "Check you’ve put in the right details and try again.",
  "js.payment.error.stripe.incomplete_cvc.title": "This CVC doesn’t look right to us...",
  "js.payment.error.stripe.incorrect_cvc.title": "This CVC doesn’t look right to us...",
  "js.payment.error.stripe.incorrect_pin.title": "We think you’ve used the wrong PIN with your card reader.",
  "js.payment.error.stripe.incorrect_zip.title": "Your billing postcode doesn’t match the card you’re trying to use.",
  "js.payment.error.stripe.invalid_cvc.title": "This CVC doesn’t look right to us...",
  "js.payment.error.stripe.invalid_expiry_month.title": "The expiry date doesn’t match the bank card you’re trying to use.",
  "js.payment.error.stripe.invalid_expiry_year.title": "The expiry date doesn’t match the bank card you’re trying to use.",
  "js.payment.error.stripe.invalid_pin.title": "We think you’ve used the wrong PIN with your card reader.",
  "js.payment.error.stripe.offline_pin_required.message": "You’ll need to use the reader that came with your bank card to finish paying. Or you can pick a different card.",
  "js.payment.error.stripe.offline_pin_required.title": "Just so we can check it’s you..",
  "js.payment.error.stripe.online_or_offline_pin_required.message": "You’ll need to use the reader that came with your bank card to finish paying. Or you can pick a different card.",
  "js.payment.error.stripe.online_or_offline_pin_required.title": "Just so we can check it’s you..",
  "js.payment.error.stripe.pin_try_exceeded.title": "Looks like you’ve put the wrong PIN in too many times...",
  "js.payment.error.stripe.processing_error.message": "If this keeps happening, try using a different card. Or get in touch with Customer Delight.",
  "js.payment.error.stripe.restricted_card.message": "So you’ll need to use a different card. If you need help, get in touch with your bank.",
  "js.payment.error.stripe.transaction_not_allowed.title": "We’re sorry - your card was declined.",
  "js.payment.option-invalid": "Please select a payment option",
  "js.payment.tandc.accept": "Remember to read and accept our terms and conditions before you make your payment!",
  "js.pipe.12months": "12 Months",
  "js.pipe.3months": "3 Months",
  "js.pipe.6months": "6 Months",
  "js.pipe.day-of-week.friday": "Friday",
  "js.pipe.day-of-week.monday": "Monday",
  "js.pipe.day-of-week.saturday": "Saturday",
  "js.pipe.day-of-week.sunday": "Sunday",
  "js.pipe.day-of-week.thursday": "Thursday",
  "js.pipe.day-of-week.tuesday": "Tuesday",
  "js.pipe.day-of-week.wednesday": "Wednesday",
  "js.pipe.moments-ago": "Moments ago",
  "js.pipe.month": "Month",
  "js.pipe.oneoff": "One off",
  "js.pipe.ongoing": "Ongoing",
  "js.pipe.possessive.apostrophe": "%s's %s",
  "js.pipe.possessive.apostrophe_s": "%s' %s",
  "js.pipe.possessive.fr_consonant": "%s de %s",
  "js.pipe.possessive.fr_vowel": "%s d'%s",
  "js.pipe.relative-date.today": "today",
  "js.pipe.relative-date.tomorrow": "tomorrow",
  "js.pipe.two-weeks": "Two Weeks",
  "js.pipe.week": "Week",
  "js.rewards.marketing_opt_in_1": "Keep me updated via email with Bloom & Wild Rewards, my birthday treat and VIP perks, as well as special offers and news about our products.",
  "js.rewards.marketing_opt_in_2": "You can view our #[a.text-link--paragraph(href=\"/privacy-statement\" target=\"_blank\") privacy statement here] (to learn how we handle your personal data) and unsubscribe at any time.",
  "js.routing.add_occasion": "add-occasion",
  "js.routing.checkout.confirmation": "confirmation",
  "js.routing.checkout.gift_options": "gift-options",
  "js.routing.checkout.payment": "payment",
  "js.routing.checkout_base": "send-flowers",
  "js.routing.checkout_route": "checkout",
  "js.routing.checkout_send": "send",
  "js.routing.content.help": "help",
  "js.routing.faqs": "https://www.bloomandwild.com/help",
  "js.routing.gift_vouchers": "gift-cards",
  "js.routing.help-with-delivery": "help-with-delivery",
  "js.routing.help-with-quality": "report-quality-issue",
  "js.routing.rewards.t_and_c": "bw-rewards-terms",
  "js.routing.rewards_route": "rewards",
  "js.routing.share": "share",
  "js.routing.subs-grid.base-url": "flower-subscription",
  "js.routing.subs-share": "sub-share",
  "js.seo.meta.description": "Flower delivery from Bloom & Wild, delight someone with a beautiful bouquet posted through their letterbox. Carefully selected flowers & gifts with free next day delivery, be there from anywhere with Bloom & Wild. Shop now.",
  "js.seo.meta.keywords": "flower delivery, letterbox flowers, flowers by post, bloom and wild, bloom & wild",
  "js.seo.meta.title": "Bloom & Wild Flower Delivery | Flowers & Gifts",
  "js.seo.opengraph.description": "Flower delivery from Bloom & Wild, delight someone with a beautiful bouquet posted through their letterbox. Carefully selected flowers & gifts with free next day delivery, be there from anywhere with Bloom & Wild. Shop now.",
  "js.seo.opengraph.image": "/images/app/share-facebook.png",
  "js.seo.opengraph.site": "Bloom & Wild",
  "js.seo.opengraph.title": "Bloom & Wild Flower Delivery",
  "js.seo.opengraph.type": "website",
  "js.seo.opengraph.url": "https://www.bloomandwild.com/",
  "js.seo.schema.image": "/images/app/share-facebook.png",
  "js.seo.schema.name": "Bloom & Wild Flower Delivery",
  "js.seo.twitter.card": "summary_large_image",
  "js.seo.twitter.description": "Flower delivery from Bloom & Wild, delight someone with a beautiful bouquet posted through their letterbox. Carefully selected flowers & gifts with free next day delivery, be there from anywhere with Bloom & Wild. Shop now.",
  "js.seo.twitter.image": "images/app/share-twitter.png",
  "js.seo.twitter.site": "@bloomandwild",
  "js.seo.twitter.title": "Bloom & Wild Flower Delivery",
  "js.service.backend.network": "Sorry, something went wrong. Please contact our Customer Delight team",
  "js.service.delivery.check.error": "Sorry, you can't make that change right now. Please email our Customer Delight Team",
  "js.service.file-upload.error.message": "File unable to be uploaded",
  "js.service.gifting-options.card-collections.all-occasions-card.collection-name": "Other occasions",
  "js.service.gifting-options.card-collections.all-occasions-card.supporting-copy": "For the days when they need support. Or just because. Scroll through all our designs to pick your fave.",
  "js.service.gifting-options.card-collections.basic-collection-card.collection-name": "Basic collection",
  "js.service.gifting-options.card-collections.basic-collection-card.supporting-copy": "Printed in A6 format, popped into the box with your gift",
  "js.service.gifting-options.card-collections.birthday-card.collection-name": "Birthday",
  "js.service.gifting-options.card-collections.birthday-card.supporting-copy": "Because it wouldn't be a birthday without a card. Pick your fave design, and add your own celebratory note.",
  "js.service.gifting-options.card-collections.celebration-card.collection-name": "Birthday & celebration",
  "js.service.gifting-options.card-collections.celebration-card.supporting-copy": "Birthday? Big move? Anniversary? These mood-boosting designs are the perfect ‘whoop whoop!’",
  "js.service.gifting-options.card-collections.charity-card.collection-name": "Charity",
  "js.service.gifting-options.card-collections.charity-card.supporting-copy": "When you pick this card, you’re helping to support our four amazing charity partners; Carers Trust, Blueprint for All, LGBT Foundation and Rewilding Britain. For each one sold, £1 will be donated to these fantastic causes.",
  "js.service.gifting-options.card-collections.easter-card.collection-name": "Easter",
  "js.service.gifting-options.card-collections.easter-card.supporting-copy": "A sweet note in one of these cards is the next best thing to being there this bank holiday weekend.",
  "js.service.gifting-options.card-collections.fathers-day-card.collection-name": "Father's Day",
  "js.service.gifting-options.card-collections.fathers-day-card.supporting-copy": "Dad. Uncle. Big Brother. Reach out to the Father figure in your life with your favourite design and a thoughtful message.",
  "js.service.gifting-options.card-collections.limited-edition-card.collection-name": "Limited edition",
  "js.service.gifting-options.card-collections.limited-edition-card.supporting-copy": "Artist collabs. One-off Liberty prints. And extra-special charity cards that donate £1 to Tommy's.",
  "js.service.gifting-options.card-collections.mothers-day-card.collection-name": "Mother's Day",
  "js.service.gifting-options.card-collections.mothers-day-card.supporting-copy": "Mum. Granny. Your fave aunt. Reach out to the mother figure in your life with beautifully thoughtful designs.",
  "js.service.gifting-options.card-collections.seasonal-collection-card.collection-name": "Seasonal Collection",
  "js.service.gifting-options.card-collections.seasonal-collection-card.supporting-copy": "Celebrate the season with our limited edition designs.",
  "js.service.gifting-options.card-collections.standard-collection-card.collection-name": "Standard Collection",
  "js.service.gifting-options.card-collections.standard-collection-card.supporting-copy": "Make your surprise even more special with our cards.",
  "js.service.initial-load.untrustworthy-expired-token": "Oh no! You clicked on a link in an old email, and the token is invalid. Please try log in again",
  "js.service.klarna.fail.message": "Klarna authorisation failed",
  "js.service.order-edit-section.section.delivery.name": "Delivery details",
  "js.service.order-edit-section.section.edit.name": "Editing order",
  "js.service.order-edit-section.section.order.name": "Order details",
  "js.service.order-edit-section.section.review.name": "Review changes",
  "js.service.order-express-section.section.delivery.name": "Delivery details",
  "js.service.order-express-section.section.loggedin.name": "User details",
  "js.service.order-express-section.section.review.name": "Review payment",
  "js.service.order-express-section.section.user.name": "User details",
  "js.service.order-new-section.section.delivery.name": "Delivery details",
  "js.service.order-new-section.section.loggedin.name": "Your details",
  "js.service.order-new-section.section.orderdetails.name": "Order details",
  "js.service.order-new-section.section.review.name": "Review payment",
  "js.service.order-new-section.section.userdetails.name": "Your details",
  "js.services.BWMessagingService.messages.item_1": "Order before 5.30pm for free next day delivery",
  "js.services.BWMessagingService.messages.item_2": "Order before 12.30pm sunday for monday delivery",
  "js.services.BWMessagingService.messages.item_3": "Order before 12.30pm for next day delivery",
  "js.services.BWMessagingService.messages.item_4": "Free delivery for %s",
  "js.services.bwProductSortService.by_rating": "Reviews",
  "js.services.bwProductSortService.price_high_low": "Price high to low",
  "js.services.bwProductSortService.price_low_high": "Price low to high",
  "js.services.bwProductSortService.recommended": "Recommended",
  "js.social.instagram.url": "https://instagram.com/bloomandwild",
  "js.stripe.messages.payment_intent.cancelled": "Oops! This page was inactive for too long, so we were unable to verify your payment. Try again to carry on with your order.",
  "js.toastr.save-more-and-save.alt-heading": "We've put that in your basket.",
  "js.toastr.save-more-and-save.description": "Add another gift and get %s off. Our treat.",
  "js.toastr.save-more-and-save.heading": "Discount applied",
  "js.zopim.concierge.name": "Bloom & Wild",
  "js.zopim.concierge.title": "Can we help?",
  "js.zopim.greetings.offline": "Offline",
  "js.zopim.greetings.online": "Can we help?",
  "js.zopim.prechat.greeting": "If you like, please let us know a few details about you. Or to chat straight away click 'start chatting'",
  "js.zopim.window.title": "Customer Delight"
};