import { Component, Input, OnInit } from '@angular/core';
import { ProductGridService } from 'Project/checkout/services/product-grid.service';
import { Product } from 'Project/checkout/services/product.service';
import { PurchaseService } from 'Project/checkout/services/purchase.service';
import { GridProduct, ProductCardType } from 'Project/shared/classes/grid-product';
import { ConfigService } from 'Project/shared/services/config.service';
import { CountryService } from 'Project/shared/services/country.service';
import { StateService } from 'Project/shared/services/state.service';

@Component({
  selector: 'bw-featured-products-carousel',
  templateUrl: './featured-products-carousel.component.html',
  styleUrls: ['./featured-products-carousel.component.scss']
})
export class FeaturedProductsCarouselComponent implements OnInit {

  @Input() campaignname: string = '';
  @Input() tag: string; // tag used to find the right skus

  products: GridProduct[];
  productCardType = ProductCardType;
  isLoading: boolean = true;

  constructor(
    private countryService: CountryService,
    private productGridService: ProductGridService,
    private configService: ConfigService,
    private stateService: StateService,
    private purchaseService: PurchaseService) { }

  /**
   *
   * @returns
   */
  ngOnInit(): Promise<GridProduct[]> {
    const serverTime = this.configService.getConfig().serverTime;
    const listType = {
      type: 'tagOnly',
      value: this.tag
    };
    const discount = this.purchaseService.getPurchase()?.discount;

    return this.productGridService.getProducts({
      serverTime,
      listType,
      site: this.countryService.forSite,
      shippingTo: this.countryService.forShipping,
      orderIndex: 0,
      discount: discount
    }).then(res => {

      this.products = res.products.length > 6 ? res.products.slice(0, 6) : res.products;
      this.isLoading = false;

      return this.products;

    });
  };

  /**
   *
   * @param product
   */
  productSelected(product: GridProduct): void {
    const params = {
      slug: product.slug,
      skuId: product.id
    };

    this.stateService.go('checkout.productpage', params);
  }

}
