import * as clone from 'clone';
import { Address } from 'Shared/classes/address';
import * as dayjs from 'dayjs';
import { ShippingOption } from 'Shared/classes/shipping-option';
import { GiftCard } from 'Shared/classes/gift-card';
import { Product } from 'Shared/classes/product';
import { CurrencyCode, Price } from 'Shared/classes/price';
import { APISerialisedJSONResponse } from 'Shared/types/backend-api/api';
import { AddonType } from './addon';

// TODO: update to use same typing techqiue similar to addresses

// Number of days a delivery is "shipped", but we count it as delivered
// these are usually "lost" deliveries,
// or deliveries that reached the recipient but the carrier didn't mark as 'delivered'
// There are 10,000s of these in the backend Database, in effect, "stuck" in a shipped state
const DAYS_SINCE_SHIPPED_AS_DELIVERED = 7;

export class Delivery {
  public id: number;
  public address: Address;
  public giftCard: GiftCard;
  public state: string;
  public editable: boolean;
  public backendState: string;
  public date: dayjs.Dayjs;
  public note: string;

  public shippingOption: ShippingOption;

  public billedAt: dayjs.Dayjs;
  public createdAt: dayjs.Dayjs;
  public orderId: number;
  public product: Product;
  public trackingDetails: TrackingDetails;
  public RecentlyUpdated?: {
    date: boolean;
    giftCard: boolean;
    address: boolean;
    note: boolean;
    bouquet: boolean;
  };
  public userIsRegistered: boolean;
  public price: Price;
  public diversion?: boolean;

  static setStringToDate(date: string): dayjs.Dayjs {
    return dayjs(date);
  }
  /**
   * Clone the occasion
   */
  clone(): Delivery {
    return clone(this);
  }

  /**
   * Set the state
   * @param type
   */
  setState(type: string): void {
    const t = type.toLowerCase();
    if (['locked', 'failed', 'failed_shipping', 'printed', 'generating_label'].indexOf(t) > -1) {
      this.state = 'confirmed';
    } else if (['shipped'].indexOf(t) > -1) {
      this.state = 'shipped';
    } else if (t === 'paused') {
      this.state = 'paused';
    } else {
      this.state = type;
    }
  }

  /**
   * Is Editable
   */
  isEditableState(): boolean {
    return ['pending', 'billed', 'paused'].indexOf(this.state) > -1;
  }

  /**
   * Is Editable
   */
  isEditable(): boolean {
    return this.editable;
  }

  /**
   * Is scheduledDelivery
   */
  isScheduleDelivery(): boolean {
    return this.state === 'pending' && !this.editable;
  }

  /**
   * isCancellable
   */
  isCancellable(): boolean {
    const nonCancellableStates = ['locked', 'printed', 'shipped', 'delivered', 'cancelled', 'confirmed'];

    return nonCancellableStates.indexOf(this.state) < 0 && !this.date.isBefore(dayjs());
  }

  /**
   * Has the delviery been completed?
   * @param serverTime
   */
  isComplete(serverTime: dayjs.Dayjs): boolean {
    // prettier-ignore
    return (
      this.backendState === 'delivered' ||
      this.backendState === 'cancelled' ||
      this.backendState === 'resent' ||
      (this.backendState === 'shipped' && serverTime.diff(this.date, 'day') >= DAYS_SINCE_SHIPPED_AS_DELIVERED)
    );
  }

  /**
   * Set the date
   * @param date DayJs compatible object
   */
  setDate(date: any): void {
    this.date = dayjs(date);
  }

  /**
   * Get progress of delivery for progress bar
   * @returns number
   */
  getProgress(): number {
    if (!this.state) {
      return undefined;
    }

    if (this.state === 'billed' || this.state === 'pending') {
      return 1;
    }
    if (this.state === 'locked' || this.state === 'printed') {
      return 2;
    }
    if (this.state === 'shipped') {
      return 3;
    }
    if (this.state === 'delivered') {
      return 4;
    }
  }

  /**
   * Delivery is processing check
   * @returns boolean
   */
  isProcessing(): boolean {
    const processingStates = ['processing', 'generating_label', 'locked', 'printed', 'scanned', 'labelled'];
    return processingStates.indexOf(this.state) > -1;
  }

  /**
   * Check if delivery is in paused or failed states
   * @returns boolean
   */
  isPausedOrFailed(): boolean {
    return ['paused', 'failed', 'failed_shipping'].indexOf(this.state) > -1;
  }

  /**
   * Check if recent - +/- 2 weeks
   * TODO: move this to BE
   * @returns boolean
   */
  isRecent(): boolean {
    const past = dayjs().subtract(2, 'week');
    const future = dayjs().add(2, 'week');

    return this.date.isAfter(past, 'day') && this.date.isBefore(future, 'day');
  }
}

export class TrackingDetails {
  public trackedNumber: string;
  public trackingUrl: string;
  public deliveredAt: dayjs.Dayjs;
  public deliveryMessage: string;
  public deliveryOn: dayjs.Dayjs;
  public updatedAt: dayjs.Dayjs;
  public lockedAt: dayjs.Dayjs;
  public shippedAt: dayjs.Dayjs;
  public history: TrackingHistory[];
  public compensable: boolean;
  public compensationPrice: Price;
  // TODO: remove once delivery tracking updated to use compensationPrice
  public compensationCurrency: string;
  public compensationAmount: number;
  public refundable: boolean;
  public resendable: boolean;
  public status: string;
  public statusDescription: string;
  public estimatedDeliveryDate: dayjs.Dayjs;
}

export class TrackingHistory {
  location: string;
  locationType: string;
  createdAt: dayjs.Dayjs;
}

// Used within delivery tracking and hc delivery self serve modal
export interface DeliveryHistory {
  day: dayjs.Dayjs;
  values: StateTime[];
}

export interface StateTime {
  state: string;
  time: dayjs.Dayjs;
}

export type DeliverySelfServeOrigin = 'orderDeliveryResolution' | 'helpCentreResolution';

export interface ApiRefundResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      amount_currency: CurrencyCode;
      amount_pennies: number;
      amount: number;
      credits_currency: string;
      credits_pennies: number;
      credits: number;
    };
  };
}

export interface ApiProductResponse {
  id: number;
  image_url: string;
  is_selected: boolean;
  name: string;
  media: any[];
  imageUrls: any[];
  slug: string;
  bundle_only: boolean;
  single_only: boolean;
  subscription_only: boolean;
  lily_free: boolean;
  eighteen_plus: boolean;
  collection_name: string;
  collection_id: number;
  is_pre_order: boolean;
  description: string;
  long_description: string;
  email_description: string;
}

export interface ApiDeliveryResponse {
  id: string;
  diversion?: DeliveryDiversion[];
  type: string;
  attributes: {
    address_attributes: {
      shipping_country_id: number;
      city: string;
      company: string;
      id: string;
      line1: string;
      line2: string;
      name: string;
      phone: string;
      postcode: string;
      phone_number: string;
      postcode_anywhere_uid: string;
    };
    billed_at: string;
    created_at: string;
    delivered_at: string;
    delivery_message: string;
    delivery_on: string;
    gift_card_image_id: number;
    is_editable: boolean;
    locked_at: string;
    order_id: number;
    shipped_at: string;
    shipping_attributes: {
      carrier_id: string;
      carrier_name: string;
      delivery_pill: string;
      description: string;
      name: string;
    };
    shipping_note: string;
    sku_attributes: ApiProductResponse;
    state: string;
    tracked_number: string;
    tracking_attributes: {
      compensable: boolean;
      compensation_amount_currency: string;
      compensation_amount_pennies: number;
      description: string;
      estimated_delivery_date: string;
      refundable: boolean;
      resendable: boolean;
      status: string;
    };
    tracking_url: string;
    updated_at: string;
  };
  meta: {
    user: {
      is_registered: boolean;
    };
  };
}

export interface GiftCardResponse {
  attributes: {
    tags: string[];
    url: string;
  };
  id: string;
  type: AddonType;
}

export interface ApiDeliveryResponseV1 {
  id: string;
  diversion?: DeliveryDiversion[];
  type: string;
  shipping_address: APISerialisedJSONResponse<'/v1/addresses/*'>;
  shipping_option: any;
  gift_card_image_url: string;
  address_attributes?: {
    shipping_country_id: number;
    city: string;
    company: string;
    id: string;
    line1: string;
    line2: string;
    name: string;
    phone: string;
    postcode: string;
    phone_number: string;
    postcode_anywhere_uid: string;
  };
  billed_at: string;
  created_at: string;
  delivered_at: string;
  delivery_message: string;
  delivery_on: string;
  gift_card_image_id: number;
  is_editable: boolean;
  locked_at: string;
  order_id: number;
  shipped_at: string;
  shipping_attributes: {
    carrier_id: string;
    carrier_name: string;
    delivery_pill: string;
    description: string;
    name: string;
  };
  shipping_note: string;
  sku_attributes?: ApiProductResponse;
  currency: CurrencyCode;
  total_cost_pre_discount_pennies: number;
  total_cost_pennies: number;
  state: string;
  tracked_number: string;
  tracking_attributes: {
    compensable: boolean;
    compensation_amount_currency: string;
    compensation_amount_pennies: number;
    description: string;
    estimated_delivery_date: string;
    refundable: boolean;
    resendable: boolean;
    status: string;
  };
  tracking_url: string;
  updated_at: string;
  meta: {
    user: {
      is_registered: boolean;
    };
  };
}

export interface DeliveryToPayload {
  id: number;
  delivery: {
    update_method: string;
    delivery_on: string;
    gift_card_image_id: number;
    gift_card_image_url: string;
    delivery_message: string;
    shipping_note: string;
    shipping_option_id: number;
    sku_id: number;
    shipping_address_id: number;
  };
}

export interface DeliveryDiversion {
  id: number;
  new_delivery_date: string;
  old_delivery_date: string;
}
export interface ApiMyDeliveriesResponse {
  data: ApiDeliveryResponse[] | ApiDeliveryResponse;
}

export interface ApiFetchDeliveriesResponse {
  deliveries: ApiDeliveryResponseV1[];
}
/** =====================================
 * Quality Objects for issues and stems
 ======================================*/
export class QualityItem {
  id: string;
  displayName: string;
  url?: string;

  constructor(id?: string, displayName?: string, url?: string) {
    this.id = id;
    this.displayName = displayName;
    this.url = url;
  }
}

export interface ApiQualityItemResponse {
  id: string;
  type: string;
  attributes: {
    display_name: string;
    url?: string;
    weight: number;
  };
}

export interface ApiQualityResponse {
  data: ApiQualityItemResponse[];
}

/**
 * Help center quality modal is currently only for b&w
 * TODO: Add DKK to compensationPennies object
 */
export class QualityResolution {
  id: string;
  isCompensable: boolean;
  compensationPennies: {
    GBP: Price;
    EUR: Price;
  };
  refundPercentage: number;
  isRefundable: boolean;
  isResendable: boolean;
  resolutionMessage: string;

  // Check if quality issue is resolvable
  isResolvable(): boolean {
    return this.isCompensable || this.isRefundable || this.isResendable;
  }
}

export interface ApiQualityResolutionResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      compensable: boolean;
      compensation_eur_pennies: number;
      compensation_gbp_pennies: number;
      refund_percentage: number;
      refundable: boolean;
      resendable: boolean;
      resolution_message: string;
    };
  };
}

export class QualityIssue {
  id: number;
  deliveryId: number;
  refunded: boolean;
  resent: boolean;
}

export interface ReportedQualityIssue {
  resolution: QualityResolution;
  issue: QualityIssue;
}

export interface ApiQualityIssueResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      delivery_id: number;
      refunded: boolean | null;
      resent: boolean | null;
    };
  };
  token: string | null;
}

export interface ApiCurrentQualityIssue {
  data: {
    id: string;
    type: string;
    attributes: {
      refunded: boolean;
      resent: boolean;
      delivery_id: number;
      quality_issue_option_id: number;
      quality_issue_option: {
        compensable: boolean;
        compensation_eur_pennies: number;
        compensation_gbp_pennies: number;
        refund_percentage: number;
        refundable: boolean;
        resendable: boolean;
        resolution_message: string;
      };
    };
  };
}
