
<ui-box class="whisper-usps" box_space="none" box_fill_mode="fill" box_space_bottom="near" box_space_top="near">
  <ui-stack *ngIf="(viewportSizeIs$ | async)?.desktop || (viewportSizeIs$ | async)?.largeTablet" stack_align="center" stack_direction="x" stack_distribute="center" stack_gap="expanded">
    <ui-stack class="whisper-usps__inner-stack" *ngFor="let usp of getUsps()" stack_align="center" stack_direction="x" stack_gap="tight">
      <ui-icon *ngIf="isIcon(usp)" [icon_name]="translatedIconName(usp)"></ui-icon><img class="whisper-usps__icon-image" *ngIf="isImage(usp)" [src]="translatedImageSrc(usp)" aria-hidden="true"><span class="whisper-usps__content">{{translatedContent(usp)}}</span>
    </ui-stack>
  </ui-stack>
  <ui-box *ngIf="(viewportSizeIs$ | async)?.mobile || (viewportSizeIs$ | async)?.mediumTablet" box_space="none">
    <ui-stack class="whisper-usps__carousel-container" stack_align="center" stack_direction="x" stack_distribute="center" stack_gap="none">
      <ui-carousel class="whisper-usps__carousel" [carousel_slides_per_view_desktop]="1" [carousel_slides_per_view_laptop]="1" [carousel_slides_per_view_tablet]="1" [carousel_slides_per_view_mobile]="1" carousel_gap="tight" [carousel_overflow]="true" carousel_pagination="none" [rewind]="true" [carousel_rows_per_row_mobile]="1" [center_insufficient_slides]="true">
        <swiper-slide *ngFor="let usp of getUsps()" data-swiper-autoplay="6000">
          <ui-stack class="whisper-usps__inner-stack--mobile" stack_align="center" stack_direction="x" stack_gap="tight" stack_distribute="center">
            <ui-icon *ngIf="isIcon(usp)" [icon_name]="translatedIconName(usp)"></ui-icon><img class="whisper-usps__icon-image" *ngIf="isImage(usp)" [src]="translatedImageSrc(usp)" aria-hidden="true"><span class="whisper-usps__content">{{translatedContent(usp)}}</span>
          </ui-stack>
        </swiper-slide>
      </ui-carousel>
    </ui-stack>
    <button class="whisper-usps__button whisper-usps__button--left" (click)="goToPrevSlide()">
      <ui-icon icon_name="chevron-left"></ui-icon>
    </button>
    <button class="whisper-usps__button whisper-usps__button--right" (click)="goToNextSlide()">
      <ui-icon icon_name="chevron-right"></ui-icon>
    </button>
  </ui-box>
</ui-box>