
<div class="staging-helper" [ngClass]="{'collapsed': collapsed, 'pbs-active': currentAPIUrl !== this.defaultBackendURL}">
  <!-- Segments-->
  <div class="helper helper--segments" *ngIf="showSegments">
    <ul class="helper__list">
      <li class="list__item item--heading">Content Segments:<span *ngFor="let segment of currentSegments">{{segment.id}} ,</span></li>
      <li *ngFor="let segment of knownSegments">
        <div class="list__item"><span>{{segment.name}} - ({{segment.id}})</span>
          <div class="item__aside"><a *ngIf="!segment.isActive" (click)="enterSegment(segment)">OUT</a><a class="is-selected" *ngIf="segment.isActive" (click)="exitSegment(segment)">IN</a></div>
        </div>
      </li>
    </ul>
  </div>
  <!-- Experiments-->
  <div class="helper helper--experiments" *ngIf="showExperiments">
    <ul class="helper__list">
      <li class="list__item item--heading">Known Experiments:</li>
      <li *ngFor="let experiment of knownExperiments">
        <div class="list__item" *ngIf="!experiment.id"><span>{{experiment.name}}</span>
          <div class="item__aside"><a *ngFor="let variant of experiment.variants" [ngClass]="{'is-selected': experiment.variant === variant}" (click)="refreshWithExperiment(experiment.name,variant)">{{variant}}</a></div>
        </div>
      </li>
      <li class="list__item item--heading">Running Experiments:</li>
      <li *ngFor="let experiment of knownExperiments">
        <div class="list__item" *ngIf="experiment.id"><span>{{experiment.name}}</span>
          <div class="item__aside"><a *ngFor="let variant of experiment.variants" [ngClass]="{'is-selected': experiment.variant === variant}" (click)="refreshWithExperiment(experiment.name,variant)">{{variant}}</a></div>
        </div>
      </li>
    </ul>
  </div>
  <!-- Experiments-->
  <div class="helper helper--api-url" *ngIf="showPBSDetails">
    <form [formGroup]="form">
      <ui-text-input formControlName="newApiURL" label="Current API URL" type="text"></ui-text-input>
      <ui-stack>
        <ui-stack stack_direction="x" stack_gap="tight">
          <ui-button (click)="setApiURL()">Set</ui-button>
          <ui-button (click)="clearAPIURL()" [title]="this.defaultBackendURL">Reset to Default</ui-button>
        </ui-stack>
        <p>Default: {{ this.defaultBackendURL }}</p>
      </ui-stack>
    </form>
  </div>
  <!-- Controller-->
  <div class="helper helper--tools">
    <div class="helper__icons"><a class="helper__icon icon--segments" (click)="toggleSegmentsList()"><span>&#9685;</span>Segments
        <div class="icon__counter"><span *ngFor="let segment of currentSegments">{{segment.id}} ,</span></div></a><a class="helper__icon" (click)="showExperiments = !showExperiments"><span>&#x272D;</span>Experiments
        <div class="icon__counter">{{totalExperimentsIn}}</div></a><a class="helper__icon" (mousedown)="editInContentful($event)"><span>&#x270E;</span>Edit</a><a class="helper__icon icon--api-url" (click)="showPBSDetails = !showPBSDetails"><span>&#9729;</span>API URL
        <div class="icon__counter" *ngIf="currentAPIUrl">{{currentAPIUrl ? 1 : 0}}</div></a><a class="helper__icon" (click)="refresh()"><span>&#x267b;</span>Refresh</a></div><a class="helper__toggle" (click)="toggleCollapsed()" title="Debug Tools"><span>🔧</span></a>
  </div>
</div>