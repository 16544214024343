import * as dayjs from 'dayjs';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ModalService } from 'Shared/services/modal.service';
import { OccasionReminder } from 'Shared/classes/occasion-reminder';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { BwButtonSize, BwButtonStyle } from 'Shared/components/button/button';

@Component({
  selector: 'bw-occasion-launch',
  templateUrl: './occasion-launch.component.html',
  styleUrls: ['./occasion-launch.component.scss']
})
export class OccasionLaunchComponent {
  // Should the button be displayed or not.
  // As button is not required for new version of occasions banner
  @Input() displayButton: boolean = true;
  @Input() occasionrebrand: boolean;
  @Input() date: dayjs.Dayjs | string;
  @Input() recipient: string;
  @Input() typeId: number;
  @Input() buttonClasses: string = 'primary'; // legacy
  @Input() buttonStyle: BwButtonStyle = 'secondary';
  @Input() size: BwButtonSize = 'medium';
  @Input() noIcon: boolean;
  @Input() origin: string;
  @Input() purchaseGuestToken: string;
  @Input() isBrandRefresh: boolean = false;

  @Output() success: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  preferredOccasion: OccasionReminder = new OccasionReminder();
  loading: boolean;

  constructor(private modalService: ModalService, private analyticsService: AnalyticsService) {}

  /**
   * Open occasion modal
   * @returns {Promise<void>}
   */
  openModal(): Promise<void> {
    const prefOcc = this.preferredOccasion ? this.preferredOccasion.clone() : new OccasionReminder();
    prefOcc.recipient = this.recipient;
    this.preferredOccasion = null;
    const occasion = new OccasionReminder();
    occasion.origin = this.origin;
    this.analyticsService.trackInHeap('saveOccasion', { occasion });

    return this.modalService
      .showLazyModal(
        { name: 'CreateOccasionModalComponent' },
        {
          initialState: {
            default: prefOcc,
            purchaseGuestToken: this.purchaseGuestToken
          },
          class: 'modal-xs'
        }
      )
      .then((): void => {
        this.success.emit();
      })
      .catch((): void => {
        this.cancel.emit();
      });
  }

  /**
   * Launch occasion modal
   * @returns {Promise<void>}
   */
  launch(): Promise<void> {
    return this.openModal();
  }
}
