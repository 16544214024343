import { Pipe, PipeTransform } from '@angular/core';
import { Credit } from 'Shared/classes/credit';
import { Price } from 'Shared/classes/price';
import { Availability } from 'Shared/classes/availability';

@Pipe({
  name: 'pricePerDelivery'
})
export class PricePerDeliveryPipe implements PipeTransform {
  constructor() {}

  transform(price: Credit | Price | Availability, deliveries: number = 1): Price {
    return new Price(
      price.currency,
      price.quantity,
      price.original / deliveries,
      price.price / deliveries
    );
  }
}
