import * as clone from 'clone';
import { Price, CurrencyCode } from 'Shared/classes/price';

export class Availability extends Price {
  frequency: number;
  duration: number;
  perDelivery: Price;

  constructor(currency: CurrencyCode, quantity: number, original: number, discounted?: number) {
    super(currency, quantity, original, discounted);
  }
}
