import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HasContentSegment } from 'Content/guards/hasContentSegment.guard';

import { ContactUsComponent } from 'Content/components/contact-us/contact-us.component';
import { ContentComponent } from 'Content/components/content/content.component';
import { SMSSignupComponent } from 'Content/components/sms-signup/sms-signup.component';
import { FlowerJournalBlogItemsComponent } from 'Content/components/flower-journal-blog-items/flower-journal-blog-items.component';

import { ContentfulFormService } from 'Content/services/contentful-form.service';
import { SMSSignupService } from 'Content/services/sms-signup.service';
import { QuickShopComponent } from 'Content/components/quick-shop/quick-shop.component';
import { BlogItemsService } from 'Content/services/BlogItemsService.service';

import { SMSSignupModel } from 'Content/models/sms-signup-model.service';
import { ContentfulFormModel } from 'Content/models/contentful-form-model.service';
import { BlogItemsModel } from 'Content/models/BlogItemsModel.service';

import { SharedModule } from 'Shared/shared.module';
import { SitemapComponent } from 'Content/components/sitemap/sitemap.component';
import { ReviewSummaryComponent } from 'Shared/components/review-summary/review-summary.component';
import { BranchRedirectorComponent } from 'Content/components/branch-redirector/branch-redirector.component';
import { ReviewsComponent } from 'Content/components/reviews/reviews.component';
import { ContentfulFormComponent } from 'Content/components/contentful-form/contentful-form.component';
import { ContentfulFormControlComponent } from 'Content/components/contentful-form-control/contentful-form-control.component';
import { reviewsOverviewComponent } from 'Content/components/reviews-overview/reviews-overview.component';
import { WhoWeAreComponent } from 'Content/components/who-we-are/who-we-are.component';
import { BwCollapsePanelComponent } from 'Content/components/bw-collapse-panel/bw-collapse-panel.component';
import { ExperimentComponent } from 'Shared/components/experiment/experiment.component';
import { SocialNetworkBarComponent } from 'Content/components/social-network-bar/social-network-bar.component';
import { OccasionBannerComponent } from 'Content/components/occasion-banner/occasion-banner.component';
import { FormFileUploadComponent } from 'Content/components/form-file-upload/form-file-upload.component';
import { TooltipContentComponent } from 'Content/components/tooltip-content/tooltip-content.component';
import { RouterModule } from '@angular/router';
import { Trigger404Status } from 'Content/guards/trigger404Status.guard';
import { CommonModule } from '@angular/common';
import { HelpCenterComponent } from 'Content/components/help-center/help-center.component';
import { OptOutRedirectComponent } from 'Content/components/opt-out-redirect/opt-out-redirect.component';

import { NewsletterSignupComponent } from 'Content/components/newsletter-signup/newsletter-signup.component';
import { NewsletterSignupStandardComponent } from 'Content/components/newsletter-signup-standard/newsletter-signup-standard.component';
import { NewsletterSignupAdvancedComponent } from 'Content/components/newsletter-signup-advanced/newsletter-signup-advanced.component';
import { NewsletterSignupClickRevealComponent } from 'Content/components/newsletter-signup-click-reveal/newsletter-signup-click-reveal.component';
import { ShareComponent } from 'Content/components/share/share.component';
import { GiftVoucherPickerComponent } from 'Content/components/gift-voucher-picker/gift-voucher-picker.component';
import { AppsBannerComponent } from 'Content/components/apps-banner/apps-banner.component';
import { BlogsLandingPageComponent } from 'Content/components/blogs-landing-page/blogs-landing-page.component';
import { SplashCategoriesComponent } from 'Shared/components/splash-categories/splash-categories.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    SplashCategoriesComponent,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' })
  ],
  exports: [ContactUsComponent, HelpCenterComponent, SMSSignupComponent, FormFileUploadComponent, BlogsLandingPageComponent],
  declarations: [
    ContentComponent,
    ContactUsComponent,
    HelpCenterComponent,
    SitemapComponent,
    ReviewsComponent,
    SMSSignupComponent,
    QuickShopComponent,
    SitemapComponent,
    BranchRedirectorComponent,
    ContentfulFormComponent,
    ContentfulFormControlComponent,
    FlowerJournalBlogItemsComponent,
    reviewsOverviewComponent,
    WhoWeAreComponent,
    BwCollapsePanelComponent,
    SocialNetworkBarComponent,
    OccasionBannerComponent,
    FormFileUploadComponent,
    TooltipContentComponent,
    OptOutRedirectComponent,
    NewsletterSignupComponent,
    NewsletterSignupStandardComponent,
    NewsletterSignupAdvancedComponent,
    NewsletterSignupClickRevealComponent,
    ShareComponent,
    GiftVoucherPickerComponent,
    AppsBannerComponent,
    BlogsLandingPageComponent
  ],
  providers: [
    Trigger404Status,
    HasContentSegment,
    SMSSignupModel,
    SMSSignupService,
    ContentfulFormService,
    ContentfulFormModel,
    BlogItemsModel,
    BlogItemsService
  ]
})
export class ContentModule {}
