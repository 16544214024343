
<div class="bw-auth-modal">
  <div class="modal-header" [ngClass]="{'small-header': (selectedTab === 'login' || selectedTab === 'register'), 'bordered': isJoiningRewardsOnLogin}">
    <div class="modal-title" *ngIf="selectedTab === 'reset' &amp;&amp; defaultHeader">Forgot your password?</div>
    <ng-container *ngIf="!isJoiningRewardsOnLogin">
      <div class="modal-title" *ngIf="selectedTab === 'register' &amp;&amp; defaultHeader">Create account</div>
      <div class="modal-title" *ngIf="selectedTab === 'login' &amp;&amp; defaultHeader">Sign in to your account</div>
    </ng-container>
    <ng-container *ngIf="isJoiningRewardsOnLogin">
      <ng-container *ngIf="selectedTab === 'login' &amp;&amp; defaultHeader">
        <div class="modal-title text-heading--functional--secondary" *ngIf="activeLoginFormControl === 'email'">Log in or create account</div>
        <div class="modal-title text-heading--functional--secondary" *ngIf="activeLoginFormControl === 'password'">Log in</div>
      </ng-container>
      <ng-container *ngIf="selectedTab === 'register' &amp;&amp; defaultHeader">
        <div class="modal-title text-heading--functional--secondary" *ngIf="!isGuest">Create your account</div>
        <div class="modal-title text-heading--functional--secondary" *ngIf="isGuest">Verify your email</div>
      </ng-container>
    </ng-container>
    <button class="close" *ngIf="showClose" aria-label="close" (click)="onCancel()"><span aria-hidden="true" *ngIf="!(selectedTab === 'login' || selectedTab === 'register')">&times;</span><img class="close-modal-img" src="/assets/brand-icons/x.svg" *ngIf="(selectedTab === 'login' || selectedTab === 'register')"></button>
  </div>
  <div class="modal-heading text-center" *ngIf="!defaultHeader">
    <div class="modal-title strong serif" *ngIf="selectedTab === 'reset'">Forgot your password?</div>
    <div class="modal-title strong serif" *ngIf="selectedTab === 'login' || selectedTab === 'register'">Nearly there...</div>
    <div class="modal-subtitle" *ngIf="(selectedTab === 'login' || selectedTab === 'register') &amp;&amp; defaultSubtitle">You just need to create (or log into) an account to save your reminders!</div>
    <div class="modal-subtitle" *ngIf="(selectedTab === 'login' || selectedTab === 'register') &amp;&amp; !defaultSubtitle">You just need to log into your account first to save your reminders.</div>
  </div>
  <ng-container *ngIf="newAuthFormsExperiment">
    <ui-box *ngIf="authStep === 'email'" box_space="near" box_space_bottom="expanded">
      <bw-auth-email-form (didSubmit)="submitEmail()" (registerNewUser)="goToRegisterNewUser($event)"></bw-auth-email-form>
      <bw-auth-third-party [showSeparator]="true" [isModal]="true" (didSucceed)="onSuccess($event)" (didFail)="goToEmailView()" (didLoad)="onLoading($event)"></bw-auth-third-party>
    </ui-box>
  </ng-container>
  <ng-container *ngIf="newAuthFormsExperiment">
    <ui-box *ngIf="authStep === 'password'" box_space="near" box_space_bottom="expanded"><span>Password</span></ui-box>
  </ng-container>
  <ng-container *ngIf="!newAuthFormsExperiment">
    <bw-auth-login #authLogin *ngIf="selectedTab === 'login'" [defaultEmail]="defaultEmail" [enableRegister]="enableRegister" [showFullForm]="showFullForm" [order]="order" origin="default" [fullOrigin]="fullOrigin" (loginSuccess)="onSuccess()" (selectTab)="selectTab($event)" (cancel)="onCancel()" (continueAsGuest)="continueAsGuest($event)" (loginFail)="rejectAndContinueAsGuest()" (registerNewUser)="goToRegisterNewUser($event)" (registerGuestUser)="registerGuestUser($event)" (didFailOnUntrustworthyLogin)="onUntrustworthyLogin($event)" (didChangeForm)="onChangingLoginFormControl($event)"></bw-auth-login>
  </ng-container>
  <ng-container *ngIf="enableRegister &amp;&amp; !isGuest">
    <ui-box *ngIf="selectedTab === 'register'" box_space="near" box_space_bottom="default">
      <ng-container *ngIf="!isJoiningRewardsOnLogin">
        <p>Hi! Looks like you're new here. <br>Create an account to unlock these perks:</p>
        <ui-box class="register__intro-items" box_fill_mode="fill" box_space="near" box_space_bottom="default">
          <ui-stack stack_direction="x" stack_distribute="space-between">
            <ng-container *ngFor="let item of accountOfferings">
              <ui-stack class="register__intro-item" stack_gap="near" stack_align="center" *ngIf="item.show">
                <ui-icon *ngIf="item.uiIconName" [icon_name]="$any(item.uiIconName)"></ui-icon>
                <p class="text-body--supporting" *ngIf="item.name">{{item.name}}</p>
              </ui-stack>
            </ng-container>
          </ui-stack>
        </ui-box>
      </ng-container>
      <ui-box *ngIf="isJoiningRewardsOnLogin" box_space="none" box_space_bottom="near">
        <ui-stack stack_gap="near">
          <ng-container *ngIf="!order">
            <p class="text-body--default" *inExperiment="{name: 'AUTO_OPT_IN_TO_REWARDS', variants: [1]}">Hello – looks like you’re new here. Create an account to join Bloom & Wild Rewards, and enjoy all these lovely perks:</p>
            <p class="text-body--default" *inExperiment="{name: 'AUTO_OPT_IN_TO_REWARDS', variants: [2]}">Hello – looks like you’re new here. Create an account to join Bloom & Wild Rewards.</p>
          </ng-container>
          <ng-container *ngIf="order">
            <p class="text-body--default" *inExperiment="{name: 'AUTO_OPT_IN_TO_REWARDS', variants: [1, 2]}">Hello – looks like you’re new here. Create an account to join Bloom & Wild Rewards, and earn <span class="text-body--emphasis">{{loyaltyOrderPoints}}</span> points on this order.</p>
          </ng-container>
          <ui-box *inExperiment="{name: 'AUTO_OPT_IN_TO_REWARDS', variants: [2]}" box_space="tight" box_border_width="primary" box_border_color="secondary" box_border_radius="layout">
            <ui-stack stack_gap="near" stack_direction="x" stack_align="center">
              <ui-image class="register__joining-image" image_src="/assets/images/rewards/earn-points.svg" loading="lazy" image_alt_text="Earn points"></ui-image>
              <p class="text-body--default" *ngIf="origin !== 'checkout' &amp;&amp; !order">It’s free to join, and you’ll get {{loyaltyWelcomePoints}} welcome points.</p>
              <p class="text-body--default" *ngIf="origin === 'checkout'">It’s free to join, and you’ll get {{loyaltyWelcomePoints}} welcome points (on top of your order points).</p>
              <p class="text-body--default" *ngIf="origin !== 'checkout' &amp;&amp; order">It’s free to join, and you’ll get {{loyaltyWelcomePoints}} welcome points (on top of your order points).</p>
            </ui-stack>
          </ui-box>
        </ui-stack>
        <ui-box box_space="near" box_space_left="none" box_space_right="none">
          <ui-box *ngIf="origin === 'checkout' || isJoiningRewardsVariant2" box_space="none" box_space_bottom="tight">
            <p class="text-body--supporting text-color--supporting text-body--emphasis">Plus all these lovely perks:</p>
          </ui-box>
          <ui-stack stack_gap="near" stack_direction="y" stack_collapse_below="tablet" [stack_wrap]="true">
            <ui-box *ngFor="let item of [].constructor(4); let i = index" box_space="none" box_fill_mode="fit">
              <ui-stack stack_gap="tight" stack_direction="x" stack_align="center">
                <ui-box box_background="rewards-tertiary" box_space="tiny" box_fill_mode="fit" box_border_radius="rewards-icon">
                  <ui-icon icon_name="tick"></ui-icon>
                </ui-box>
                <p class="text-body--supporting" *ngIf="i === 0">£5 off every 500 points</p>
                <p class="text-body--supporting" *ngIf="i === 1">Birthday treat</p>
                <p class="text-body--supporting" *ngIf="i === 2">Save your addresses</p>
                <p class="text-body--supporting" *ngIf="i === 3">Easy order tracking</p>
              </ui-stack>
            </ui-box>
            <p class="text-body--supporting text-color--supporting">You can <a class="text-link--paragraph" href="/bw-rewards-terms" target="_blank">read our full T&Cs here</a>.</p>
          </ui-stack>
        </ui-box>
      </ui-box>
      <bw-register-form [origin]="origin" [preferredName]="preferredName" [preferedEmail]="defaultEmail" [isJoiningRewards]="isJoiningRewardsOnLogin" [fullOrigin]="fullOrigin" (didSuccess)="onSuccess($event)"></bw-register-form>
    </ui-box>
  </ng-container>
  <ng-container *ngIf="enableRegister &amp;&amp; isGuest">
    <ui-box *ngIf="selectedTab === 'register'" box_space="near" box_space_bottom="default">
      <bw-details-user-guest bwFormControlName="email" [preferredName]="preferredName" [preferredEmail]="defaultEmail" [toRegister]="true" [order]="order" (back)="selectTab('login')"></bw-details-user-guest>
    </ui-box>
  </ng-container>
  <div class="modal-footer" *ngIf="selectedTab !== 'login'" [ngClass]="{'text-body--supporting': isJoiningRewardsOnLogin}"><span>Wrong email?</span>
    <bw-button buttonStyle="text-link" (clicked)="selectTab('login')" bwTrackAs="checkout.userDetails.openLoginModal">Go back</bw-button>
  </div>
  <bw-loading-spinner *ngIf="loading" [fullscreen]="false"></bw-loading-spinner>
</div>