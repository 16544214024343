import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, ViewChild } from '@angular/core';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { IActiveViewports, ViewportDetectionService } from 'Shared/services/viewport-detection.service';
import { TranslationKey, t } from 'Shared/utils/translations';
import { CarouselComponent, OasysCarouselModule, OasysIconModule, OasysLayoutModule } from 'oasys-lib';
import { BehaviorSubject } from 'rxjs';

/* bw:view-encapsulation */

@Component({
  selector: 'bw-whisper-usps',
  templateUrl: './whisper-usps.component.html',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, OasysLayoutModule, OasysIconModule, OasysCarouselModule, NgFor, NgIf]
})
export class WhisperUspsComponent {
  carousel: CarouselComponent;
  viewportSizeIs$: BehaviorSubject<IActiveViewports> = this.viewportDetectionService.viewportSizeIs$;

  private usps = ['a', 'b', 'c', 'd'];
  constructor(private viewportDetectionService: ViewportDetectionService, private experimentsService: ExperimentsService) {}

  @ViewChild(CarouselComponent) set content(content: CarouselComponent) {
    if (content) {
      this.carousel = content;

      setTimeout((): void => {
        content.swiper.nativeElement.swiper.autoplay.start(6000);
      }, 100);
    }
  }

  getUsps(): string[] {
    [1, 2, 3, 4].forEach((variant): void => {
      if (this.experimentsService.isActive('WHISPER_USPS', variant)) {
        this.setUspsOrder(variant);
      }
    });
    return this.usps;
  }

  isIcon = (variant: string): boolean => this.isIconName(this.translatedIconName(variant));
  isImage = (variant: string): boolean => this.translatedImageSrc(variant).includes('.');

  translatedContent = (variant: string): string => {
    const translationKey = `js.component.whisper-usps.content-${variant}` as TranslationKey;
    return t(translationKey);
  };

  translatedIconName = (variant: string): IconName => {
    const translationKey = `js.component.whisper-usps.logo-${variant}` as TranslationKey;
    return t(translationKey) as IconName;
  };

  translatedImageSrc = (variant: string): string => {
    const translationKey = `js.component.whisper-usps.logo-${variant}` as TranslationKey;
    return t(translationKey);
  };

  goToNextSlide(): void {
    this.carousel.swiper.nativeElement.swiper.slideNext();
  }

  goToPrevSlide(): void {
    this.carousel.swiper.nativeElement.swiper.slidePrev();
  }

  private isIconName(maybeIcon: string): maybeIcon is IconName {
    return iconNames.includes(maybeIcon as IconName);
  }

  private setUspsOrder(variant: number): void {
    const count = variant - 1;
    const usps = ['a', 'b', 'c', 'd'];
    const length = usps.length;
    usps.push(...usps.splice(0, ((count % -length) + length) % length));
    this.usps = usps;
  }
}

// hardcoded local icon name types - to be replaced by oasys import once all new types added to oasys & exposed from library
const iconNames = ['delivery', 'heart', 'calendar', 'gift', 'greeting-card'] as const;
export type IconName = (typeof iconNames)[number];
