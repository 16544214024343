import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { StateName, StateParams, StateService } from 'Shared/services/state.service';
import { User } from 'Shared/services/user.service';
import { AuthModalComponent } from 'Shared/components/modals/auth-modal/auth-modal.component';
import { ModalService } from 'Shared/services/modal.service';
import { t } from 'Shared/utils/translations';
import { LocationService } from 'Shared/services/location.service';

@Injectable()
export class ModalToShowGuard implements CanActivate {
  constructor(private modalService: ModalService, private stateService: StateService, private locationService: LocationService) {}

  /**
   * Can activate
   */
  canActivate(activeState: ActivatedRouteSnapshot): Promise<boolean> {
    const modalToLaunch = activeState.data.modalToLaunch as string;

    const redirectToState = this.locationService.getCurrentParams().redirectOnSuccess;

    let promise: Promise<unknown>;
    if (modalToLaunch === 'authLogin') {
      promise = this.showLoginModal();
    }
    if (modalToLaunch === 'addOccasion') {
      promise = this.showAddOccasionsModal();
    }

    if (modalToLaunch === 'authRegister') {
      promise = this.showRegisterModal();
    }

    if (modalToLaunch === 'createAccount') {
      promise = this.showCreateAccountModal();
    }

    if (modalToLaunch === 'resetPassword') {
      promise = this.showResetPasswordModal();
    }

    if (modalToLaunch === 'activateAccountMagicLink') {
      promise = this.showActivateAccountdMagicLinkModal();
    }

    if (modalToLaunch === 'helpWithDelivery') {
      promise = this.showHcDeliverySelfServeModal();
    }

    if (modalToLaunch === 'helpWithQuality') {
      promise = this.showHcQualitySelfServeModal();
    }

    // We are not returning here, as we need to ensure that this state can activate, and the modal will launch ontop
    promise
      .then((defaultRedirect: string | undefined): void => {
        this.stateService.go((redirectToState || defaultRedirect || 'homepage') as StateName);
      })
      .catch((): void => {
        this.stateService.go('homepage');
      });

    return Promise.resolve(true);
  }

  /**
   * Show the quick add occasions modal
   * @returns {Promise<void>}
   */
  showAddOccasionsModal(): Promise<void | boolean> {
    return this.modalService
      .showLazyModal(
        { name: 'CreateOccasionModalComponent' },
        {
          initialState: {},
          class: 'modal-xs'
        }
      )
      .then((): void => {
        this.stateService.go('homepage');
      })
      .catch((): void => {
        this.stateService.go('homepage');
      });
  }

  /**
   * Show the login modal
   * @returns {Promise<Record<string, string | boolean>>}
   */
  showLoginModal(): Promise<Record<string, string | boolean>> {
    return this.modalService.show(AuthModalComponent, {
      initialState: {
        title: t('js.guard.has-logged-in.title'),
        selectedTab: 'login',
        origin: 'modal',
        fullOrigin: 'webAccountLaunchModal'
      },
      ignoreBackdropClick: true,
      class: 'modal-sm'
    });
  }

  /**
   * Show reset password modal
   * @returns {Promise<User>}
   */
  showResetPasswordModal(): Promise<User> {
    const token =
      (this.stateService.getCurrent().params as StateParams).resetToken ?? (this.stateService.getCurrent().params as StateParams).token;
    const email = (this.stateService.getCurrent().params as StateParams).email ?? null;

    return this.modalService.showLazyModal(
      { name: 'ResetPasswordModalComponent' },
      {
        initialState: {
          token,
          email,
          modalTitle: t('js.components.launch-modal.resetPassword.title')
        },
        ignoreBackdropClick: true,
        dismissDisplayingModals: false,
        class: 'modal-sm',
        trackingKey: 'auth-modal-reset-password'
      }
    );
  }

  /**
   * Show activate account magic link modal
   * @returns {Promise<void>}
   */
  showActivateAccountdMagicLinkModal(): Promise<void> {
    const email = (this.stateService.getCurrent().params as StateParams)?.email ?? null;

    return this.modalService.showLazyModal(
      { name: 'MagicLinkModalComponent' },
      {
        initialState: {
          email
        },
        ignoreBackdropClick: true,
        class: 'modal-sm'
      }
    );
  }

  /**
   * Show register modal
   * @returns {Promise<Record<string, string | boolean>>}
   */
  showRegisterModal(): Promise<Record<string, string | boolean>> {
    return this.modalService.show(AuthModalComponent, {
      initialState: {
        title: t('js.guard.has-logged-in.title'),
        selectedTab: 'register',
        origin: 'modal',
        fullOrigin: 'webAccountLaunchModal'
      },
      ignoreBackdropClick: true,
      class: 'modal-sm'
    });
  }

  /**
   * Show create account modal
   * @returns {Promise<User>}
   */
  showCreateAccountModal(): Promise<User> {
    const token =
      (this.stateService.getCurrent().params as StateParams).resetToken || (this.stateService.getCurrent().params as StateParams).token;

    return this.modalService.showLazyModal(
      { name: 'ResetPasswordModalComponent' },
      {
        initialState: {
          token,
          modalTitle: t('js.components.launch-modal.createAccount.title')
        },
        ignoreBackdropClick: true,
        dismissDisplayingModals: false,
        class: 'modal-sm',
        trackingKey: 'auth-modal-reset-password'
      }
    );
  }

  /**
   * Show hc-delivery-self-serve modal
   * @returns {Promise<string>}
   */
  showHcDeliverySelfServeModal(): Promise<string> {
    return this.modalService
      .showLazyModal(
        { name: 'HcDeliverySelfServeComponent' },
        {
          initialState: {},
          class: 'modal-sm help-center-modal'
        }
      )
      .catch((): void => {})
      .then((): string => 'help');
  }

  /**
   * Show hc-quality-self-serve modal
   * @returns {Promise<string>}
   */
  showHcQualitySelfServeModal(): Promise<string> {
    return this.modalService
      .showLazyModal(
        { name: 'HcQualitySelfServeComponent' },
        {
          initialState: {},
          class: 'modal-sm help-center-modal'
        }
      )
      .catch((): void => {})
      .then((): string => 'help');
  }
}
