/* tslint:disable */
export const knownStates = [
  {
    "name": "checkout.editsubscription",
    "path": "send-flowers/edit-subscription/:orderId",
    "data": {
      "preload": true,
      "delay": true
    }
  },
  {
    "name": "checkout.editorder",
    "path": "send-flowers/edit-order/:orderId",
    "data": {
      "preload": true,
      "delay": true
    }
  },
  {
    "name": "checkout.start",
    "path": "send-flowers/checkout",
    "data": {
      "preload": true,
      "delay": true
    }
  },
  {
    "name": "checkout.restore",
    "path": "send-flowers/restore"
  },
  {
    "name": "checkout.bankredirect",
    "path": "send-flowers/purchase-pending"
  },
  {
    "name": "checkout.payment",
    "path": "send-flowers/payment"
  },
  {
    "name": "checkout.confirmation",
    "path": "confirmation"
  },
  {
    "name": "checkout.giftOptions",
    "path": "send-flowers/gift-options",
    "data": {
      "preload": true,
      "delay": true
    }
  },
  {
    "name": "checkout.greetingCardsGrid",
    "path": "send-flowers/gift-options/greeting-cards/:cardGroupID",
    "data": {
      "preload": true,
      "delay": true
    }
  },
  {
    "name": "login",
    "path": "login",
    "data": {
      "modalToLaunch": "authLogin",
      "contentToLoad": "homepage"
    }
  },
  {
    "name": "register",
    "path": "register",
    "data": {
      "modalToLaunch": "authRegister",
      "contentToLoad": "homepage"
    }
  },
  {
    "name": "create-account",
    "path": "create-account/:resetToken",
    "data": {
      "modalToLaunch": "createAccount",
      "contentToLoad": "homepage"
    }
  },
  {
    "name": "create-account-param",
    "path": "create-account",
    "data": {
      "modalToLaunch": "createAccount",
      "contentToLoad": "homepage"
    }
  },
  {
    "name": "reset-password",
    "path": "reset-password",
    "data": {
      "modalToLaunch": "resetPassword",
      "contentToLoad": "homepage"
    }
  },
  {
    "name": "reset-password-with-token",
    "path": "reset-password/:resetToken",
    "data": {
      "modalToLaunch": "resetPassword",
      "contentToLoad": "homepage"
    }
  },
  {
    "name": "activate-account",
    "path": "activate-account",
    "data": {
      "modalToLaunch": "activateAccountMagicLink",
      "contentToLoad": "homepage"
    }
  },
  {
    "name": "quick-add-occasion",
    "path": "add-occasion",
    "data": {
      "modalToLaunch": "addOccasion",
      "contentToLoad": "homepage"
    }
  },
  {
    "name": "help-with-delivery",
    "path": "help-with-delivery",
    "data": {
      "modalToLaunch": "helpWithDelivery",
      "contentToLoad": "help"
    }
  },
  {
    "name": "help-with-quality",
    "path": "report-quality-issue",
    "data": {
      "modalToLaunch": "helpWithQuality",
      "contentToLoad": "help"
    }
  },
  {
    "name": "subscribe",
    "path": "subscribe/:emailSlug",
    "data": {
      "autoSetAsSubscribed": true
    }
  },
  {
    "name": "unsubscribe",
    "path": "unsubscribe/:emailSlug"
  },
  {
    "name": "payment-result",
    "path": "payment-result"
  },
  {
    "name": "giftCertificate",
    "path": "gift-certificate/:orderId/:orderToken"
  },
  {
    "name": "applyCreditCode",
    "path": "my-credit/:code",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "logout",
    "path": "logout"
  },
  {
    "name": "account.orders",
    "path": "my-account/orders",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "account.orders.delivered",
    "path": "my-account/orders/delivered",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "ordersView",
    "path": "my-orders/order/:orderId",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "subscriptionNextDelivery",
    "path": "my-orders/order/:orderId/next-delivery",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "ordersView.share",
    "path": "my-orders/order/:orderId/share",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "subscriptionReactivate",
    "path": "my-orders/order/:orderId/reactivate",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "orderDeliveryView",
    "path": "my-orders/order/:orderId/delivery/:deliveryId",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "orderDeliveriesManagement",
    "path": "my-orders/order/:orderId/all-my-deliveries",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "subscriptionDetails",
    "path": "my-orders/order/:orderId/subscription-details",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "account.invoices",
    "path": "my-account/invoices/:purchaseId",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "account.credit",
    "path": "my-account/credit",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "account.details",
    "path": "my-account/details",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "account.cards",
    "path": "my-account/cards",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "account.marketing",
    "path": "my-account/marketing-preferences",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "account.addresses",
    "path": "my-account/addresses",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "account.occasions",
    "path": "my-account/occasions",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "account.favourites",
    "path": "my-account/favourites",
    "data": {
      "isProductGrid": true,
      "authenticate": true
    }
  },
  {
    "name": "validateUnusualSignIn",
    "path": "validate-unusual-sign-in",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "account.rewards",
    "path": "my-account/rewards",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "account.rewards.join",
    "path": "my-account/rewards/join",
    "data": {
      "authenticate": true
    }
  },
  {
    "name": "redirectCredit",
    "path": "my-credit"
  },
  {
    "name": "redirectMyOrders",
    "path": "my-orders"
  },
  {
    "name": "redirectMyAccount",
    "path": "my-account"
  },
  {
    "name": "redirectMyOccasions",
    "path": "my-occasions"
  },
  {
    "name": "redirectMyOccasionsPage",
    "path": "my-occasions/occasions/:page"
  },
  {
    "name": "orderspage",
    "path": "orders/:page"
  },
  {
    "name": "myaccountorderspage",
    "path": "my-account/orders/:page"
  },
  {
    "name": "account.postal",
    "path": "my-account/postal-preferences"
  },
  {
    "name": "account.email",
    "path": "my-account/email-preferences"
  },
  {
    "name": "checkout.colour",
    "path": "send-flowers/colour/:colour",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.tagOnly",
    "path": "send-flowers/tagonly/:tagOnly",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.tagOnlyType",
    "path": "send-flowers/tagonly/:tagOnly/type/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.bouquetAddon",
    "path": "send-flowers/bouquet/:bouquet/:addon",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.bouquet",
    "path": "send-flowers/bouquet/:bouquet",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.bouquets",
    "path": "send-flowers/bouquets/:bouquet",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.bouquetAddOnType",
    "path": "send-flowers/bouquet/:bouquet/:addon/type/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.sendType",
    "path": "send-flowers/type/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.tag",
    "path": "send-flowers/tag/:tag",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.tagType",
    "path": "send-flowers/tag/:tag/type/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.trackingBouquet",
    "path": "tracking/:trackingCode/send-flowers/bouquet/:bouquet",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.trackingTag",
    "path": "tracking/:trackingCode/send-flowers/tag/:tag",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.tracking",
    "path": "tracking/:trackingCode/send-flowers",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.discountCode",
    "path": "credit/:discountCode/send-flowers/type/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.discountAddon",
    "path": "credit/:discountCode/send-flowers/addon/:addon",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.discountBouquetAddon",
    "path": "credit/:discountCode/send-flowers/bouquet/:bouquet",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.discountBouquet",
    "path": "credit/:discountCode/send-flowers/bouquet/:bouquet/:addon",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.discountTag",
    "path": "credit/:discountCode/send-flowers/tag/:tag",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.discountTagOnly",
    "path": "credit/:discountCode/send-flowers/tagonly/:tagOnly",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.discountTagOnlyAddon",
    "path": "credit/:discountCode/send-flowers/tagonly/:tagOnly/:addon",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.discountTagType",
    "path": "credit/:discountCode/send-flowers/:tag/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.discount",
    "path": "credit/:discountCode/send-flowers",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.type",
    "path": "type/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.productpage",
    "path": "send-flowers/send/:slug/:skuId",
    "data": {
      "preload": true,
      "delay": true
    }
  },
  {
    "name": "favourites",
    "path": "favourites",
    "data": {
      "preload": true,
      "delay": true
    }
  },
  {
    "name": "checkout.subscription",
    "path": "send-flowers/treat-yourself-subscriptions",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "checkout.base",
    "path": "send-flowers",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "redirectContent",
    "path": "content"
  },
  {
    "name": "homepage"
  },
  {
    "name": "tracking",
    "path": "tracking/:trackingCode",
    "data": {
      "contentToLoad": "homepage"
    }
  },
  {
    "name": "giftvouchers",
    "path": "gift-cards",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    }
  },
  {
    "name": "help",
    "path": "help"
  },
  {
    "name": "faq",
    "path": "faqs"
  },
  {
    "name": "sitemap",
    "path": "sitemap"
  },
  {
    "name": "branch",
    "path": "branch"
  },
  {
    "name": "reviews",
    "path": "reviews"
  },
  {
    "name": "blog.base",
    "path": "the-blog",
    "data": {
      "isNewSpace": true
    }
  },
  {
    "name": "blog.tagged",
    "path": "the-blog/tagged/:tagged",
    "data": {
      "isNewSpace": true
    }
  },
  {
    "name": "share",
    "path": "share"
  },
  {
    "name": "share-subscription",
    "path": "sub-share",
    "data": {
      "subscriptionCampaign": true
    }
  },
  {
    "name": "share",
    "path": "share"
  },
  {
    "name": "invite",
    "path": "invite",
    "data": {
      "segment": "subscriber"
    }
  },
  {
    "name": "pageNotFound"
  },
  {
    "name": "404",
    "path": "404",
    "data": {
      "contentToLoad": "404"
    }
  },
  {
    "name": "content",
    "path": "**"
  }
];
export const lazyStates = [
  {
    "path": "login",
    "data": {
      "modalToLaunch": "authLogin",
      "contentToLoad": "homepage"
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "register",
    "data": {
      "modalToLaunch": "authRegister",
      "contentToLoad": "homepage"
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "create-account/:resetToken",
    "data": {
      "modalToLaunch": "createAccount",
      "contentToLoad": "homepage"
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "create-account",
    "data": {
      "modalToLaunch": "createAccount",
      "contentToLoad": "homepage"
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "reset-password",
    "data": {
      "modalToLaunch": "resetPassword",
      "contentToLoad": "homepage"
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "reset-password/:resetToken",
    "data": {
      "modalToLaunch": "resetPassword",
      "contentToLoad": "homepage"
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "activate-account",
    "data": {
      "modalToLaunch": "activateAccountMagicLink",
      "contentToLoad": "homepage"
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "add-occasion",
    "data": {
      "modalToLaunch": "addOccasion",
      "contentToLoad": "homepage"
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "help-with-delivery",
    "data": {
      "modalToLaunch": "helpWithDelivery",
      "contentToLoad": "help"
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "report-quality-issue",
    "data": {
      "modalToLaunch": "helpWithQuality",
      "contentToLoad": "help"
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "subscribe/:emailSlug",
    "data": {
      "autoSetAsSubscribed": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "unsubscribe/:emailSlug",
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "payment-result",
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "gift-certificate/:orderId/:orderToken",
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-credit/:code",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "logout",
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/orders",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/orders/delivered",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-orders/order/:orderId",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-orders/order/:orderId/next-delivery",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-orders/order/:orderId/share",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-orders/order/:orderId/reactivate",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-orders/order/:orderId/delivery/:deliveryId",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-orders/order/:orderId/all-my-deliveries",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-orders/order/:orderId/subscription-details",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/invoices/:purchaseId",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/credit",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/details",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/cards",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/marketing-preferences",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/addresses",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/occasions",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/favourites",
    "data": {
      "isProductGrid": true,
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "validate-unusual-sign-in",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/rewards",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/rewards/join",
    "data": {
      "authenticate": true
    },
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-credit",
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-orders",
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account",
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-occasions",
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-occasions/occasions/:page",
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "orders/:page",
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/orders/:page",
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/postal-preferences",
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "my-account/email-preferences",
    "loadChildren": () => import('Project/account/account.module').then(m => m.AccountModule)
  },
  {
    "path": "send-flowers/edit-subscription/:orderId",
    "data": {
      "preload": true,
      "delay": true
    },
    "loadChildren": () => import('Project/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    "path": "send-flowers/edit-order/:orderId",
    "data": {
      "preload": true,
      "delay": true
    },
    "loadChildren": () => import('Project/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    "path": "send-flowers/checkout",
    "data": {
      "preload": true,
      "delay": true
    },
    "loadChildren": () => import('Project/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    "path": "send-flowers/restore",
    "loadChildren": () => import('Project/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    "path": "send-flowers/purchase-pending",
    "loadChildren": () => import('Project/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    "path": "send-flowers/payment",
    "loadChildren": () => import('Project/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    "path": "confirmation",
    "loadChildren": () => import('Project/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    "path": "send-flowers/gift-options",
    "data": {
      "preload": true,
      "delay": true
    },
    "loadChildren": () => import('Project/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    "path": "send-flowers/gift-options/greeting-cards/:cardGroupID",
    "data": {
      "preload": true,
      "delay": true
    },
    "loadChildren": () => import('Project/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    "path": "send-flowers/colour/:colour",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "send-flowers/tagonly/:tagOnly",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "send-flowers/tagonly/:tagOnly/type/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "send-flowers/bouquet/:bouquet/:addon",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "send-flowers/bouquet/:bouquet",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "send-flowers/bouquets/:bouquet",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "send-flowers/bouquet/:bouquet/:addon/type/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "send-flowers/type/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "send-flowers/tag/:tag",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "send-flowers/tag/:tag/type/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "tracking/:trackingCode/send-flowers/bouquet/:bouquet",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "tracking/:trackingCode/send-flowers/tag/:tag",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "tracking/:trackingCode/send-flowers",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "credit/:discountCode/send-flowers/type/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "credit/:discountCode/send-flowers/addon/:addon",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "credit/:discountCode/send-flowers/bouquet/:bouquet",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "credit/:discountCode/send-flowers/bouquet/:bouquet/:addon",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "credit/:discountCode/send-flowers/tag/:tag",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "credit/:discountCode/send-flowers/tagonly/:tagOnly",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "credit/:discountCode/send-flowers/tagonly/:tagOnly/:addon",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "credit/:discountCode/send-flowers/:tag/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "credit/:discountCode/send-flowers",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "type/:type",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "send-flowers/send/:slug/:skuId",
    "data": {
      "preload": true,
      "delay": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "favourites",
    "data": {
      "preload": true,
      "delay": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "send-flowers/treat-yourself-subscriptions",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  },
  {
    "path": "send-flowers",
    "data": {
      "preload": true,
      "delay": true,
      "isProductGrid": true
    },
    "loadChildren": () => import('Project/browse/browse.module').then(m => m.BrowseModule)
  }
];