import { Component, Input, OnInit } from '@angular/core';
import { Country, CountryService } from 'Shared/services/country.service';
import { I18nComponent } from 'Shared/components/i18n/i18n.component';
import { PurchaseService } from 'Checkout/services/purchase.service';
import { StateService } from 'Shared/services/state.service';
import { ModalService } from 'Shared/services/modal.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ConfirmModalComponent } from 'Shared/components/modals/confirm-modal/confirm-modal.component';
import { t } from 'Shared/utils/translations';
import { LocationService } from 'Shared/services/location.service';
import { AnalyticsService } from 'Project/shared/services/analytics.service';
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { PersistentCheckoutService } from 'Checkout/services/persistent-checkout.service';
import { CheckoutService } from 'Checkout/services/checkout.service';
import { User, UserService } from 'Shared/services/user.service';

export type countrySelectorContext = 'navbar' | 'checkout' | 'checkout-ie';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-i18n-selector',
  templateUrl: './i18n-selector.component.html'
})
export class I18nSelectorComponent implements OnInit {
  @Input() context: countrySelectorContext = 'navbar';

  siteCountry: Country;
  countries: Country[];
  dropdownShown: boolean = false;
  popperPlacement: NgxPopperjsPlacements = NgxPopperjsPlacements.BOTTOMSTART;
  popperTrigger: NgxPopperjsTriggers = NgxPopperjsTriggers.click;

  constructor(
    private countryService: CountryService,
    private purchaseService: PurchaseService,
    private analyticsService: AnalyticsService,
    private state: StateService,
    private modalService: ModalService,
    private windowRef: WindowRefService,
    private locationService: LocationService,
    private persistentCheckoutService: PersistentCheckoutService,
    private checkoutService: CheckoutService,
    private userService: UserService
  ) {
    this.countries = this.countryService.getCountries();
    this.siteCountry = this.countryService.forShipping;
  }

  /**
   * Confirmation modal
   */
  confirmModal(): Promise<unknown> {
    return this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: t('js.directives.checkout.bwDeliveryDetails.confirmModal.title'),
        body: t('js.directives.checkout.bwDeliveryDetails.confirmModal.content'),
        successText: t('js.directives.checkout.bwDeliveryDetails.confirmModal.successText'),
        cancelText: t('js.directives.checkout.bwDeliveryDetails.confirmModal.cancelText')
      }
    });
  }

  /**
   * checks if confirmation should be shown
   */
  shouldShowConfirmation(): boolean {
    return !!this.purchaseService.getPurchase().orders.length;
  }

  /**
   * Open I18n modal to allow user to select different country
   * @param preferredCountry
   */
  i18nModal(preferredCountry: Country): Promise<void | User> {
    const initialState = {
      preferredCountry: preferredCountry || this.countryService.forShipping,
      context: this.context
    };

    if (this.context === 'checkout' || this.context === 'checkout-ie') {
      this.analyticsService.track('component.country-modal.checkout-open', {
        modalType: 'ChangeDeliveryCountry',
        modalValue: 'Open Delivery Country Modal'
      });
      this.analyticsService.track('component.country-modal.checkout-error', {
        modalValue:
          this.context === 'checkout'
            ? t('component.address-form.auto_key.country-invalid-postcode')
            : t('component.address-form.auto_key.ie.country-invalid-postcode')
      });
    }
    return this.modalService
      .show(I18nComponent, {
        initialState,
        trackingKey: 'deliveryCountrySelectionModal'
      })
      .then((data): unknown => (this.shouldShowConfirmation() ? this.confirmModal().then((): unknown => data) : data))
      .then((data: { selectedCountry: Country; selectedLocale: string }): void => {
        // Check if the site supports the country id
        const isCountryIdSupported = this.countryService.supportsShippingToCountryId(`${data.selectedCountry.id}`, data.selectedLocale);

        // Temporary redirect to remove when we'll remove the FR site from the codebase
        // TODO: remove as part of #180759792
        if (data.selectedCountry.id === 5) {
          this.windowRef.nativeWindow.location = 'site-closed-fr-en.html';
        }

        if (!isCountryIdSupported) {
          const url = this.locationService.convertCountryToSubfolder(`/?countryId=${data.selectedCountry.id}`, data.selectedLocale);
          this.windowRef.nativeWindow.location = `${url}`;
          return;
        }

        if (data.selectedCountry.id !== this.siteCountry.id) {
          this.analyticsService.trackCountryChange(this.siteCountry, data.selectedCountry);
          this.countryService.setCountryForShipping(data.selectedCountry);
          this.persistentCheckoutService.clearLocalStorage();
          this.purchaseService.reset();
          this.checkoutService.resetCheckout();

          const currentState = this.state.getCurrent();
          const isCheckout = currentState.name.split('.')[0] === 'checkout';

          this.state.go(
            isCheckout ? 'checkout.base' : currentState,
            {
              countryId: `${data.selectedCountry.id}`
            },
            { reload: !isCheckout }
          );
        }
      })
      .then((): Promise<User> => this.userService.refreshUser())
      .catch((): void => {});
  }

  /**
   * On init
   */
  ngOnInit(): void {
    if (this.countryService.forShipping$) {
      this.countryService.forShipping$.subscribe((fs): void => {
        this.siteCountry = fs;
        this.countries = this.countryService.getCountries();
      });
    }
  }
}
