import { Pipe, PipeTransform } from '@angular/core';
import { Price } from 'Shared/classes/price';
import { Availability } from 'Shared/classes/availability';

@Pipe({
  name: 'comparePrice'
})
export class ComparePricePipe implements PipeTransform {
  transform(value: Availability | Price, args?: Availability | Price): any {
    try {
      const difference = value.price - args.price;
      return new Price(value.currency, 1, difference, difference);
    } catch (e) {
      return new Price(value.currency, 1, 0, 0);
    }
  }
}
