import { Injectable } from '@angular/core';
import { Country } from 'Shared/classes/country';
import { Product } from 'Shared/classes/product';
import { APISerialisedJSONResponse, BackendService } from 'Shared/services/backend.service';
import * as dayjs from 'dayjs';
import { Discount } from 'Shared/classes/discount';
import { ConfigService } from 'Shared/services/config.service';
import { Addon } from 'Shared/classes/addon';
import { Price } from 'Shared/classes/price';

@Injectable({
  providedIn: 'root'
})
export class AddonModelService {
  constructor(private backend: BackendService, private configService: ConfigService) {}

  /**
   * From payload
   * @param res
   */
  fromPayload(res: APISerialisedJSONResponse<'/v2/availability/sku_addons'>['data'][number]): Addon {
    const addon = new Addon();

    const attr = (res.attributes || res) as APISerialisedJSONResponse<'/v2/availability/sku_addons'>['data'][number]['attributes'];
    attr.media = attr.media && attr.media.length ? attr.media : [];

    addon.id = parseInt(res.id, 10);
    addon.slug = attr.slug;
    addon.name = attr.name;

    addon.over18Only = attr.eighteen_plus;
    addon.description = attr.description;
    addon.longDescription = attr.long_description;
    addon.shortDescription = attr.email_description;

    addon.imageUrls = attr.media.filter((m): boolean => !m.combination).map((m): string => m.url);
    addon.productCombinationImageUrls = attr.media.filter((m): unknown => m.combination).map((m): string => m.url);

    addon.appearingFrom = dayjs(attr.appearing_from);
    addon.appearingTo = dayjs(attr.appearing_to);
    addon.deliverableFrom = dayjs(attr.deliverable_from).startOf('day');
    addon.deliverableTo = dayjs(attr.deliverable_to).endOf('day');

    addon.rating = {
      count: attr.rating_count,
      average: attr.rating_average
    };
    addon.tags = (attr.tags || []).map((t): string => t.toLowerCase());

    addon.type = attr.addon_kind || 'default';

    if (attr.prices && attr.prices.length) {
      addon.price = new Price(
        attr.currency,
        attr.prices[0].number_of_deliveries,
        attr.prices[0].price_pennies,
        attr.prices[0].price_pennies_discounted
      );
    }

    addon.isSelected = attr.is_selected;
    addon.isSelectable = attr.available_to_select;

    return addon;
  }

  /**
   * Get the addons
   * @param country
   * @param product
   * @param selectedAddons
   * @param deliveryDate
   * @param orderIndex
   * @param enableVaseAsKind
   * @param discount
   */
  getAddons(
    country: Country,
    product: Product,
    selectedAddons: Addon[],
    deliveryDate: dayjs.Dayjs,
    orderIndex: number,
    enableVaseAsKind: boolean = false,
    discount?: Discount
  ): Promise<Addon[]> {
    const serverTime = this.configService.getConfig().serverTime;
    const date = deliveryDate.format('YYYY-MM-DD');
    const discountCode = discount ? discount.code : undefined;
    const firstItemInPurchase = !orderIndex || orderIndex === 0;
    const addonsSelected = selectedAddons && selectedAddons.length ? selectedAddons : [];

    return this.backend
      .get(null, '/v2/availability/sku_addons', {
        useUrlAsCache: true,
        sendExperiments: ['API_'],
        params: {
          shipping_country_id: country.id,
          primary_sku_id: product.id,
          delivery_date: date,
          discount_code: discountCode,
          first_item_in_purchase: firstItemInPurchase,
          multi_addons: true,
          'selected_addon_skus[]': addonsSelected.map((a): string | number => a.slug || a.id).sort(),
          enable_addon_kinds: true,
          enable_addon_vase_kind: enableVaseAsKind
        }
      })
      .then((res): Addon[] => {
        if (!res || !res.data) {
          return [];
        }

        const addons = res.data
          .map((r): Addon => {
            const addon = this.fromPayload(r);
            return addon;
          })
          .filter(
            (addon): boolean =>
              addon.appearingFrom.unix() <= serverTime.unix() &&
              addon.appearingTo.unix() >= serverTime.unix() &&
              addon.deliverableFrom.unix() <= deliveryDate.unix() &&
              addon.deliverableTo.unix() >= deliveryDate.unix()
          );

        return addons;
      });
  }
}
