
<div class="bw-auth-login-email">
  <bw-form-text-input [bwFormControl]="email" label="Your email" inputType="email">
    <ng-container role="errors">
      <div for="required">Please enter a valid email address (e.g johndoe@domain.com)</div>
      <div for="invalid">Please enter a valid email address (e.g johndoe@domain.com)</div>
    </ng-container>
  </bw-form-text-input>
  <bw-button *ngIf="origin === 'default'" buttonStyle="modal-primary" [fullWidth]="true" size="medium" (clicked)="submit()" bwTrackAs="component.auth-modal.login-email-submit">Continue</bw-button>
  <bw-button *ngIf="origin !== 'default'" buttonStyle="primary" [fullWidth]="true" size="medium" (clicked)="submit()" bwTrackAs="component.user-details-checkout.login-email-submit">Continue with email</bw-button>
  <ui-box box_space="none" [box_space_top]="origin === 'default' ? 'none' : 'near'">
    <bw-auth-third-party [showSeparator]="showThirdPartySeparate" [isModal]="origin === 'default'" (didSucceed)="onSuccessLogin($event)" (didFail)="onFailedLogin()" (didLoad)="onLoading($event)"></bw-auth-third-party>
  </ui-box>
  <ng-container *ngIf="showUserGuest &amp;&amp; order?.type !== 'subscription'">
    <div class="user-details__separator-horisontal" *ngIf="showSeparator">
      <div class="line">
        <div class="text">Or</div>
      </div>
    </div>
    <bw-button buttonStyle="secondary" bwTrackAs="checkout.userDetails.continueToOrderDetails" (click)="onGuest()" [fullWidth]="true">Continue as guest</bw-button>
  </ng-container>
</div>
<bw-loading-spinner *ngIf="loading" [fullscreen]="false" message="Logging in..."></bw-loading-spinner>