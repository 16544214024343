import { Component, OnInit } from '@angular/core';
import { StateService } from 'Shared/services/state.service';
import { ExperimentsService } from 'Shared/services/experiments.service';
import { knownExperiments as duringBuildExperiments } from 'Environments/known-experiments';
import { ContentService } from 'Shared/services/content.service';
import { BackendService } from 'Shared/services/backend.service';
import { WindowRefService } from 'Shared/services/window.service';
import { ContentSegment } from 'Shared/models/segment-model.service';
import { CookieService, ICookieConsent } from 'Shared/services/third-parties/cookie.service';
import { takeWhile } from 'rxjs/operators';
import { timer } from 'rxjs';
import { LocalStorageService } from 'Shared/services/local-storage.service';
import { FormControl, FormGroup } from '@angular/forms';

class KnownExperiment {
  id?: string;
  name: string;
  variants: number[];
  variant: number;

  constructor(name: string, variants: number[], variant: number = 0) {
    this.name = name;
    this.variants = variants;
    this.variant = variant;
  }
}

/* bw:view-encapsulation */
@Component({
  selector: 'bw-staging-helper',
  templateUrl: './staging-helper.component.html'
})
export class StagingHelperComponent implements OnInit {
  collapsed: boolean = true;
  show: boolean = true;
  showExperiments: boolean = false;
  showPBSDetails: boolean = false;
  showSegments: boolean = false;
  totalExperimentsIn: number = 0;
  knownExperiments: KnownExperiment[] = [];
  currentSegments: ContentSegment[] = [];
  knownSegments: any = [];
  form: FormGroup;
  defaultBackendURL: string;

  public currentAPIUrl: string;

  // by default, all true
  userConsent: ICookieConsent = {
    marketing: true,
    statistics: true,
    preferences: true,
    necessary: true
  };
  // Add here any other subdomain that is added to the cookiebot dashboard as alias
  // Remove before merge to staging
  cookiebotDomainAliases: string[] = [
    'localhost',
    'www.bloomdev.org',
    'fr.bloomdev.org',
    'de.bloomdev.org',
    'bloomon-uk.bloomdev.org',
    'bloomon-de.bloomdev.org',
    'bloomon-dk.bloomdev.org',
    'bloomon-nl.bloomdev.org',
    'bloomon-be.bloomdev.org'
  ];

  constructor(
    private stateService: StateService,
    private experimentsService: ExperimentsService,
    private contentService: ContentService,
    private backend: BackendService,
    private windowRef: WindowRefService,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService
  ) {
    this.knownExperiments = duringBuildExperiments.map((data) => new KnownExperiment(data.name, data.variants, 0));
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  ngOnInit(): void {
    // Fake the cookie consent for preview branches, except when cypress is running
    // Function causes cypress to get stuck in an infinite loop if ran
    if (typeof this.windowRef.nativeWindow.Cypress === 'undefined') {
      this.fakeCookieConsent();
    }

    this.currentAPIUrl = this.backend.server;

    const params = this.stateService.getCurrent().params;
    this.show = !!params.preview || !!params.experiment || !!params.analyticsDebug;
    this.collapsed = !params.preview && !params.analyticsDebug;

    // Experiments
    this.experimentsService.experimentsObj$.subscribe((experimentObj) => {
      this.totalExperimentsIn = 0;
      Object.values(experimentObj).forEach((experiment) => {
        const known = this.knownExperiments.find((exp) => experiment.name === exp.name);
        if (known) {
          known.variant = experiment.variant;
          return;
        }
        const knownExperiment = new KnownExperiment(
          experiment.name,
          [0, 1, 2, 3], // We are guessing the number here
          experiment.variant
        );
        this.knownExperiments.push(knownExperiment);
      });

      this.totalExperimentsIn = this.knownExperiments.filter((e) => e.variant > 0).length;
    });

    // Content Segments
    this.contentService.contentSegmentsDidChange$.subscribe((segments) => {
      this.currentSegments = segments;
    });

    this.buildForm();
  }

  /**
   * Enter a segment
   * @param segment
   */
  enterSegment(segment: ContentSegment): Promise<any> {
    this.contentService.forceContentSegment(segment);
    return this.contentService.refreshSegments().then(() => {
      this.stateService.reload();
      return this.refreshSegmentData();
    });
  }

  /**
   * Exit a segment
   * @param segmentId
   */
  exitSegment(segment: ContentSegment): Promise<any> {
    this.contentService.exitContentSegment(segment);
    return this.contentService.refreshSegments().then(() => {
      this.stateService.reload();
      return this.refreshSegmentData();
    });
  }

  refreshSegmentData(): Promise<any> {
    return this.backend.get(null, '/v2/segments/all', { useUrlAsCache: true }).then((res) => {
      this.knownSegments = res.data.map((r) => ({
        id: parseInt(r.id, 10),
        name: r.attributes.name,
        isActive: this.contentService.contentSegments().find((s) => s.id === parseInt(r.id, 10)),
        excluded_tags: r.attributes.excluded_tags || []
      }));
    });
  }

  /**
   * Basic toggle of the segments list
   */
  toggleSegmentsList(): void {
    this.showSegments = !this.showSegments;
    if (this.showSegments) {
      this.refreshSegmentData();
    }
  }

  /**
   * Refresh the page going into the experiment
   * @param name
   * @param variant
   */
  refreshWithExperiment(name: string, variant: number): void {
    const experiment = this.experimentsService.createExperiment({
      name,
      variant,
      allowOverride: false
    });
    this.experimentsService.addExperiment(experiment);
    this.stateService.reload();
  }

  editInContentful($event): void {
    const contentfulId = this.windowRef.nativeWindow.document.querySelector('.bwContentSplash').getAttribute('contentful-id');
    const spaceId = this.windowRef.nativeWindow.document.querySelector('[contentful-space-id]').getAttribute('contentful-space-id');

    const url = `https://app.contentful.com/spaces/${spaceId}/entries/${contentfulId}`;
    $event.target.setAttribute('target', '_blank');
    $event.target.setAttribute('href', url);
  }

  setApiURL(): void {
    this.currentAPIUrl = this.form.get('newApiURL').value.trim();
    this.localStorageService.set('apiUrl', this.currentAPIUrl);
    window.location.replace(`/?apiUrl=${this.currentAPIUrl}`);
  }

  clearAPIURL(): void {
    this.localStorageService.set('apiUrl');
    this.currentAPIUrl = null;
    window.location.replace('/');
  }

  refresh(): void {
    const current = this.stateService.getCurrent();

    const params = this.stateService.getCurrent().params;
    this.stateService.go(current, params, { reload: true });
  }

  /**
   * Builds the fake Cookiebot object in window
   * Sets the fake cookies
   * Triggers the CookiebotOnConsentReady to be picked up by the cookiebot service
   */
  fakeCookieConsent(): void {
    const isPreviewBranch = this.cookiebotDomainAliases.indexOf(this.windowRef.nativeWindow.location.hostname) < 0;

    if (!isPreviewBranch) {
      return;
    }

    // Cookiebot will load in window, but will do nothing if the preview branch is not added to the dashboard
    this.windowRef.nativeWindow['Cookiebot'] = {};
    this.windowRef.nativeWindow['Cookiebot'].runScripts = () => this.runThirdPartyInlineScripts();
    this.windowRef.nativeWindow['Cookiebot'].renew = () =>
      console.error(
        'Not available for this preview branch',
        'To enable the dialog, add this branch to the cookiebot dashboard and update this.cookiebotDomainAliases'
      );
    this.windowRef.nativeWindow['Cookiebot'].consent = this.userConsent;

    this.setConsentCookie(this.userConsent);
    this.cookieService.cookieConsent$.next(this.userConsent);
    this.cookieService.initExistingConsent();
  }

  /**
   * Sets the consent cookie according to the user preferences
   * Similar to the way cookiebot is building the cookie (except no domain, path ...)
   * Can be used in a later development to toggle a custom consent
   */
  setConsentCookie(userConsent: ICookieConsent): void {
    const cookieParts = [];
    Object.entries(userConsent).forEach(([key, value]) => {
      cookieParts.push(`${key}:${encodeURIComponent(value)}`);
    });
    const newConsentCookie = `CookieConsent={${cookieParts.join(encodeURIComponent(','))}}`;
    this.windowRef.nativeWindow.document.cookie = newConsentCookie;
  }

  /**
   * Triggers the CookiebotOnConsentReady in window to be picked up by the cookiebot service
   */
  dispatchCookieConsentEvent(): void {
    const intervalTimer = timer(0, 200);
    intervalTimer.pipe(takeWhile(() => !this.cookieService.intialised.marketing)).subscribe(() => {
      this.windowRef.nativeWindow.dispatchEvent(new Event('CookiebotOnConsentReady'));
    });
  }

  /**
   * Replacement for cookiebot's run scripts function
   */
  runThirdPartyInlineScripts(): void {
    const cookieConsentScripts = this.windowRef.nativeWindow.document.querySelectorAll('[type="text/plain"]');
    const body = this.windowRef.nativeWindow.document.querySelector('body');
    cookieConsentScripts.forEach((s) => {
      const consent = s.getAttribute('data-cookieconsent');
      if (this.userConsent[consent]) {
        const script = s.cloneNode(true);
        const parent = s.parentElement;
        parent.removeChild(s);
        script.setAttribute('type', 'text/javascript');
        script.defer = false;
        body.appendChild(script);
      }
    });
  }

  /**
   * Build form
   */
  private buildForm(): void {
    this.defaultBackendURL = this.backend.defaultBackendUrl();
    this.form = new FormGroup({
      newApiURL: new FormControl(this.currentAPIUrl ?? '')
    });
  }
}
