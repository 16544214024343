
<ui-box class="bw-confirmation-auth-rewards" box_border_width="primary" box_border_color="secondary">
  <ui-stack stack_gap="near">
    <h5 class="text-heading--functional--secondary">Psst. There are {{loyaltyOrderPoints}} points with your name on them.</h5>
    <p class="text-body--default">And points = perks. Like money-off vouchers, secret sales and more. It’s free to join Bloom & Wild Rewards and you get all this too:</p>
    <ui-stack stack_gap="near" stack_direction="y" stack_distribute="start" stack_collapse_below="tablet" [stack_wrap]="true">
      <ui-box *ngFor="let item of [].constructor(4); let i = index" box_space="none" box_fill_mode="fit">
        <ui-stack stack_gap="tight" stack_direction="x" stack_align="start">
          <ui-box box_background="rewards-tertiary" box_space="tiny" box_fill_mode="fit" box_border_radius="rewards-icon">
            <ui-icon icon_name="tick"></ui-icon>
          </ui-box>
          <p class="text-body--supporting" *ngIf="i === 0">{{loyaltyWelcomePoints}} welcome points</p>
          <p class="text-body--supporting" *ngIf="i === 1">£5 off every 500 points</p>
          <p class="text-body--supporting" *ngIf="i === 2">50% off on your birthday</p>
          <p class="text-body--supporting" *ngIf="i === 3">Easy order tracking</p>
        </ui-stack>
      </ui-box>
      <p class="text-body--supporting text-color--supporting" *ngIf="showTandC">You can <a class="text-link--paragraph" href="/bw-rewards-terms" target="_blank">read our full T&Cs here</a>.</p>
    </ui-stack>
    <ng-content></ng-content>
  </ui-stack>
</ui-box>