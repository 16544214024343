
<nav class="mega-nav-mobile-v2" *ngIf="!activeSection">
  <!-- HEADER SECTION-->
  <div class="mega-nav-mobile-v2__header">
    <div class="mega-nav-mobile-v2__header--title">
      <h6>Shop</h6>
    </div>
    <div class="mega-nav-mobile-v2__header--cta">
      <bw-button [iconOnly]="true" buttonStyle="modal-tertiary" size="small" bwTrackAs="component.nav.burgerMenu.close" aria-label="Close Menu" tabindex="0" (click)="closeBurgerMenu()"><img src="/assets/brand-icons/x.svg" alt="Close Navigation"></bw-button>
    </div>
  </div>
  <!-- MAIN CONTENT SECTION-->
  <div class="mega-nav-mobile-v2__content">
    <ul class="mega-nav-mobile-v2__content--links">
      <li class="mega-nav-mobile-v2__content--link" *ngFor="let link of megaNav;" [attr.url]="link.url">
        <!-- Links with no children (i.e All, Business etc)--><a *ngIf="!link.sections.length &amp;&amp; !link.isFooterLink" [attr.href]="link.url" (click)="navInteractionTracking(link)" tabindex="0" [attr.aria-label]="link.name + '.'"><span [attr.id]="link.name">{{link.name}}
            <!-- New Nav Icon--><img [ngClass]="link.styles" src="/assets/brand-icons/new-label.svg"></span></a>
        <!-- Links with child links (i.e letterbox, handtied etc)--><a *ngIf="link.sections.length" (click)="setActiveSection(link)" (keyup)="handleKeyUp($event, link)" aria-haspopup="true" [attr.aria-expanded]="activeSection &amp;&amp; activeSection.name === link.name" [attr.aria-label]="link.name + '.'" tabindex="0"><span [attr.id]="link.name">{{link.name}}
            <!-- New Nav Icon--><img [ngClass]="link.styles" src="/assets/brand-icons/new-label.svg"></span>
          <bw-icon iconType="chevron-right"></bw-icon></a>
      </li>
    </ul>
  </div>
  <!-- FOOTER SECTION-->
  <div class="mega-nav-mobile-v2__footer">
    <ul class="mega-nav-mobile-v2__footer--links">
      <li *ngFor="let link of megaNav" [attr.url]="link.url"><a *ngIf="!link.sections.length &amp;&amp; link.isFooterLink" [attr.href]="link.url" (click)="navInteractionTracking(link)" tabindex="0" [attr.aria-label]="link.name + '.'"><span [attr.id]="link.name">{{link.name}}</span></a></li>
      <li>
        <!-- Flower Journal--><a [attr.href]="'nav.flowerjournal.link' | siteConfig" (click)="onCancel()" bwTrackAs="component.mega-nav-mobile.flower-journal">Blog</a>
      </li>
      <li>
        <!-- Country Selector-->
        <bw-i18n-selector class="hidden-md hidden-lg" (siteChanged)="ngOnInit()"></bw-i18n-selector>
      </li>
    </ul>
  </div>
</nav>
<!-- SECONDARY NAV-->
<nav class="mega-nav-mobile-v2__secondary-nav" *ngIf="activeSection" (keyup)="handleKeyUp($event, activeSection)">
  <bw-mega-nav-mobile-child [childSectionLinks]="activeSection" (navChildClicked)="onCancel()" (closeSecondaryNav)="onSecondaryNavClose()"></bw-mega-nav-mobile-child>
</nav>