import * as clone from 'clone';
import { Email } from 'Shared/classes/email';
import { Price } from 'Shared/classes/price';
import * as dayjs from 'dayjs';

export const THRESHOLD_PASSWORD_REGISTER = 1000;
export interface Name {
  first: string;
  second: string;
}

export class User {
  public slug: string;
  public token: string;
  public loggedIn: boolean = false;
  public fullName: string;
  public credit: Price;

  public email: Email;
  public password: string;
  public billingAddressId: number;
  public defaultCreditCurrency: string;
  public availableCredit: { GBP: Price; EUR: Price; DKK: Price } = {
    GBP: new Price('GBP', 1, 0),
    EUR: new Price('EUR', 1, 0),
    DKK: new Price('DKK', 1, 0)
  };

  public defaultCardId: number;
  public subscriptionCardIds: number[];
  public postalPreference: string;
  public orderCount: number = 0;
  public previouslyPurchasedProductIds: number[] = [];
  public freeBouquet: number = 0;
  public subscriptionOrderIds: number[] = [];
  public subscriptionOrderCancelledIds?: number[] = [];
  public hasLegacySubscription: boolean;
  public loyaltySchemeMembershipId: number | undefined;
  public profile: {
    dob: dayjs.Dayjs;
  };
  /**
   * Constructor
   * @param email
   * @param token
   */
  constructor(emailAddress?: string, token?: string, slug?: string) {
    this.email = new Email(emailAddress);
    this.token = token;
    this.slug = slug;
  }

  /**
   * Clone the instance
   */
  clone(): User {
    return clone(this);
  }

  /**
   * Set the email on the user
   * @param email
   */
  setEmail(email: Email): void {
    this.email = email;
    this.slug = this.email.slug;
  }

  /**
   * Has the user previously ordered?
   * - Used for tracking purposes
   */
  hasPreviouslyOrdered(): boolean {
    return !!(this.orderCount > 0 || this.email.hasOrdered || false);
  }

  /**
   * Is logged in
   */
  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  /**
   * A *very* basic method to get the users name
   */
  getNameParts(): Name {
    const name = (this.fullName || '').split(' ');
    return {
      first: name.shift(),
      second: name.join(' ') || ''
    };
  }

  hasCredit(): boolean {
    return this.credit && this.credit.price > 0;
  }
}
