import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastrConfig } from 'Base/app/toastr/toastr.config';
import { ToastrMessage, FeedbackToastrMessage } from 'Base/app/toastr/classes/toastr';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { Error } from 'Shared/classes/error';
import { ExperimentsService } from 'Shared/services/experiments.service';

export { ToastrMessage, FeedbackToastrMessage } from 'Base/app/toastr/classes/toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {
  messageQueue: ToastrMessage[] = [];
  messageTimeout: any;
  message$: BehaviorSubject<ToastrMessage | FeedbackToastrMessage | undefined> = new BehaviorSubject(undefined);
  debounceTimeout: any;

  constructor(private analyticsService: AnalyticsService, public experimentService: ExperimentsService) {}

  /**
   * Next toastr
   */
  next(): void {
    this.message$.next(undefined);
    this.show(this.messageQueue.shift());
  }

  /**
   * Trigger error type toastr
   * @param message
   * @param title
   * @param config
   */
  error(message: string, title?: string, config?: ToastrConfig, data?: object): ToastrMessage {
    const error = new Error({
      title,
      message,
      code: undefined,
      kind: undefined
    });
    this.analyticsService.trackInHeap('errorMessageThrown', {
      error,
      ...data
    });
    return this.show(new ToastrMessage({ type: 'error', message, title, config }));
  }

  /**
   * Trigger success type toastr
   * @param message
   * @param title
   * @param config
   */
  success(message: string, title?: string, config?: ToastrConfig): ToastrMessage {
    return this.show(new ToastrMessage({ type: 'success', message, title, config }));
  }

  /**
   * Trigger info type toastr
   * @param message
   * @param title
   * @param config
   */
  info(message: string, title?: string, config?: ToastrConfig): ToastrMessage {
    return this.show(new ToastrMessage({ type: 'info', message, title, config }));
  }

  /**
   * Get the config for the toastr
   * @returns ToastrConfig
   */
  getToastrConfig(): ToastrConfig {
    let toastrConfig = new ToastrConfig();

    toastrConfig = {
      autoDismiss: false,
      position: 'bottomLeft'
    };

    return toastrConfig;
  }

  /**
   * Trigger feedback type toastr
   * @param message
   * @param ...args
   */
  feedback(message: string, ...args): ToastrMessage | FeedbackToastrMessage {
    const config = new ToastrConfig();
    config.timeOut = 8000;
    return this.show(new FeedbackToastrMessage({ type: 'quality_feedback', message, ...args[0] }));
  }

  /**
   * To be used with enhanced error tracking
   * Used for tracking payment error messages, for now
   * @param error
   * @param config
   * @returns
   */
  showError(error: Error, config?: ToastrConfig): ToastrMessage {
    this.analyticsService.trackInHeap('errorMessageThrown', { error });
    return this.show(new ToastrMessage({ type: 'error', message: error.message, title: error.title, config }));
  }

  private debounce(time: number): Promise<void> {
    clearTimeout(this.debounceTimeout);
    return new Promise((resolve): void => {
      this.debounceTimeout = setTimeout((): void => {
        resolve();
      }, time);
    });
  }

  /**
   * Show toastr message
   * @param message
   */
  private show(message: ToastrMessage | FeedbackToastrMessage): ToastrMessage | FeedbackToastrMessage {
    this.debounce(500).then(() => {
      const currentMessage = this.message$.getValue();
      const duplicate = !!(currentMessage && message && currentMessage.message === message.message);

      if (!currentMessage || (duplicate && !message.config.preventDuplicates)) {
        this.message$.next(message);

        return;
      }

      this.messageQueue.push(message);
    });

    return message;
  }
}
