
<div class="footer">
  <div class="footer__container container">
    <div class="footer__top-row">
      <div class="footer__mobile-top-row hidden-md hidden-lg" [ngClass]="{'isStacked': bCorpLogoStyle === 'full-width'}"><img class="footer__logo-mobile" bw-contentful-image="/assets/branded-icons/logo__footer.svg">
        <ng-container *ngIf="bCorpLogoActive">
          <ui-image class="footer__b-corp-logo" image_src="{{bCorpLogoImage}}" image_alt_text="B Corp logo"></ui-image>
        </ng-container>
      </div>
      <div class="footer__company-details">
        <div class="footer__logo hidden-xs hidden-sm"><img bw-contentful-image="/assets/branded-icons/logo__footer.svg"></div>
        <ul class="list-unstyled">
          <li class="footer__contact3">Email us<strong><a class="footer__contact3-link" href="/help" bwTrackAs="footer.contact_3.text">here</a></strong>
          </li>
          <li class="footer__social-icons"><a href="https://instagram.com/bloomandwild" title="Instagram">
              <bw-icon class="social-icon" iconType="instagram"></bw-icon></a><a [attr.href]="'social.facebook.url' | siteConfig" title="Facebook">
              <bw-icon class="social-icon" iconType="facebook"></bw-icon></a><a href="https://twitter.com/bloomandwild" title="Twitter">
              <bw-icon class="social-icon" iconType="twitter"></bw-icon></a>
            <div class="break-column"></div>
            <div class="footer__app-banner">
              <bw-app-store-link context="website-footer" [appStoreLink]="'confirmation.banners.mobile.openAppToSaveLink' | siteConfig"></bw-app-store-link>
            </div>
            <ng-container *ngIf="bCorpLogoActive">
              <ui-image class="footer__b-corp-logo hidden-xs hidden-sm" image_src="{{bCorpLogoImage}}" image_alt_text="B Corp logo"></ui-image>
            </ng-container>
          </li>
        </ul>
      </div>
      <div class="footer__section" *ngFor="let section of footerSections; let indx = index" [ngClass]="{'is-open': openedSection === indx}">
        <div class="footer__section-heading" (click)="toggleOpenSection(indx)">{{ section.sectionName }}
          <bw-icon class="footer__section-chevron visible-xs" iconType="chevron-down"></bw-icon>
        </div>
        <div class="footer__link-list">
          <div class="footer__link" *ngFor="let item of section.items"><a href="{{item.link}}" [attr.target]="item.target || '_self'">{{ item.text }}</a></div>
        </div>
      </div>
    </div>
    <div class="footer__bottom-row">
      <div class="footer__cards d-flex justify-content-left" title="Accepted payment methods">
        <bw-payment-icons group="footer-icons" iconVariation="rounded"></bw-payment-icons>
      </div>
      <div class="footer__small-print">&copy; {{today | localeDate:'YYYY'}} Bloom & Wild Limited. All rights reserved. &nbsp;  &nbsp;<br><a href="/terms-and-privacy" rel="nofollow" bwTrackAs="page.contact_us.work_with_us.terms_and_conditions">Terms & Conditions</a></div>
    </div>
    <div class="footer__cookie">
      <bw-button buttonStyle="text-link" href="/cookies-policy" bwTrackAs="page.contact_us.work_with_us.cookie_policy">Cookie statement</bw-button>
      <bw-button buttonStyle="text-link" (clicked)="renewCookieConsent()" bwTrackAs="page.contact_us.work_with_us.cookie_preferences">Cookie preferences</bw-button>
    </div>
  </div>
</div>