import { Injectable } from '@angular/core';
import { APIJSONRequest, APISerialisedJSONResponse, BackendService } from 'Shared/services/backend.service';
import { FacebookService } from 'Shared/services/facebook.service';
import { THRESHOLD_PASSWORD_REGISTER, User } from 'Shared/classes/user';
import { Price } from 'Shared/classes/price';
import { WindowRefService } from 'Shared/services/window.service';
import { Order } from 'Shared/classes/order';
import { Email } from 'Shared/services/email.service';
import { CountryService } from 'Shared/services/country.service';
import * as dayjs from 'dayjs';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { BugsnagService } from 'Shared/services/third-parties/bugsnag.service';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const sha1 = require('sha1');

@Injectable({
  providedIn: 'root'
})
export class UserModelService {
  private fingerprint: string;

  constructor(
    private http: HttpClient,
    private backend: BackendService,
    private facebookService: FacebookService,
    private windowRefService: WindowRefService,
    private countryService: CountryService,
    private bugsnagService: BugsnagService
  ) {
    // eslint-disable-next-line no-useless-escape
    this.fingerprint = JSON.parse(this.windowRefService.nativeWindow['bwFingerprint'] || '""').replace(/\"/gim, '');
  }

  /**
   * To payload
   * @param {User} user
   * @returns {APIJSONRequest<'/v1/users'>}
   */
  public static toPayload(user: User): APIJSONRequest<'/v1/users'> {
    return {
      email: user.email?.address.length ? user.email.address : undefined,
      full_name: user.fullName ?? undefined,
      password: user.password ?? undefined,
      billing_address_id: user.billingAddressId ?? undefined,
      default_credit_card_id: user.defaultCardId ?? undefined,
      postal_preference: user.postalPreference ?? undefined
    };
  }

  /**
   * From payload auth user tokens
   * @param {APISerialisedJSONResponse<'/v2/user_tokens'>} res
   * @returns {string}
   */
  public static fromPayloadUserToken(res: APISerialisedJSONResponse<'/v2/user_tokens'>['data']): string {
    return res.attributes ? res.attributes.token : undefined;
  }

  /**
   * From payload
   * @param res
   */
  // eslint-disable-next-line complexity
  public static fromPayload(res: APISerialisedJSONResponse<'/v1/users'>['user']): User {
    if (!res) {
      return new User();
    }
    const user = new User(res.email, res.authentication_token);
    user.slug = res.slug;
    user.fullName = res.full_name;
    if (res.customer_profile?.date_of_birth) {
      user.profile = {
        dob: dayjs(res.customer_profile.date_of_birth) ?? undefined
      };
    }
    // Balance is in pounds, not pennies
    const balance = (res.balance ?? 0) * 100;
    user.credit = new Price(res.balance_currency, 1, balance);
    user.defaultCreditCurrency = res.balance_currency;

    const eurCredit = res.balance_pennies ? res.balance_pennies.eur ?? 0 : 0;
    const gbpCredit = res.balance_pennies ? res.balance_pennies.gbp ?? 0 : 0;
    const dkkCredit = res.balance_pennies ? res.balance_pennies.dkk ?? 0 : 0;

    user.availableCredit = {
      EUR: new Price('EUR', 1, eurCredit),
      GBP: new Price('GBP', 1, gbpCredit),
      DKK: new Price('DKK', 1, dkkCredit)
    };

    user.billingAddressId = res.billing_address_id;
    user.defaultCardId = res.default_credit_card_id;
    user.subscriptionCardIds = res.subscriptions_credit_cards_ids;
    user.postalPreference = res.postal_preference;
    user.orderCount = res.order_count;
    user.email.preference = res.email_preference;
    user.email.sha256 = res.email_sha256;
    user.email.occasionPreferences = {
      mothersDay: res.content_preferences.mothers_day,
      fathersDay: res.content_preferences.fathers_day,
      valentinesDay: res.content_preferences.valentines_day,
      grandparentsDay: res.content_preferences.grandparents_day,
      grandmothersDay: res.content_preferences.grandmothers_day,
      discountPromotions: res.content_preferences.discount_promotions,
      holidaySeason: res.content_preferences.holiday_season
    };
    user.email.surveyPreference = res.content_preferences.survey;
    user.previouslyPurchasedProductIds = res.ordered_sku_ids ?? [];
    user.freeBouquet = res.free_bouquet_count ?? 0;
    user.subscriptionOrderIds = res.subscription_order_ids ?? [];
    user.subscriptionOrderCancelledIds = res.subscription_order_cancelled_ids ?? [];
    user.hasLegacySubscription = res.has_legacy_subscription;
    user.loyaltySchemeMembershipId = res.loyalty_scheme_membership_id ?? undefined;
    return user;
  }

  /**
   * Validate Password is strong
   * @param password
   * @returns
   */
  public validateStrongPassword(password: string): Promise<boolean | void> {
    if (password) {
      // SHA1 hash the password
      const code = sha1(password);

      // Create Sufix by getting 5 caracters from SHA1 hash
      // Uppercase prefix to be used for pwnedpasswords.com search
      const prefix = code.toString().slice(0, 5).toUpperCase();

      // Create Sufix by removing first 5 characters from SHA1 hash
      // Uppercase sufix to be used for pwnedpasswords.com because returned result will be Uppercased
      const suffix = code.toString().slice(5).toUpperCase();

      // Call pwnedpasswords with the Prefix
      // Response Type has to be Text.
      return lastValueFrom(this.http.get('https://api.pwnedpasswords.com/range/' + prefix, { responseType: 'text' }))
        .then((res: string): boolean => {
          // Create a list of SHA1 from response
          // Response example
          // Response type: text
          // response body:
          //           0047F897FB151B92817E6A08C30E06A41A0:20
          //           006BB537759E3C94E0E6C6FBE3E3FCDEDCB:1
          //           00A26BC204C1A5E7FD2F194FF507EDD012F:1
          //           ....
          const listOfSha1 = res.split('\r\n');
          // Find in SHA1 response list if SUFFIX is included
          const range = listOfSha1.filter((str: string): boolean => str.toString().includes(suffix));
          // Extract the threshold if pastword range been found
          // <SUFFIX>:<THRESHOLD>
          //       Range/Suffix                 :Threshold
          //        ||                           ||
          //        \/                           \/
          // 0047F897FB151B92817E6A08C30E06A41A0:20
          const threshold = range.length ? range[0].split(':')[1] : 0;
          // Check if Password is safe to be accepted
          return Number(threshold) <= THRESHOLD_PASSWORD_REGISTER;
        })
        .catch((error): boolean => {
          this.bugsnagService.logEvent(error);
          Promise.reject(error);
          return true; // Return true to be a non-blocker for register users in checkout
        });
    }
    return Promise.reject();
  }

  /**
   * Get fingerprint
   */
  public getFingerprint(): string {
    return this.fingerprint;
  }

  /**
   * Gets the user
   * @param {User} user
   * @param {string} token
   * @returns {Promise<User>}
   */
  public get(user: User, token?: string): Promise<User> {
    if (token) {
      user.token = token;
    }
    const country = this.countryService.forShipping;
    return this.backend
      .get(user, '/v1/users', {
        params: {
          shipping_country_id: country.id
        }
      })
      .then((res): User => UserModelService.fromPayload(res.user));
  }

  /**
   * Update a user
   * @param {User} user
   * @param {User} updatedUser
   * @returns {Promise<User>}
   */
  public update(user: User, updatedUser: User): Promise<User> {
    const country = this.countryService.forShipping;
    return this.backend
      .put(user, '/v1/users', UserModelService.toPayload(updatedUser), {
        params: {
          shipping_country_id: country.id
        }
      })
      .then((res): User => UserModelService.fromPayload(res.user));
  }

  /**
   * Add Date of Birth
   * @param {User} user
   * @param {User} updatedUser
   * @returns {Promise<User>}
   */
  public addDoB(user: User, dob: dayjs.Dayjs): Promise<User> {
    const country = this.countryService.forShipping;
    return this.backend
      .put(
        user,
        '/v1/users',
        {
          customer_profile: {
            date_of_birth: dob.format('YYYY-MM-DD')
          }
        },
        {
          params: {
            shipping_country_id: country.id
          }
        }
      )
      .then((res): User => UserModelService.fromPayload(res.user));
  }

  /**
   * Login via facebook
   * @return {Promise<User>}
   */
  public facebookLogin(): Promise<User> {
    return this.facebookService.login().then((authResponse): Promise<User> => this.loginWithFacebookToken(authResponse.accessToken));
  }

  /**
   * Log the user in
   * @param {string} email
   * @param {string} password
   * @returns {Promise<User>}
   */
  public login(email: string, password: string): Promise<User> {
    const country = this.countryService.forShipping;
    return this.backend
      .post(
        null,
        '/v1/users/sign_in',
        {
          email,
          password
        },
        {
          params: {
            shipping_country_id: country.id
          }
        }
      )
      .then((res): User => UserModelService.fromPayload(res.user));
  }

  /**
   * Register a user
   * @param {User} user
   * @returns {Promise<User>}
   */
  public register(user: User): Promise<User> {
    const country = this.countryService.forShipping;
    return this.backend
      .post(null, '/v1/users', UserModelService.toPayload(user), {
        params: {
          shipping_country_id: country.id
        }
      })
      .then((res): User => UserModelService.fromPayload(res.user));
  }

  /**
   * Request password reset
   * @param {User} user
   * @returns {Promise<User>}
   */
  public requestPasswordReset(user: User): Promise<User> {
    const country = this.countryService.forShipping;
    return this.backend.post(
      null,
      '/v1/users/request_password_reset',
      {
        email: user.email.address
      },
      {
        params: {
          shipping_country_id: country.id
        }
      }
    );
  }

  /**
   * Request activate account
   * @param {User} user
   * @returns {Promise<User>}
   */
  requestActivateAccount(user: User): Promise<User> {
    const country = this.countryService.forShipping;
    return this.backend.post(
      null,
      '/v1/users/request_create_account',
      {
        email: user.email.address
      },
      {
        params: {
          shipping_country_id: country.id
        }
      }
    );
  }

  /**
   * Request delete user
   * @param {User} user
   * @returns {Promise<User>}
   */
  public requestDelete(user: User): Promise<User> {
    const country = this.countryService.forShipping;

    return this.backend
      .put(
        user,
        '/v1/users',
        {
          marked_for_restriction_at: dayjs().format()
        },
        {
          params: {
            shipping_country_id: country.id
          }
        }
      )
      .then((res): User => UserModelService.fromPayload(res.user));
  }

  /**
   * Reset the user's password given a token
   * @param {string} token
   * @param {string} password
   * @returns {Promise<User>}
   */
  public resetPassword(token: string, password: string): Promise<User> {
    const country = this.countryService.forShipping;
    return this.backend
      .post(
        null,
        '/v1/users/reset_password',
        {
          password,
          password_confirmation: password,
          reset_password_token: token
        },
        {
          params: {
            shipping_country_id: country.id
          }
        }
      )
      .then((res): User => UserModelService.fromPayload(res.user));
  }

  /**
   * Log the user out
   * @param {User} user
   * @returns {Promise<any>}
   */
  public logout(user: User): Promise<void> {
    const country = this.countryService.forShipping;
    return this.backend.delete(user, '/v1/users/sign_out', {
      params: {
        shipping_country_id: country.id
      }
    });
  }

  /**
   * Request order tracking token
   * @param {Order} order
   * @param {Email} email
   * @param {kind} postcode
   * @param {'tracking' | 'quality'} kind
   * @returns {Promise<string>}
   */
  public requestOrderTrackingToken(
    order: Order,
    email?: Email,
    postcode?: string,
    kind: 'tracking' | 'quality' = 'tracking'
  ): Promise<string> {
    const country = this.countryService.forShipping;
    return this.backend
      .post(
        null,
        '/v2/user_tokens',
        {
          data: {
            type: 'user_tokens',
            attributes: {
              user_email: kind === 'tracking' ? email?.address : undefined,
              postcode: postcode ?? undefined,
              subject_id: order ? order.id : undefined,
              subject_type: 'Order',
              kind: `order_${kind}`
            }
          }
        },
        {
          params: {
            shipping_country_id: country.id
          }
        }
      )
      .then((res): string => UserModelService.fromPayloadUserToken(res.data));
  }

  /**
   * Validate google login via credential
   * @param {string} credential
   * @returns {Promise<User>}
   */
  public googleLogin(credential: string): Promise<User> {
    return this.backend
      .post(null, '/v1/users/google', { access_token: credential })
      .then((res): User => UserModelService.fromPayload(res.user));
  }

  /**
   * Validate unusal sign in
   * TODO: add endpoint typings
   * @param {string} deviceIdentifier
   * @param {string} token
   * @returns {Promise<any>}
   */
  public validateUnusualSignIn(deviceIdentifier: string, token: string): Promise<void> {
    return this.backend.get(null, '/v1/validate_unusual_sign_in', {
      params: { device_identifier: deviceIdentifier, token }
    });
  }

  /**
   * Login with Facebook Token
   * @param {string} accessToken
   * @return {Promise<User>}
   */
  private loginWithFacebookToken(accessToken: string): Promise<User> {
    const country = this.countryService.forShipping;
    return this.backend
      .post(
        null,
        '/v1/users/facebook',
        { access_token: accessToken },
        {
          params: {
            shipping_country_id: country.id
          }
        }
      )
      .then((res): User => UserModelService.fromPayload(res.user));
  }
}
