import {
  Component,
  AfterViewInit,
  OnDestroy,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { Config, ConfigService } from 'Shared/services/config.service';
import { CountryService } from 'Shared/services/country.service';
import { MentionMeService } from 'Shared/services/third-parties/mention-me.service';
import {
  MentionMeParameters,
  ImplementationTypes,
  IntegrationType
} from 'Shared/classes/mention-me';

/**
 * For reference, situation is more defined now to include brand id, site country, client type, location
 * example: 1gbweco
 *
 * locations:
 * co - confirmation page
 * cp - my credit page
 * sp - share page
 */
@Component({
  selector: 'bw-mention-me-iframe',
  templateUrl: './mention-me-iframe.component.html',
  styleUrls: ['./mention-me-iframe.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MentionMeIframeComponent implements AfterViewInit, OnDestroy {
  @Input() situation: string;
  @Input() implementationType: ImplementationTypes = 'banner';
  @Input() integrationType: IntegrationType = 'referreroffer';
  @Input() segment: string;
  @Input() parameters: MentionMeParameters;
  @Input() shippingCountryId?: number = 0;
  @Input() variant: number = 0;

  constructor(
    private domUtilsService: DomUtilsService,
    private configService: ConfigService,
    private countryService: CountryService,
    private mentionMeService: MentionMeService
  ) { }

  /**
   * Construct unique situation property to be passed when MM script loaded
   * @param brandIdNumber
   * @param country
   * @returns string
   */
  constructSituation(brandIdNumber: number, country: string): string {
    return brandIdNumber + country + 'we' + this.situation;
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.reset();

    const config: Config = this.configService.getConfig();
    const paramArr: string[] = [];

    if (this.parameters) {
      Object.entries(this.parameters).forEach(([k, v]): void => {
        if (v) {
          paramArr.push(`${k}=${v}`);
        }
      });
    }

    if (this.segment) {
      paramArr.push(`segment=${this.segment}`);
    }
    const params = paramArr.length ? `&${paramArr.join('&')}` : '';

    const locale: string =
      this.shippingCountryId !== 0
        ? this.mentionMeService.getCampaignLocaleByShippingCountry(this.shippingCountryId)
        : this.countryService.siteConfigValue('locale.mentionme');

    const situationValue: string = this.constructSituation(config.brandIdNumber, config.country);
    // prettier-ignore
    const url = ''.concat(
      `${config.mentionMeUrl}${this.integrationType}/${config.mentionMeId}`,
      `?implementation=${this.implementationType}`,
      `&situation=${situationValue}`,
      `&locale=${locale}`,
      `&variant=${this.variant ?? 0}`,
      `${params}`
    );
    this.mentionMeService.loadScript(url, 'mentionMeIframe');
  }

  /**
   * Remove it once detroyed - stops tracking
   */
  ngOnDestroy(): void {
    this.reset();
  }

  private reset(): void {
    this.domUtilsService.removeElemWithId('mentionMeIframe');
    window['MentionMeFiredTags'] = undefined;
    window['MentionMe'] = undefined;
  }
}
