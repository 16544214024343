import { Injectable } from '@angular/core';
import { BackendService } from 'Shared/services/backend.service';
import { UserService, User } from 'Shared/services/user.service';
import { Country } from 'Shared/classes/country';
import { LocationService } from 'Shared/services/location.service';
import { ReturningCustomerService } from 'Shared/services/returning-customer.service';
import { NewsletterStatusService } from 'Shared/services/newsletter-status.service';
import { StateService } from 'Shared/services/state.service';
import { HttpClient } from '@angular/common/http';
import { BoostedProductExperiment } from 'Shared/classes/boosted-product-experiment';
import { ConfigService } from 'Shared/services/config.service';
import { lastValueFrom } from 'rxjs';

export interface CarouselSegment {
  type: string;
  segmentId: number;
  value?: string;
  order: {
    productId: number;
    weight: number;
  }[];
}

export interface Segment {
  segmentId: number;
  carouselOrder: CarouselSegment[];
  recommendedProducts: RecommendedProducts[];
}

export class RecommendedProducts {
  experimentName: string;
  experimentVariant: string;
  weight: number;
  productIds: number[];
  recommendationVariants: Record<string, number[]>;
}

export class ContentSegment {
  id: number;
  excluded_tags?: string[];

  constructor(id?: number) {
    this.id = id;
  }
}

type VariantContentType = {
  variant: number;
  skus: number[];
};

type CarouselContentType = {
  type: string;
  value: string;
  segment_ids: number[];
};

@Injectable({
  providedIn: 'root'
})
export class SegmentModelService {
  boostedProductPromise: Promise<any>;
  forcedContentSegments: ContentSegment[] = [];

  constructor(
    private http: HttpClient,
    private backend: BackendService,
    private userService: UserService,
    private locationService: LocationService,
    private returningCustomerService: ReturningCustomerService,
    private newsletterStatusService: NewsletterStatusService,
    private stateService: StateService,
    private configService: ConfigService
  ) {}

  /**
   * From the payload
   * @param res
   */
  static fromPayload(res: any): Segment {
    // So this segmentation endpoint is a little odd

    // Firstly, we have our 'base' carousel - this applies globally
    // Then we have our badly named 'subnav' carousels, which are actually for 'tagOnly' carousels
    // In the future, we might have other crazy types to add here, but for now. TagOnly it is.
    const segments = res.data?.attributes.subnavs.slice(); // Clone
    segments?.push({
      type: 'base',
      segmentId: parseInt(res.data.id, 10),
      carousel_skus: res.data.attributes.carousel_skus
    });

    const carouselSegments = segments?.map(
      (s: {
        type: string;
        tag: string;
        carousel_skus: unknown[];
      }): { type: string; segmentId: number; value: string; order: { productId: number; weight: number }[] } => ({
        type: s.type || 'tagOnly',
        segmentId: parseInt(res.data.id, 10),
        value: s.tag,
        order: s.carousel_skus
          .map((sku: { sku_id: number; weight: number }): { productId: number; weight: number } => ({
            productId: sku.sku_id,
            weight: sku.weight
          }))
          .sort((a: { weight: number }, b: { weight: number }): number => a.weight - b.weight)
      })
    );

    const recommendations = res.data?.attributes.sku_recommendations.slice(); // Clone

    const recommendedProductsExpanded = recommendations?.map(
      (r: {
        experiment_name: string;
        variant: number;
        experiment_weight: number;
        sku_ids: number;
      }): {
        experimentName: string;
        experimentVariant: number;
        weight: number;
        productIds: number;
        recommendationVariants: unknown;
      } => ({
        experimentName: r.experiment_name || '',
        experimentVariant: r.variant,
        weight: r.experiment_weight || 0,
        productIds: r.sku_ids,
        recommendationVariants: {}
      })
    );

    const recommendedProducts: RecommendedProducts[] = [];

    recommendedProductsExpanded.forEach((item: RecommendedProducts): void => {
      const existing = recommendedProducts.filter((r): boolean => r.experimentName === item.experimentName);

      if (existing.length) {
        const existingIndex = recommendedProducts.indexOf(existing[0]);
        recommendedProducts[existingIndex].recommendationVariants[item.experimentVariant] = item.productIds;
      } else {
        item.recommendationVariants[item.experimentVariant] = item.productIds;
        recommendedProducts.push(item);
      }
    });

    return {
      recommendedProducts,
      segmentId: parseInt(res.data.id, 10),
      carouselOrder: carouselSegments
    };
  }

  /**
   * From the content Segment payload
   * @param res
   */
  static fromContentPayload(res: any): ContentSegment[] {
    return res && res.data && res.data.length
      ? res.data.map((d: { id: string; attributes: { excluded_tags: string[] } }): ContentSegment => {
        const contentSegment = new ContentSegment();
        contentSegment.id = parseInt(d.id, 10);
        contentSegment.excluded_tags = d.attributes.excluded_tags;
        return contentSegment;
      })
      : [];
  }

  /**
   * 'Fake' the segments based on stuff the frontend knows, eventually this'll be moved to the backend
   * These map on to the 'fake' ones here - https://admin.bloomandwild.com/segments
   */
  public getFrontendSegments(): ContentSegment[] {
    const segments = [];
    const user = this.userService.getUser();
    // Signed up - https://admin.bloomandwild.com/segments/61
    if (user && this.newsletterStatusService.checkIfSignedUp(user)) {
      const segment = new ContentSegment(61);
      segment.excluded_tags = [];
      segments?.push(segment);
    }
    // Returning visitor - https://admin.bloomandwild.com/segments/62
    if (this.returningCustomerService.isCustomerReturning()) {
      const segment = new ContentSegment(62);
      segment.excluded_tags = [];
      segments?.push(segment);
    }
    // Logged in - https://admin.bloomandwild.com/segments/63
    if (user && user.isLoggedIn()) {
      const segment = new ContentSegment(63);
      segment.excluded_tags = [];
      segments?.push(segment);
    }
    // Discount in URL - https://admin.bloomandwild.com/segments/64
    const params = this.locationService.getCurrentParams();
    if (params && params.discountCode) {
      const segment = new ContentSegment(64);
      segment.excluded_tags = [];
      segments?.push(segment);
    }
    return segments;
  }

  /**
   * Get the segments
   * @param country
   */
  getContentSegments(getFreshData: boolean = false): Promise<ContentSegment[]> {
    const user = this.userService.getUser();

    const forcedSegments = this.forcedContentSegments || [];
    const frontendSegments = this.getFrontendSegments();
    const segments = [].concat(forcedSegments, frontendSegments);

    return Promise.race([
      this.waitFor(500, segments),
      this.backend
        .get(user, '/v2/segments/content_segments', {
          useUrlAsCache: true,
          clearCache: getFreshData,
          params: {
            user_slug: this.stateService.getInitial().params.slug
          }
        })
        .then((r): ContentSegment[] => {
          const backendSegments = SegmentModelService.fromContentPayload(r);
          return [].concat(segments, backendSegments);
        })
    ]).catch((): ContentSegment[] => segments);
  }

  /**
   * Force the content segment
   * @param segment
   */
  forceContentSegment(segment: ContentSegment): void {
    this.forcedContentSegments.push(segment);
  }

  /**
   * Exit a content segment
   * @param segment
   */
  exitContentSegment(segment: ContentSegment): void {
    this.forcedContentSegments = this.forcedContentSegments.filter((s): boolean => s.id !== segment.id);
  }

  /**
   * Get the segments
   * @param country
   */
  getSegments(country: Country, user: User): Promise<Segment> {
    return this.backend
      .get(user, '/v2/segments', {
        useUrlAsCache: true,
        params: {
          'filter[shipping_country_id]': country.id,
          user_slug: this.stateService.getInitial().params.slug
        }
      })
      .then((res): Segment => SegmentModelService.fromPayload(res));
  }

  /**
   * From a boosted product experiment
   * @param res
   */
  fromBoostedProductExperimentPayload(
    res: {
      variants: VariantContentType[];
      carousels: CarouselContentType[];
      experiment: string;
    }[]
  ): BoostedProductExperiment[] {
    const data = [];
    res.forEach((r: { variants: VariantContentType[]; carousels: CarouselContentType[]; experiment: string }): void => {
      if (r.variants && r.variants.length) {
        const experiment = new BoostedProductExperiment();
        experiment.carousels = r.carousels;
        experiment.variants = r.variants;
        experiment.experiment = r.experiment;
        data.push(experiment);
      }
    });
    return data;
  }

  /**
   * Get Promoted Products
   * TOTHINK: This one day might replace the "Segments" we have, hence I added it here
   * but it could be it's own model/service in the future
   */
  getBoostedProductExperiments(country: Country): Promise<BoostedProductExperiment[]> {
    const carouselFile =
      this.configService.getConfig().carouselFile || 'https://content.bloomandwild.com/carousel_automation/carousel.json';

    // Basic cache
    this.boostedProductPromise = this.boostedProductPromise || lastValueFrom(this.http.get(carouselFile));

    return this.boostedProductPromise.then((r): BoostedProductExperiment[] => {
      const res = r;
      const data = (res.carousel_boosted_skus && res.carousel_boosted_skus[country.id]) || [];
      return this.fromBoostedProductExperimentPayload(data);
    });
  }

  /**
   * Wait for x ms, and return back some defaults
   * @param ms
   * @param defaults
   */
  private waitFor(ms: number, defaults: ContentSegment[]): Promise<ContentSegment[]> {
    return new Promise((resolve): void => {
      setTimeout((): void => {
        resolve(defaults);
      }, ms);
    });
  }
}
