import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Name, User } from 'Shared/classes/user';
import { Config, ConfigService } from 'Shared/services/config.service';
import { CountryService } from 'Shared/services/country.service';
import { DomUtilsService } from 'Shared/utils/dom-utils.service';
import { lastValueFrom } from 'rxjs';

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
enum UKCampaign {
  en_GB = 'en_GB',
  en_IE = 'en_GB',
  de_DE = 'en_GB',
  de_AT = 'en_GB',
  en_DE = 'en_GB',
  en_AT = 'en_GB'
}
enum IECampaign {
  en_GB = 'en_IE',
  en_IE = 'en_IE',
  de_DE = 'en_IE',
  de_AT = 'en_IE',
  en_DE = 'en_IE',
  en_AT = 'en_IE'
}

enum DECampaign {
  en_GB = 'de_DE',
  en_IE = 'de_DE',
  de_DE = 'de_DE',
  de_AT = 'de_DE',
  en_DE = 'de_DE',
  en_AT = 'de_DE'
}

enum ATCampaign {
  en_GB = 'en_AT',
  en_IE = 'en_AT',
  de_DE = 'de_AT',
  en_DE = 'en_AT',
  de_AT = 'de_AT',
  en_AT = 'en_AT'
}

@Injectable({
  providedIn: 'root'
})
export class MentionMeService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private countryService: CountryService,
    private domUtilService: DomUtilsService
  ) {}

  /**
   * Loads the mention me script
   * @param url
   * @param type
   */
  loadScript(url: string, type: string): Promise<void> {
    return this.domUtilService.loadScript(url, type);
  }

  /**
   * Get the URL to show the webview for the referrer page
   * @param user
   */
  getReferrerWebViewURL(user: User): Promise<URL> {
    const config: Config = this.configService.getConfig();
    const nameParts: Name = user.getNameParts();

    const locale: string = this.countryService.siteConfigValue('locale.mentionme');

    return lastValueFrom(
      this.http.post(`${config.mentionMeAPI}entry-point/v1/referrer`, {
        customer: {
          emailAddress: user.email.address,
          firstname: nameParts.first,
          surname: nameParts.second,
          uniqueIdentifier: user.slug
        },
        request: {
          partnerCode: config.mentionMeId,
          situation: 'confirmation',
          localeCode: locale
        }
      })
    ).then((r: { url: URL }): URL | Promise<URL> => (r && r.url ? r.url : Promise.reject()));
  }

  /**
   * Return the campaigsn locale for where the order is sent
   * @param shippingCountryId
   * @returns
   */
  getCampaignLocaleByShippingCountry(shippingCountryId: number): string {
    const brand: number = this.configService.getConfig().brandIdNumber;

    let campaignLocale: string = null;
    const locale: string = this.countryService.siteConfigValue('locale.mentionme');
    if (brand === 1) {
      switch (shippingCountryId) {
        case 1:
          campaignLocale = UKCampaign[locale];
          break;
        case 2:
          campaignLocale = IECampaign[locale];
          break;
        case 6:
          campaignLocale = DECampaign[locale];
          break;
        case 7:
          campaignLocale = ATCampaign[locale];
          break;
        default:
          campaignLocale = locale;
      }
    } else {
      campaignLocale = locale;
    }
    return campaignLocale;
  }
}
