import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { StateService } from 'Shared/services/state.service';
import { UserService } from 'Shared/services/user.service';
import { AuthModalComponent } from 'Shared/components/modals/auth-modal/auth-modal.component';
import { ModalService } from 'Shared/services/modal.service';
import { t } from 'Shared/utils/translations';
import { ExperimentsService } from 'Shared/services/experiments.service';

@Injectable()
export class HasLoggedIn implements CanActivate {
  constructor(
    private userService: UserService,
    private modalService: ModalService,
    public experimentService: ExperimentsService,
    private stateService: StateService
  ) {}
  /**
   * Can activate
   */
  canActivate(): Promise<boolean> {
    const user = this.userService.getUser();

    if (user.loggedIn) {
      return Promise.resolve(true);
    }
    return this.userService
      .authenticate()
      .catch((): Promise<{ email?: string }> => this.showLoginModal())
      .then((): boolean => true)
      .catch((): boolean => {
        this.stateService.go('homepage');
        return false;
      });
  }

  /**
   * Show the login modal
   */
  showLoginModal(): Promise<{ email?: string }> {
    return this.modalService.show(AuthModalComponent, {
      initialState: {
        title: t('js.guard.has-logged-in.title'),
        origin: 'account',
        fullOrigin: 'webAccountLaunchModal'
      },
      ignoreBackdropClick: true,
      class: 'modal-xs'
    });
  }
}
