import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { Experiment, ExperimentOptions } from 'Shared/classes/experiment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { StateService } from 'Shared/services/state.service';
import { ConfigService } from 'Shared/services/config.service';
import { BackendService } from 'Shared/services/backend.service';
import { GtmService } from 'Shared/services/third-parties/gtm.service';
import { HeapService } from 'Shared/services/third-parties/heap.service';
import { GtagServiceGA4 } from './third-parties/gtag-ga4.service';

/***
 * There are a few ways that experiments can be added:
 *
 *  - via Optimizely anytime a decide() method is called (this is the main way)
 *  - Via a Querystring ?experiment=x&variant=1 on app load
 *  - Via `pushExperiment(name,variant,id)` in console (name & id are both optional and can be undefined)
 *
 * The config (which maps IDs and Names) isn't available till after the app loads, so we need to run
 * attemptToCreateAndAddExperimentsFromWindow on service load and also after the config has been retrieved
 *
 */
@Injectable({
  providedIn: 'root'
})
export class ExperimentsService {
  experimentsObj$: BehaviorSubject<{
    [key: string]: Experiment;
  }> = new BehaviorSubject({});

  debug: boolean = false;
  window: any;

  constructor(
    private windowRef: WindowRefService,
    private analyticsService: AnalyticsService,
    private stateService: StateService,
    private configService: ConfigService,
    private backendService: BackendService,
    private gtmService: GtmService,
    private heapService: HeapService,
    private gtagServiceGA4: GtagServiceGA4
  ) {
    this.window = this.windowRef.nativeWindow;
    this.debug = this.window.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Temporary roll out for certain sites, but still under experiment for others
   * @param sites
   * @param name
   * @param variant
   */
  public isCountryOrExperiment(sites: string[], name: string, variant: number): boolean {
    const inExperiment = this.isActive(name, variant);

    return inExperiment || sites.indexOf(this.configService.getConfig().site) > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<experiments>', ...args);
    }
  }

  /**
   * Add rolled out experiments
   * @param toRollOut
   */
  setRolledOutExperiments(toRollOut: { [key: string]: number }): void {
    // First, we need to reset any experiments that are already rolled out
    const current = this.experimentsObj$.getValue() || {};

    const initialSet = Object.keys(current).reduce((acc, key): {} => {
      if (!current[key].isRolledOut) {
        acc[key] = current[key];
      }
      return acc;
    }, {});
    this.experimentsObj$.next(initialSet);

    Object.entries(toRollOut).map(([key, expVariant]): void => {
      const experiment = this.createExperiment({
        name: key,
        variant: expVariant
      });
      experiment.isRolledOut = true;
      this.addExperiment(experiment);
    });
  }

  /**
   * Create an experiment from either the id or the name
   * @param variant
   * @param name
   * @param id
   */
  createExperiment(opt: ExperimentOptions): Experiment {
    const config = this.configService.getConfig();
    let experimentName = opt.name;

    // No name, but we have an id - attempt to find from config
    if (!experimentName && opt.id) {
      const knownExperiment = Object.entries(config).find((obj): boolean => opt.id === obj[1]);
      experimentName = knownExperiment ? knownExperiment[0].split(':')[2] : undefined;
    }

    // If we still don't have a name for the experiment - looks like we won't be using it then
    if (!experimentName || typeof opt.variant === 'undefined' || opt.variant < 0) {
      return undefined;
    }

    return new Experiment({
      name: experimentName,
      id: opt.id || config[`experiment:${config.site}:${experimentName}`],
      variant: opt.variant,
      allowOverride: opt.allowOverride
    });
  }

  /**
   * Add an experiment to our currently running list
   * @param experiment
   */
  addExperiment(experiment: Experiment): Experiment | void {
    // If the variant is incorrect
    if (!experiment || typeof experiment.variant === 'undefined' || experiment.variant < 0) {
      this.log(`experiment had invalid variant, cancel adding`);
      return;
    }

    // Check if we can over-ride a currently running experiment
    const currentlyRunning = this.experimentsObj$.getValue() || {};
    const current = currentlyRunning[experiment.name];
    if (current && !current.allowOverride && experiment.allowOverride) {
      this.log(
        `experiment (${experiment.name}) (Trying to replace${current.variant} with ${experiment.variant}) had could not override, cancel adding`
      );
      return;
    }

    const attributeName = `experiment-${(experiment.name || '').toLowerCase()}`;
    this.windowRef.nativeWindow.document.body.setAttribute(attributeName, `${experiment.variant}`);

    currentlyRunning[experiment.name] = experiment;
    this.experimentsObj$.next(currentlyRunning);

    // If the experiment is already rolled out - we don't want to track it
    if (!experiment.isRolledOut) {
      const trackId = experiment.id || 'NOEXPERIMENTID';
      const trackString = `experiment:${trackId}:${experiment.name}`;

      this.analyticsService.setDimension(trackString, experiment.variant);
      const currentlyRunningArray = Object.values(currentlyRunning);
      this.backendService.setExperimentsRunning(currentlyRunningArray);
      this.heapService.setExperimentsRunning(currentlyRunningArray);
      this.gtagServiceGA4.setExperimentsRunning(currentlyRunningArray);

      // old event
      this.analyticsService.trackInHeap('experiment:active', {
        experimentName: experiment.name,
        experimentVariant: experiment.variant,
        experimentId: experiment.id
      });

      // new heap event
      this.analyticsService.trackInHeap('experimentActive', {
        experiment: {
          name: experiment.name,
          variant: experiment.variant,
          id: experiment.id
        }
      });

      // Send GA4 experiment details to analytics service
      this.analyticsService.trackViewExperiment({
        name: experiment.name,
        variant: experiment.variant,
        id: experiment.id
      });
    }

    this.windowRef.nativeWindow['experiments'] = currentlyRunning;
    this.log('Added', experiment.name, 'id:', experiment.id, 'variant:', experiment.variant, 'rolledout:', experiment.isRolledOut);

    return experiment;
  }

  /**
   * Check if the experiment is active or not
   * @param name
   * @param variant
   */
  isActive(name: string, variant: string | number): boolean {
    const varNumb = typeof variant === 'string' ? parseInt(variant, 10) : variant;
    const currentlyRunning = this.experimentsObj$.getValue();

    // Either running and variant matches, or it's not running and the variant is 0
    const variantRunning = currentlyRunning[name] ? currentlyRunning[name].variant : 0;
    return variantRunning === varNumb;
  }

  /**
   * Get an experiment if we know what it is
   * @param name
   */
  getExperiment(name: string): Experiment {
    return this.experimentsObj$.getValue()[name];
  }

  /**
   * Get just the variant, otherwise return default 0
   * @param name
   */
  getVariantFor(name: string): number {
    const experiment = this.experimentsObj$.getValue()[name];
    return experiment ? experiment.variant : 0;
  }

  /**
   * Wait for an experiment, if time out, resolve with a 0 variant experiment
   * @param name
   * @param waitForMs
   */
  waitForExperiment(name: string, waitForMs: number = 500): Promise<Experiment> {
    const experiment = this.getExperiment(name);
    if (experiment) {
      return Promise.resolve(experiment);
    }

    let subscriber$: Subscription;
    let subscriberTimeout: NodeJS.Timeout;

    const promise: Promise<Experiment> = new Promise((resolve) => {
      // ... either subscribe until we have the experiment
      subscriber$ = this.experimentsObj$.subscribe((experiments): void => {
        if (experiments[name]) {
          resolve(experiments[name]);
        }
      });

      // Or timeout with a variant 0
      subscriberTimeout = setTimeout((): void => {
        const variantZero = new Experiment({
          name,
          variant: 0
        });
        resolve(variantZero);
      }, waitForMs);
    });

    // ... once one of the above completes, tidy up
    return promise.then((exp): Experiment => {
      clearTimeout(subscriberTimeout);
      subscriber$.unsubscribe();
      return exp;
    });
  }

  /**
   * Fire an event
   * @param string
   */
  fireEvent(string: string): void {
    this.gtmService.addToDataLayer({
      eventType: 'experimentTrigger',
      event: string,
      stateChangeToUrl: this.stateService.currentUrl()
    });
  }
}
