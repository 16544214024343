/* eslint-disable complexity */
import { ILazyModalsList } from 'Modals/interfaces/lazy-modals-list';

/**
 * Lazy load modal component
 * @param component: Name of modal we want to lazy laod
 * @returns {Promise<unknown>}
 */
export function getLazyModalModule(component: ILazyModalsList): Promise<unknown> {
  if (component.name === 'RewardsClaimVoucherModalComponent') {
    return import('Account/components/rewards/rewards-claim-voucher-modal/rewards-claim-voucher-modal.component');
  }

  if (component.name === 'ConfirmModalComponent') {
    return import('Project/shared/components/modals/confirm-modal/confirm-modal.component');
  }

  if (component.name === 'OrderAddressModalComponent') {
    return import('Project/account/my-order/components/order-modals/order-address-modal/order-address-modal.component');
  }

  if (component.name === 'DeliveryViewModalComponent') {
    return import('Account/my-order/components/order-modals/delivery-view-modal/delivery-view-modal.component');
  }

  if (component.name === 'UntrustworthyLoginModalComponent') {
    return import('Modals/untrustworthy-login-modal/untrustworthy-login-modal.component');
  }

  if (component.name === 'UserDeleteModalComponent') {
    return import('Account/components/modals/user-delete/user-delete-modal.component');
  }

  if (component.name === 'MentionMeModalComponent') {
    return import('Shared/components/third-parties/mention-me-modal/mention-me-modal.component');
  }

  if (component.name === 'CreateOccasionModalComponent') {
    return import('Modals/create-occasion-modal/create-occasion-modal.component');
  }

  if (component.name === 'DeliveryTrackingModalComponent') {
    return import('Modals/delivery-tracking-modal/delivery-tracking-modal.component');
  }

  if (component.name === 'ProductReviewsComponent') {
    return import('Modals/product-reviews/product-reviews.component');
  }

  if (component.name === 'HcDeliverySelfServeComponent') {
    return import('Modals/hc-delivery-self-serve/hc-delivery-self-serve.component');
  }

  if (component.name === 'HcQualitySelfServeComponent') {
    return import('Modals/hc-quality-self-serve/hc-quality-self-serve.component');
  }

  if (component.name === 'ResetPasswordModalComponent') {
    return import('Account/auth/modals/reset-password-modal/reset-password-modal.component');
  }

  if (component.name === 'ChangePasswordModalComponent') {
    return import('Account/auth/modals/change-password-modal/change-password-modal.component');
  }

  if (component.name === 'MagicLinkModalComponent') {
    return import('Shared/components/modals/magic-link-modal/magic-link-modal.component');
  }

  if (component.name === 'KeyivrIframeComponent') {
    return import('Checkout/components/modals/keyivr-iframe/keyivr-iframe.component');
  }

  if (component.name === 'OrderSubscriptionResumeModalComponent') {
    return import('Modals/order-subscription-resume-modal/order-subscription-resume-modal.component');
  }

  if (component.name === 'ContactUsModalComponent') {
    return import('Shared/components/modals/contact-us-modal/contact-us-modal.component');
  }

  if (component.name === 'SubscriptionCancelModalComponent') {
    return import('Account/components/subscription-cancel-modal/subscription-cancel-modal.component');
  }

  if (component.name === 'OrderSkipDeliveriesModalComponent') {
    return import('MyOrder/components/order-modals/order-skip-deliveries-modal/order-skip-deliveries-modal.component');
  }

  if (component.name === 'ContentModalComponent') {
    return import('Content/components/content-modal/content-modal.component');
  }

  if (component.name === 'PaymentOptionsModalComponent') {
    return import('MyOrder/components/order-modals/payment-options-modal/payment-options-modal.component');
  }

  // Unable to find requried component
  return Promise.reject(false);
}
