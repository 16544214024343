
<div class="product-card-image" role="img" [attr.aria-label]="product.name + ' - ' + product.description" [ngClass]="{'product-card-image--is-active': cardActive}">
  <!-- Grey placeholder image-->
  <div class="card-image card-image__placeholder" aria-hidden="true"></div>
  <ng-container *ngIf="withinViewport">
    <div class="product-card-image__swipe-container {{'swipe-container'+ index }}">
      <ng-container *inExperiment="{name: 'GRID_VIDEO', variants: [0]}">
        <ng-container *ngFor="let prodImage of product.imageUrls; let i = index"><img class="card-image card-image__main" *ngIf="i===0" [bwEcommImage]="product.imageUrls[0]" [lazyLoaded]="false" aria-hidden="true" bwTimingEvent="TTFF" intersectionObserverEvents observerRootMargin="0% 0% 0% 0%" [observerThreshold]="0.8" (hasEntered)="onSlideEnter(i)" imageRole="product-main" id="{{'product-card-image-id-' + i}}"><img class="card-image card-image__main" *ngIf="i&gt;0" [bwEcommImage]="prodImage" [lazyLoaded]="true" aria-hidden="true" intersectionObserverEvents observerRootMargin="0% 0% 0% 0%" [observerThreshold]="0.8" (hasEntered)="onSlideEnter(i)" imageRole="product-main" id="{{'product-card-image-id-' + i}}"></ng-container>
      </ng-container>
      <ng-container *inExperiment="{name: 'GRID_VIDEO', variants: [1, 2]}">
        <ng-container *ngIf="!isMobile">
          <ng-container *ngFor="let prodImage of imageUrls; let i = index"><img class="card-image card-image__main" *ngIf="i===0" [bwEcommImage]="imageUrls[0]?.src" [lazyLoaded]="false" aria-hidden="true" bwTimingEvent="TTFF" intersectionObserverEvents observerRootMargin="0% 0% 0% 0%" [observerThreshold]="0.8" (hasEntered)="onSlideEnter(i)" imageRole="product-main" id="{{'product-card-image-id-' + i}}" [hidden]="videoLoaded &amp;&amp; toggleVideo ? true : false"><img class="card-image card-image__main" *ngIf="i&gt;0" [bwEcommImage]="prodImage?.src" [lazyLoaded]="true" aria-hidden="true" intersectionObserverEvents observerRootMargin="0% 0% 0% 0%" [observerThreshold]="0.8" (hasEntered)="onSlideEnter(i)" imageRole="product-main" id="{{'product-card-image-id-' + i}}" [hidden]="videoLoaded &amp;&amp; toggleVideo ? true : false"></ng-container>
          <video *ngIf="product.videoThumbnail &amp;&amp; videoLoaded" [attr.src]="product?.videoUrl" id="product-video-{{product.id}}" autoplay loop muted playsinline webkit-playsinline preload="metadata" width="100%" height="100%" [hidden]="videoLoaded &amp;&amp; toggleVideo ? false : true"></video>
        </ng-container>
      </ng-container>
      <ng-container *inExperiment="{name: 'GRID_VIDEO', variants: [1]}">
        <ng-container *ngIf="isMobile">
          <ng-container *ngFor="let prodImage of imageUrls; let i = index">
            <video *ngIf="product.videoThumbnail &amp;&amp; i === 0 &amp;&amp; prodImage.type === 'video' &amp;&amp; product.showVideoOnMobileGrid" id="product-video-{{product.id}}" (hasEntered)="onSlideEnter(i)" [attr.src]="showVideo ? product?.videoUrl : ''" intersectionObserverEvents autoplay loop muted playsinline webkit-playsinline width="100%" height="100%"></video><img class="card-image card-image__main" *ngIf="i===0 &amp;&amp; prodImage.type !== 'video'" [bwEcommImage]="product.imageUrls[0]" [lazyLoaded]="false" aria-hidden="true" bwTimingEvent="TTFF" intersectionObserverEvents observerRootMargin="0% 0% 0% 0%" [observerThreshold]="0.8" (hasEntered)="onSlideEnter(i)" imageRole="product-main" id="{{'product-card-image-id-' + i}}"><img class="card-image card-image__main" *ngIf="i &gt; 0 &amp;&amp; prodImage.type !== 'video'" [bwEcommImage]="prodImage?.src" [lazyLoaded]="true" aria-hidden="true" preload="metadata" intersectionObserverEvents observerRootMargin="0% 0% 0% 0%" [observerThreshold]="0.8" (hasEntered)="onSlideEnter(i)" imageRole="product-main" id="{{'product-card-image-id-' + i}}">
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *inExperiment="{name: 'GRID_VIDEO', variants: [2]}">
        <ng-container *ngIf="isMobile">
          <ng-container *ngFor="let prodImage of imageUrls; let i = index"><img class="card-image card-image__main" *ngIf="i===0" [bwEcommImage]="product.imageUrls[0]" [lazyLoaded]="false" aria-hidden="true" bwTimingEvent="TTFF" intersectionObserverEvents observerRootMargin="0% 0% 0% 0%" [observerThreshold]="0.8" (hasEntered)="onSlideEnter(i)" imageRole="product-main" id="{{'product-card-image-id-' + i}}" [hidden]="videoLoaded &amp;&amp; toggleVideo ? true : false"><img class="card-image card-image__main" *ngIf="i &gt; 0 &amp;&amp; prodImage.type !== 'video'" [bwEcommImage]="prodImage?.src" [lazyLoaded]="true" aria-hidden="true" preload="metadata" intersectionObserverEvents observerRootMargin="0% 0% 0% 0%" [observerThreshold]="0.8" (hasEntered)="onSlideEnter(i)" imageRole="product-main" id="{{'product-card-image-id-' + i}}" [hidden]="videoLoaded &amp;&amp; toggleVideo ? true : false"></ng-container>
        </ng-container>
        <video *ngIf="product.videoThumbnail &amp;&amp; videoLoaded" [attr.src]="product?.videoUrl" id="product-video-{{product.id}}" autoplay loop muted playsinline webkit-playsinline preload="metadata" width="100%" height="100%" [hidden]="videoLoaded &amp;&amp; toggleVideo ? false : true"></video>
      </ng-container>
    </div>
  </ng-container>
</div>