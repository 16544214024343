import { Injectable } from '@angular/core';
import { Purchase } from 'Shared/classes/purchase';
import { APIJSONRequest, APISerialisedJSONResponse, BackendService } from 'Shared/services/backend.service';
import { User, UserService } from 'Shared/services/user.service';
import { AddressModelService } from 'Shared/models/address-model.service';
import { Card } from 'Shared/classes/card';
import { CurrencyCode, Price } from 'Shared/classes/price';
import { Credit } from 'Shared/classes/credit';
import { Product } from 'Shared/classes/product';
import { Discount } from 'Shared/classes/discount';
import { ProductModelService } from 'Checkout/models/product-model.service';
import { OrderModelService } from 'Shared/models/order-model.service';
import { GiftVoucher, GiftVoucherRedemption } from 'Shared/classes/gift-voucher';
import { DiscountService } from 'Checkout/services/discount.service';
import { Order } from 'Shared/classes/order';
import { CheckoutService } from 'Checkout/services/checkout.service';
import { StripeIntentAPIResponse } from 'Shared/types/backend-api/purchase-api.typings';

@Injectable({
  providedIn: 'root'
})
export class PurchaseModelService {
  private stripePaymentIntentAPIVersion: string = '2024-04-26';

  constructor(
    private backend: BackendService,
    private userService: UserService,
    private orderModelService: OrderModelService,
    private productModelService: ProductModelService,
    private discountService: DiscountService,
    private checkoutService: CheckoutService
  ) {}

  /**
   * To payload
   * @param purchase
   * @param user - Used for 'guest' users
   * @param isMultiCodeEnabled
   */
  static toPayload(purchase: Purchase, user?: User, isMultiCodeEnabled: boolean = false): APIJSONRequest<'/v3/purchases'> {
    // If we are logged out, we pass in the email and name
    const userAttributes =
      user && !user.isLoggedIn()
        ? {
            email: user.email.address,
            full_name: user.fullName
          }
        : undefined;

    const useCredit = purchase.credit && purchase.credit.use !== undefined ? purchase.credit.use : undefined;

    let codeAttributes: { code: string };
    let trackingCodeAttributes: { code: string };

    if (isMultiCodeEnabled) {
      const shouldNotSendTracking: boolean = !!(purchase?.discount || purchase?.giftVoucher || purchase?.giftVouchers?.length > 0);
      trackingCodeAttributes =
        !shouldNotSendTracking && purchase.meta && purchase.meta.trackingCode ? { code: purchase.meta.trackingCode } : undefined;
    } else {
      codeAttributes =
        purchase?.discount || purchase?.giftVoucher ? { code: purchase?.discount?.code || purchase?.giftVoucher?.code } : undefined;
      trackingCodeAttributes = purchase.meta && purchase.meta.trackingCode ? { code: purchase.meta.trackingCode } : undefined;
    }

    const source = purchase.source ? `${purchase.source.charAt(0).toUpperCase()}${purchase.source.slice(1)}` : undefined;

    const billingAddress = purchase.billingAddressAttributes ? AddressModelService.toPayload(purchase.billingAddressAttributes) : undefined;

    if (purchase.postalPreference) {
      billingAddress['postal_preference'] = purchase.postalPreference;
    }

    return {
      data: {
        type: 'purchases',
        attributes: {
          source,
          use_credit: useCredit,
          user_attributes: userAttributes,
          code_attributes: codeAttributes,
          tracking_code_attributes: trackingCodeAttributes,
          button_ref: purchase.meta.buttonRef,
          terms_accepted: purchase.meta.termsAccepted,
          terms_displayed: purchase.meta.termsDisplayed,
          billing_address_id: purchase.billingAddressId || undefined,
          billing_address_attributes: billingAddress || undefined,
          orders_attributes: purchase.orders.map((order) => {
            let time: string;
            if (order.timeslot) {
              const timeslotDate = order.timeslot.start.toDate();
              time = [`0${timeslotDate.getUTCHours()}`.slice(-2), `00${timeslotDate.getUTCMinutes()}`.slice(-2)].join(':');
              order.firstDeliveryDate = order.timeslot.start;
            }

            // Depending on if the shipping option is a 'replacement' we send through the original (ie the 'subsequentDeliveries') one
            let shippingOptionId: number;
            if (order.shippingOption) {
              shippingOptionId = order.shippingOption.id;
            }
            if (order.shippingOption && order.shippingOption.subsequentDeliveries) {
              shippingOptionId = order.shippingOption.subsequentDeliveries.id;
            }

            const greetingCardMessage = order.giftCard && order.getGreetingCardAddon() ? order.giftCard.message : null;

            return {
              id: order.id,
              _destroy: order.deleted ? '1' : undefined,
              sku_association_id: order.upsoldFrom ? order.upsoldFrom.id : null,
              shipping_option_id: shippingOptionId,
              shipping_note: order.note || undefined,
              sku_id: order.product ? order.product.id : undefined,
              gift_message: greetingCardMessage,
              addon_sku_ids: (order.addons || []).map((a) => a.id),
              timeslot_id: order.timeslot && order.timeslot.id ? order.timeslot.id : undefined,
              first_delivery_time: time,
              first_delivery: order.firstDeliveryDate ? order.firstDeliveryDate.format('YYYY-MM-DD') : undefined,
              product_attributes: {
                duration: order.duration === -1 ? 0 : order.duration,
                frequency: order.frequency,
                lily_free: order.isLilyFree
              },
              shipping_address_attributes: order.address ? AddressModelService.toPayload(order.address) : undefined
            };
          })
        }
      }
    };
  }

  /**
   * To confirm payload
   * @param purchase
   * @param paymentIntentId
   */
  static toConfirmPayload(purchase: Purchase): APIJSONRequest<'/v3/purchases/:purchaseId/confirm'> {
    return {
      data: {
        attributes: {
          stripe_payment_intent_attributes: {
            stripe_id: purchase.paymentIntentId
          }
        }
      }
    };
  }

  /**
   * The "Finalize" payload is slightly different thatn the main payload for purchases
   * @param purchase
   */
  static toFinalizePayload(
    purchase: Purchase,
    card: Card,
    storeCardOnPaymentSuccess: boolean
  ): APIJSONRequest<'/v3/purchases/:purchaseId/pay'> {
    return {
      data: {
        id: purchase.id,
        type: 'purchases',
        attributes: {
          credit_card_attributes: {
            id: card.id > 0 ? card.id : undefined, // for zero-value purchases,
            store_card: storeCardOnPaymentSuccess
          },
          button_ref: purchase.meta.buttonRef,
          terms_accepted: purchase.meta.termsAccepted,
          terms_displayed: purchase.meta.termsDisplayed
        }
      }
    };
  }

  /**
   * To the "Validate" payload
   * @param card
   */
  static toValidatePayload(card: Card): APIJSONRequest<'/v3/purchases/:purchaseId/validate'> {
    if (!card || !card.id) {
      return {};
    }
    return {
      data: {
        attributes: {
          credit_card_attributes: {
            id: card.id
          }
        }
      }
    };
  }

  /**
   * Get a Stripe payment intent for a purchase
   * @param purchase
   * @param storeCard
   * @param paymentMethod
   */
  public getStripePaymentIntent(purchase: Purchase, storeCard: boolean = false, paymentMethod: string = 'null'): Promise<any> {
    const user = this.userService.getUser();

    return this.backend
      .post(
        user,
        `/${this.stripePaymentIntentAPIVersion}/checkout/stripe-payment-intents` as '/:apiVersion/checkout/stripe-payment-intents',
        { purchase_id: purchase.id, store_card: storeCard, payment_method: paymentMethod },
        {
          headers: !user.isLoggedIn() // Auth headers for logged insent in backend.service
            ? {
                'x-user-email': user.email.address,
                'x-purchase-token': purchase.token
              }
            : null
        }
      )
      .then((r) => (r && r.client_secret ? Promise.resolve(r) : Promise.reject('No Payment Token')));
  }

  /**
   * Get a Stripe payment intent for a purchase
   * @param purchase
   * @param storeCard
   * @param paymentMethod
   */
  public updateStripePaymentIntent(
    purchase: Purchase,
    paymentIntentID: string,
    storeCard: boolean = false,
    paymentMethod: string = 'null'
  ): Promise<void> {
    const user = this.userService.getUser();

    return this.backend.put(
      user,
      `/${this.stripePaymentIntentAPIVersion}/checkout/stripe-payment-intents/${paymentIntentID}/update-usage` as '/:apiVersion/checkout/stripe-payment-intents/:paymentIntentID/update-usage',
      { store_card: storeCard, payment_method: paymentMethod },
      {
        headers: !user.isLoggedIn() // Auth headers for logged insent in backend.service
          ? {
              'x-user-email': user.email.address,
              'x-purchase-token': purchase.token
            }
          : undefined
      }
    );
  }

  /**
   * From payload
   * @param purchase
   */
  public fromPayload(res: APISerialisedJSONResponse<'v3/purchases/*'>): Purchase {
    const p = new Purchase();
    p.id = parseInt(res.id, 10);

    if (res?.code?.code) {
      p.discount = new Discount(res.code.code, res.code.percentage, res.code.amount_pennies, res.code.delivery_number_range);
      p.discount.description = res.code.campaign_description ?? undefined;
    }

    p.currency = res.currency as CurrencyCode;
    p.credit = res.credited_pennies ? new Credit(true, res.credited_currency as CurrencyCode, 1, res.credited_pennies) : new Credit(false);

    p.price = new Price(res.currency as CurrencyCode, 1, res.total_cost_pre_discount_pennies, res.total_cost_pennies);
    p.guestPasswordToken = res.password_reset_token || undefined;

    p.setState(res.state);
    p.token = res.token;
    p.source = res.source;

    const orders = (res.orders ?? []).map((o): Order => this.orderModelService.fromPayload(o));
    p.setOrders(orders);

    let giftVoucherRedemption = [];
    giftVoucherRedemption = res.gift_voucher_redemptions?.map(
      (e): GiftVoucherRedemption => new GiftVoucherRedemption(e.amount_pennies, e.completed, e.order_id)
    );

    // TODO: remove after migration to giftVouchers
    p.giftVoucher = res.gift_voucher
      ? new GiftVoucher(
          res.gift_voucher.code,
          giftVoucherRedemption,
          res.gift_voucher.id,
          res.gift_voucher.type,
          res.gift_voucher.initial_value_pennies,
          res.gift_voucher.balance_pennies,
          res.gift_voucher.currency as CurrencyCode,
          res.gift_voucher.expires_on
        )
      : undefined;

    p.giftVouchers = [];
    if (res.gift_vouchers?.length > 0) {
      res.gift_vouchers.forEach((voucher): void => {
        if (voucher.code) {
          const giftVoucher = new GiftVoucher(
            voucher.code,
            giftVoucherRedemption,
            voucher.id,
            voucher.type,
            voucher.initial_value_pennies,
            voucher.balance_pennies,
            voucher.currency as CurrencyCode,
            voucher.expires_on,
            voucher.is_loyalty
          );
          p.giftVouchers.push(giftVoucher);
        }
      });
    }

    p.totalLoyaltyPoints = res.total_loyalty_points;
    return p;
  }

  /**
   * Get the products relating to a purchase orders
   * @param purchase
   */
  getProductsForPurchase(purchase: Purchase): Promise<Purchase> {
    let promise: any = Promise.resolve(purchase);
    (purchase.orders || []).forEach((order, index) => {
      const country = order.address.country;

      promise = promise
        .then(() => this.productModelService.getAvailableProducts(country, index, purchase.discount))
        .then((products: Product[]) => {
          const orderedProduct = products.find((p) => p.id === order.product.id);

          // We should never *not* be able to find the products in this list, maybe if the response of
          // the products API has changed since the last call - unlikely, but possible
          order.product = orderedProduct ? orderedProduct.clone() : new Product(order.product.id);
          order.product.pricing = []; // Clear the pricing, as the price from the purchase is per order

          // Find the product that has the association id of the upsell assocation
          // and assign to the order
          const upsoldFromProduct = order.upsoldFrom
            ? products.find((p) => !!p.upsells.find((u) => u.id === order.upsoldFrom.id))
            : undefined;

          order.upsoldFrom = upsoldFromProduct ? Object.assign(order.upsoldFrom, { product: upsoldFromProduct }) : undefined;

          purchase.orders[index] = order;
          return purchase;
        })
        .catch(
          () =>
            // Any errors, do not change the product
            purchase
        );
    });
    return promise;
  }

  /**
   * Confirm the payment
   * @param purchase
   * @param paymentIntentId
   */
  confirm(purchase: Purchase): Promise<boolean> {
    const user = this.userService.getUser();
    return this.backend.put(
      user,
      `/v3/purchases/${purchase.id}/confirm` as '/v3/purchases/:purchaseId/confirm',
      PurchaseModelService.toConfirmPayload(purchase),
      {
        requestIsJsonApi: true,
        sendExperiments: true,
        headers: !user.isLoggedIn() // Auth headers for logged insent in backend.service
          ? {
              'x-user-email': user.email.address,
              'x-purchase-token': purchase.token
            }
          : null,
        params: {
          gift_card_as_addon: true
        }
      }
    ); // Note - no response required
  }

  /**
   * Verify a purchase
   * @param purchase
   * @param card
   */
  validate(purchase: Purchase, card?: Card): Promise<boolean> {
    const user = this.userService.getUser();
    return this.backend.post(
      user,
      `/v3/purchases/${purchase.id}/validate` as '/v3/purchases/:purchaseId/validate',
      card ? PurchaseModelService.toValidatePayload(card) : {},
      {
        requestIsJsonApi: true,
        sendExperiments: true,
        headers: !user.isLoggedIn()
          ? {
              'x-user-email': user.email.address,
              'x-purchase-token': purchase.token
            }
          : null,
        params: {
          gift_card_as_addon: true
        }
      }
    );
  }

  /**
   * Restore a purchase
   * @param purchase
   */
  restore(purchase: Purchase): Promise<Purchase> {
    const user = this.userService.getUser();
    return this.backend
      .post(
        user,
        `/v3/purchases/${purchase.id}/validate` as '/v3/purchases/:purchaseId/restore',
        {},
        {
          requestIsJsonApi: true,
          responseIsJsonApi: true,
          sendExperiments: true,
          headers: !user.isLoggedIn()
            ? {
                'x-user-email': user.email.address,
                'x-purchase-token': purchase.token
              }
            : {
                'x-purchase-token': purchase.token
              },
          params: {
            include: [
              'gift_voucher',
              'gift_voucher_redemptions',
              'code',
              'orders',
              'orders.shipping_address',
              'orders.product',
              'orders.sku',
              'orders.addon_skus',
              'orders.shipping_option'
            ].join(','),
            gift_card_as_addon: true
          }
        }
      )
      .then((resPurchase) => this.fromPayload(resPurchase));
  }

  /**
   * Initiate the payment and get the payment secret
   * @param purchase
   * @param card
   */
  initiatePayment(purchase: Purchase, card: Card, storeCardOnPaymentSuccess: boolean, isBankRedirect: boolean = false): Promise<string> {
    const user = this.userService.getUser();

    const attributes = isBankRedirect
      ? {
          payment_method: card.kind,
          store_card: storeCardOnPaymentSuccess
        }
      : {
          credit_card_attributes: {
            id: card.id,
            store_card: storeCardOnPaymentSuccess
          }
        };

    return this.backend
      .put(
        user,
        `/v3/purchases/${purchase.id}/initiate_payment` as '/v3/purchases/:purchaseId/initiate_payment',
        {
          data: {
            attributes
          }
        },
        {
          requestIsJsonApi: true,
          sendExperiments: true,
          headers: !user.isLoggedIn() // Auth headers for logged insent in backend.service
            ? {
                'x-user-email': user.email.address,
                'x-purchase-token': purchase.token
              }
            : null,
          params: {
            gift_card_as_addon: true
          }
        }
      )
      .then((r) =>
        r && r.data && r.data.attributes && r.data.attributes.client_secret
          ? r.data.attributes.client_secret
          : Promise.reject('No Payment Token')
      );
  }

  /**
   * Get a purchase
   * @param purchase
   */
  get(purchase: Purchase): Promise<Purchase> {
    const user = this.userService.getUser();
    return this.backend
      .get(user, `/v3/purchases/${purchase.id}` as '/v3/purchases/:purchaseId', {
        sendExperiments: true,
        responseIsJsonApi: true,
        params: {
          include: [
            'gift_voucher',
            'gift_voucher_redemptions',
            'code',
            'orders',
            'orders.shipping_address',
            'orders.product',
            'orders.sku',
            'orders.addon_skus',
            'orders.shipping_option'
          ].join(','),
          gift_card_as_addon: true
        },
        headers: !user.isLoggedIn()
          ? {
              'x-user-email': user.email.address,
              'x-purchase-token': purchase.token
            }
          : null
      })
      .then((res) => (res ? this.getProductsForPurchase(this.fromPayload(res)) : null));
  }

  /**
   * Finalize a purchase given a card
   * @param purchase
   * @param card
   */
  finalize(purchase: Purchase, card: Card, storeCardOnPaymentSuccess: boolean): Promise<any> {
    const user = this.userService.getUser();
    return this.backend.put(
      user,
      `/v3/purchases/${purchase.id}/pay` as '/v3/purchases/:purchaseId/pay',
      PurchaseModelService.toFinalizePayload(purchase, card, storeCardOnPaymentSuccess),
      {
        requestIsJsonApi: true,
        sendExperiments: true,
        headers: !user.isLoggedIn() // Auth headers for logged insent in backend.service
          ? {
              'x-user-email': user.email.address,
              'x-purchase-token': purchase.token
            }
          : null,
        params: {
          gift_card_as_addon: true
        }
      }
    );
  }

  /**
   * Update a purchase
   * @param purchase
   */
  update(purchase: Purchase): Promise<Purchase> {
    const user = this.userService.getUser();
    return this.backend
      .put(
        user,
        `/v3/purchases/${purchase.id}` as 'PUT:/v3/purchases/:purchaseId',
        PurchaseModelService.toPayload(purchase, user, this.checkoutService.isMultiCodeRedemptionEnabled()),
        {
          sendExperiments: true,
          responseIsJsonApi: true,
          requestIsJsonApi: true,
          params: {
            include: [
              'gift_voucher',
              'gift_voucher_redemptions',
              'code',
              'orders',
              'orders.shipping_address',
              'orders.product',
              'orders.sku',
              'orders.addon_skus',
              'orders.shipping_option'
            ].join(','),
            gift_card_as_addon: true
          },
          headers: !user.isLoggedIn() // Auth headers for logged insent in backend.service
            ? {
                'x-user-email': user.email.address,
                'x-purchase-token': purchase.token
              }
            : null
        }
      )
      .then((res) => (res ? this.getProductsForPurchase(this.fromPayload(res)) : null));
  }

  /**
   * Add a discount code or gift voucher to a order
   * @param {string} code
   * @param {Purchase} purchase
   * @returns {Promise<Purchase>}
   */
  public addDiscountCode(code: string, purchase: Purchase): Promise<Purchase> {
    const user = this.userService.getUser();
    return this.backend
      .put(
        user,
        `/2023-10-23/purchases/${purchase.id}/add_code/${code}` as '/2023-10-23/purchases/:purchaseId/add_code/:code',
        PurchaseModelService.toPayload(purchase, user),
        {
          sendExperiments: true,
          responseIsJsonApi: true,
          requestIsJsonApi: true,
          params: {
            include: [
              'code',
              'gift_voucher',
              'gift_vouchers',
              'gift_voucher_redemptions',
              'orders',
              'orders.shipping_address',
              'orders.product',
              'orders.sku',
              'orders.addon_sku',
              'orders.addon_skus',
              'orders.shipping_option'
            ].join(',')
          },
          headers: { 'x-purchase-token': purchase.token }
        }
      )
      .then((res): Promise<Purchase> => {
        if (!res) {
          return null;
        }

        return this.fromDiscountCodeRedemptionPayload(code, res);
      });
  }

  /**
   * Remove discount code applied to purchase
   * @param {string} code
   * @param {Purchase} purchase
   * @returns {Promise<Purchase>}
   */
  public removeDiscountCode(code: string, purchase: Purchase): Promise<Purchase> {
    const user = this.userService.getUser();
    return this.backend
      .put(
        user,
        `/2023-10-23/purchases/${purchase.id}/remove_code/${code}` as '/2023-10-23/purchases/:purchaseId/remove_code/:code',
        PurchaseModelService.toPayload(purchase, user),
        {
          sendExperiments: true,
          responseIsJsonApi: true,
          requestIsJsonApi: true,
          params: {
            include: [
              'code',
              'gift_voucher',
              'gift_vouchers',
              'gift_voucher_redemptions',
              'orders',
              'orders.shipping_address',
              'orders.product',
              'orders.sku',
              'orders.addon_sku',
              'orders.addon_skus',
              'orders.shipping_option'
            ].join(',')
          },
          headers: { 'x-purchase-token': purchase.token }
        }
      )
      .then((res): Promise<Purchase> => {
        if (!res) {
          return null;
        }

        return this.fromDiscountCodeRedemptionPayload(code, res);
      });
  }

  /**
   * Get and format purchase with correct discount and gift vouchers
   * @param {string} code
   * @param {APISerialisedJSONResponse<'/2023-10-23/purchases/:purchaseId/add_code/:code'>} res
   * @returns {Promise<Purchase>}
   */
  private fromDiscountCodeRedemptionPayload(
    code: string,
    res: APISerialisedJSONResponse<'/2023-10-23/purchases/:purchaseId/add_code/:code'>
  ): Promise<Purchase> {
    return this.getProductsForPurchase(this.fromPayload(res)).then((purchaseResponse: Purchase): Purchase => {
      // check and set discount code
      if (purchaseResponse.discount?.code) {
        const currentCode = this.discountService.activeDiscountCode;
        purchaseResponse.discount.active = currentCode?.active ?? undefined;
        if (!currentCode?.active) {
          const codeObj = purchaseResponse.discount;
          codeObj.active = false;
          this.discountService.activeDiscountCode = codeObj;
        }
      }

      // check and set gift vouchers
      const currentVouchers = this.discountService.activeGiftVouchers;
      if (purchaseResponse.giftVouchers.length > 0) {
        (purchaseResponse.giftVouchers ?? []).forEach((giftVoucher: GiftVoucher): void => {
          if (giftVoucher.code === code) {
            giftVoucher.active = false;
            currentVouchers.push(giftVoucher);
          } else {
            giftVoucher.active = true;
          }
        });
        this.discountService.activeGiftVouchers = currentVouchers;
      }

      return purchaseResponse;
    });
  }

  /**
   * Create a purchase given an initial order
   * @param purchase
   */
  create(purchase: Purchase): Promise<Purchase> {
    const user = this.userService.getUser();
    return this.backend
      .post(user, '/v3/purchases', PurchaseModelService.toPayload(purchase, user, this.checkoutService.isMultiCodeRedemptionEnabled()), {
        sendExperiments: true,
        responseIsJsonApi: true,
        requestIsJsonApi: true,
        params: {
          include: [
            'gift_voucher',
            'gift_voucher_redemptions',
            'code',
            'orders',
            'orders.shipping_address',
            'orders.product',
            'orders.sku',
            'orders.addon_skus',
            'orders.shipping_option'
          ].join(','),
          gift_card_as_addon: true
        },
        headers: user.isLoggedIn() // Auth headers for logged insent in backend.service
          ? {
              'x-user-email': user.email.address
            }
          : null
      })
      .then((res) => (res ? this.getProductsForPurchase(this.fromPayload(res)) : null));
  }

  /**
   * Start KeyIvr Payment Intent
   * @param agent - email
   * @param purchase
   */
  startKeyIvrTransaction(
    agent: string,
    purchase: Purchase
  ): Promise<APISerialisedJSONResponse<'/v3/purchases/:purchaseId/trigger_key_ivr_transaction'>> {
    const user = this.userService.getUser();

    return this.backend.post(
      user,
      `/v3/purchases/${purchase.id}/trigger_key_ivr_transaction` as '/v3/purchases/:purchaseId/trigger_key_ivr_transaction',
      { agent_email: agent },
      {
        requestIsJsonApi: true,
        sendExperiments: true,
        headers: !user.isLoggedIn()
          ? {
              'x-user-email': user.email.address,
              'x-purchase-token': purchase.token
            }
          : null
      }
    );
  }
}
