import { Injectable } from '@angular/core';
import {
  BackendService,
  APISerialisedJSONResponse,
  APIJSONRequest
} from 'Shared/services/backend.service';
import { UserService } from 'Shared/services/user.service';
import { Address } from 'Shared/classes/address';
import { Country } from 'Shared/classes/country';
import { CountryService } from 'Shared/services/country.service';
import { TelephoneNumberUtilsService } from 'Shared/utils/telephone-number-utils.service';
import { ShippingOption } from 'Shared/classes/shipping-option';
import { Error } from 'Shared/classes/error';
import { Email } from 'Shared/classes/email';

@Injectable({
  providedIn: 'root'
})
export class AddressModelService {
  constructor(
    private backend: BackendService,
    private userService: UserService,
    private countriesService: CountryService,
    private telephoneUtilsService: TelephoneNumberUtilsService
  ) {}

  private static tidyLine(str: string): string {
    return str.replace(/\s\s+/g, ' ').trim();
  }

  /**
   * To Payload for Backend
   * @param address
   * Optional Fields get converted to empty strings so they can be passed to Backed
   */
  public static toPayload(address: Address): APIJSONRequest<'/v1/addresses/*'> {
    return {
      id: address.id,
      name: address.name ? AddressModelService.tidyLine(address.name) : undefined,
      company: address.company ? AddressModelService.tidyLine(address.company) : '', // Optional Field
      vat_number: address.vat ? AddressModelService.tidyLine(address.vat) : undefined,
      line1: address.line1 ? AddressModelService.tidyLine(address.line1) : undefined,
      line2: address.line2 ? AddressModelService.tidyLine(address.line2) : '', // Optional Field
      postcode: address.getPostcode(),
      city: address.city ? AddressModelService.tidyLine(address.city) : undefined,
      shipping_country_id: address.country ? address.country.id : undefined,
      postcode_anywhere_address_id: address.pcaID || undefined,
      phone_number:
        address.phone && address.country
          ? TelephoneNumberUtilsService.convertToInternationalNumber(
              address.country.phonePrefix,
              address.phone
            )
          : undefined,
      email: address?.email?.address || undefined
    };
  }

  /**
   * From payload
   * @param res
   */
  public fromPayload(res: APISerialisedJSONResponse<'/v1/addresses/*'>): Address {
    const country = this.countriesService.getCountryBy('id', res.shipping_country_id);
    const addr = new Address();
    addr.city = res.city;
    addr.company = res.company;
    addr.vat = res.vat_number;
    addr.id = parseInt(res.id, 10);
    addr.line1 = res.line1;
    addr.line2 = res.line2;
    addr.name = res.name || '';
    addr.phone = res.phone;
    addr.postcode = (res.postcode || '').toUpperCase();
    addr.country = country;
    addr.phone = res.phone_number
      ? TelephoneNumberUtilsService.convertToDisplayNumber(res.phone_number)
      : undefined;
    addr.pcaID = res.postcode_anywhere_uid;
    addr.email = res.email ? new Email(res.email) : undefined;
    return addr;
  }

  /**
   * Get all addresses for a user
   */
  public getAll(): Promise<any> {
    const user = this.userService.getUser();
    return this.backend
      .get(user, '/v1/addresses')
      .then((res) => (res && res.addresses ? res.addresses.map((a) => this.fromPayload(a)) : []));
  }

  /**
   * Create an address
   * @param user
   * @param address
   */
  public create(address: Address): Promise<Address> {
    const user = this.userService.getUser();
    return this.backend
      .post(user, `/v1/addresses`, {
        address: AddressModelService.toPayload(address)
      })
      .then((res) => this.fromPayload(res));
  }

  /**
   * Get address
   * @param address
   */
  get(address: Address): Promise<Address> {
    const user = this.userService.getUser();
    return this.backend
      .get(user, `/v1/addresses/${address.id}`)
      .then((res) => this.fromPayload(res));
  }

  /**
   * Delete an address
   * @param addressId
   */
  public delete(address: Address): Promise<any> {
    const user = this.userService.getUser();
    return this.backend.delete(user, `/v1/addresses/${address.id}`);
  }

  public check(
    address: Address,
    shippingOption: ShippingOption
  ): Promise<APISerialisedJSONResponse<'/v1/addresses/check'> | Error> {
    return this.backend
      .get(null, '/v1/addresses/check', {
        useUrlAsCache: true,
        params: {
          'address[postcode]': address.postcode,
          'address[shipping_country_id]': address.country.id,
          shipping_option_id: shippingOption.id
        }
      })
      .then((res) => {
        if (res.valid) {
          return Promise.resolve(res);
        }

        return Promise.reject(
          new Error({
            title: res.message,
            message: '',
            code: 'addressCheck'
          })
        );
      });
  }

  /**
   * Update an address
   * @param address
   */
  public update(address: Address): Promise<any> {
    return this.delete(address).then(() => {
      address.id = null;
      return this.create(address);
    });
  }

  /**
   * Suggest alternative addresses
   * @param address
   */
  public suggestAlternatives(address: Address): Promise<Address[]> {
    return this.backend
      .post(null, '/v1/addresses/dhl_check', {
        address: AddressModelService.toPayload(address)
      })
      .then((res) =>
        res && res.suggestions ? res.suggestions.map((s) => this.fromPayload(s)) : []
      );
  }
}
