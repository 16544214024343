import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import ngTemplateInterceptor from 'Content/interceptors/ngTemplate.interceptor';
import bwContactUsInterceptor from 'Content/interceptors/bwContactUs.interceptor';
import imgRootImagesInterceptor from 'Content/interceptors/imgRootImages.interceptor';
import imgStaticImagesInterceptor from 'Content/interceptors/imgStaticImages.interceptor';
import bwReviewSummaryInterceptor from 'Content/interceptors/bwReviewSummary.interceptor';
import bwContentfulFormInterceptor from 'Content/interceptors/bwContentfulForm.interceptor';
import bwSocialNetworkBarInterceptor from 'Content/interceptors/bwSocialNetworkBar.interceptor';
import bwOurFollowersDirectiveInterceptor from 'Content/interceptors/bwOurFollowersDirective.interceptor';
import readMoreInterceptor from 'Content/interceptors/readMore.interceptor';
import reviewsInterceptor from 'Content/interceptors/reviews.interceptor';
import { Error } from 'Shared/classes/error';
import readLessInterceptor from 'Content/interceptors/readLess.interceptor';
import tooltipcontentInterceptor from 'Content/interceptors/tooltipcontent.interceptor';
import instagramFeedInterceptor from 'Content/interceptors/instagram-feed.interceptor';
import bwHelpCenterInterceptor from 'Content/interceptors/bwHelpCenter.interceptor';
import bwCookieConsentInterceptor from 'Content/interceptors/bwCookieConsent.interceptor';
import bwSlideshowInterceptor from 'Content/interceptors/bwSlideshow.interceptor';

import { CountryService } from 'Shared/services/country.service';
import { LocationService } from 'Shared/services/location.service';

@Injectable({
  providedIn: 'root'
})
export class ContentModelService {
  window: any;
  getPromise: {
    [key: string]: Promise<HTMLDivElement>;
  } = {};

  componentInterceptors: any = {};
  fileInterceptors: any = {};

  constructor(
    private windowRefService: WindowRefService,
    private countryService: CountryService,
    private locationService: LocationService
  ) {
    this.window = this.windowRefService.nativeWindow;

    this.componentInterceptors = {
      iframe: (item: HTMLElement) => {
        const src = item.getAttribute('src');

        const split = src.split('?');
        const iframeParams = this.locationService.getParamsAsObject(`?${split[1] || ''}`);
        const appParams = this.locationService.getUrlParams();
        const newParams = Object.assign({}, iframeParams, appParams);
        if (newParams) {
          const newUrl = `${split[0]}${this.locationService.getQuerystringForParams(newParams)}`;
          item.setAttribute('src', newUrl);
        }
        return item;
      },
      'bw-contact-us': bwContactUsInterceptor,
      'bw-help-center': bwHelpCenterInterceptor,
      'script[type="text/ng-template"]': ngTemplateInterceptor,
      'img[src^="/images/"]': imgRootImagesInterceptor,
      'data-review-summary': bwReviewSummaryInterceptor,
      'bw-contentful-form': bwContentfulFormInterceptor,
      'img[src^="/static-v2/"]': imgStaticImagesInterceptor,
      '.bwOurFollowersDirective': bwOurFollowersDirectiveInterceptor,
      '[analytics-category^="socialNetworkBar"]': bwSocialNetworkBarInterceptor,
      '[read-more]': readMoreInterceptor,
      '[read-less]': readLessInterceptor,
      '[tooltip-content]': tooltipcontentInterceptor,
      'bw-instagram-feed': instagramFeedInterceptor,
      '[data-consent]': bwCookieConsentInterceptor,
      'bw-slideshow': bwSlideshowInterceptor
    };
  }

  /**
   * Looks in the html for particular components and changes them accordingly
   * @param html
   */
  interceptor(html): HTMLDivElement {
    let htmlForDom = html;

    // Do any basic text replacements we need to the HTML
    const toReplace = this.countryService.siteConfigValue('contentful.replacements.text');
    (toReplace || []).forEach((r) => {
      htmlForDom = htmlForDom.replace(new RegExp(r.replace, 'gm'), r.with);
    });

    const element = document.createElement('div');
    element.innerHTML = htmlForDom;

    // loops through components with interceptors and checks if the copy has any matching components
    Object.keys(this.componentInterceptors).forEach((selector) => {
      const foundElements = element.querySelectorAll(selector);
      Array.prototype.forEach.call(foundElements, (item) => {
        this.componentInterceptors[selector](item);
      });
    });

    // html is only changed if a specified component is found
    return element;
  }

  /**
   * Fetch the url and respond with the text
   * @param url
   */
  fetch(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.onreadystatechange = function (): void {
        if (this.readyState === 4 && this.status === 200) {
          resolve(request.responseText);
          return;
        }
        if (this.readyState === 4) {
          reject(
            new Error({
              message: '',
              code: 'pageNotFound'
            })
          );
        }
      };
      request.open('GET', url, true);
      request.send();
    });
  }

  /**
   * Get the content for a path
   * @param path
   */
  get(url: string): Promise<HTMLDivElement> {
    const cacheKey = url;

    if (this.getPromise[cacheKey]) {
      return this.getPromise[cacheKey];
    }

    const fallbackPromise = () => this.fetch(url);

    let promise = this.windowRefService.getCacheAsPromise(url, fallbackPromise);

    promise = promise.then((html) => this.interceptor(html));

    this.getPromise[cacheKey] = promise;

    return this.getPromise[cacheKey];
  }
}
