import * as clone from 'clone';
export class Error {
  public title: string;
  public message: string;
  public code: string;
  public kind: string;
  public statusCode: number;
  public meta?: {
    [key: string]: string;
  } = {};

  constructor(err: ErrorItem) {
    this.title = err.title;
    this.message = err.detail || err.message;
    this.code = err.code;
    this.kind = err.kind || err.secondary_code;
  }
}

export interface ErrorItem {
  code: string;
  title?: string;
  detail?: string;
  message?: string;
  kind?: string;
  secondary_code?: string;
}
