import * as clone from 'clone';
import { RouteDefinitions, StateName, StateParams } from 'Shared/services/state.service';

export type ActivatedStateData<T> = T extends StateName ? RouteDefinitions[T]['data'] : {};

export type ActivatedStateParams<T> = T extends StateName ? RouteDefinitions[T]['params'] : {};

export class ActivatedState<T = void | StateName> {
  public params: ActivatedStateParams<T> = {} as ActivatedStateParams<T>;
  public from?: ActivatedState<any>;

  constructor(
    public name: StateName,
    public url: string,
    public pathParams: ActivatedStateParams<T> = {} as ActivatedStateParams<T>,
    public queryParams: ActivatedStateParams<T> = {} as ActivatedStateParams<T>,
    public data: ActivatedStateData<T> = {} as ActivatedStateData<T>,
    public statePath: ActivatedState[] = []
  ) {
    this.params = Object.assign({}, pathParams, queryParams);
    this.url = (this.url || '').toLowerCase();
  }

  /**
   * Clone the instance
   */
  clone(): ActivatedState {
    return clone(this);
  }
}
