import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from 'Shared/classes/order';
import * as dayjs from 'dayjs';
import { Discount } from 'Shared/classes/discount';
import { User } from 'Shared/classes/user';
import { FeaturesService } from 'Shared/services/features.service';

/* bw:view-encapsulation */
@Component({
  selector: 'bw-order-summary',
  templateUrl: './order-summary.component.html'
})
export class OrderSummaryComponent implements OnInit {
  @Input()
  order: Order;
  @Input()
  user?: User;
  @Output()
  cancel: EventEmitter<any> = new EventEmitter();

  postcodeBeforeCityEnabled: boolean;

  constructor(private featuresService: FeaturesService) {}

  resetDatesTime(date: dayjs.Dayjs): dayjs.Dayjs {
    return date.set('millisecond', 0).set('second', 0).set('minute', 0).set('hour', 0);
  }

  dateIsToday(date: dayjs.Dayjs): boolean {
    return this.resetDatesTime(date).isSame(this.resetDatesTime(dayjs(new Date())));
  }

  ngOnInit(): void {
    this.postcodeBeforeCityEnabled =
      this.featuresService.getFeature('ADDRESS_FIELDS').postcodeBeforeCity;
  }
}
