import { Injectable } from '@angular/core';
import { ProductModelService } from 'Checkout/models/product-model.service';
import { RangeProductFilter, RangeProductModelService, RangeProductParams } from 'Checkout/models/range-product-model.service';
import { Country } from 'Shared/classes/country';
import { Product } from 'Shared/classes/product';
import { Discount } from 'Shared/classes/discount';
import { ProductDetailModelService } from 'Checkout/models/product-detail-model.service';
import { ProductDetails } from 'Shared/interfaces/product-details';

export { Product } from 'Shared/classes/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(
    private productModelService: ProductModelService,
    private rangeProductModelService: RangeProductModelService,
    private productDetailsModelService: ProductDetailModelService
  ) {}

  /**
   * Get a single product
   * @param product
   * @param country
   */
  getProduct(product: Product, country: Country): Promise<Product> {
    return this.productModelService.getProduct(product, country);
  }

  /**
   * Gets the products description components and reviews
   * @param country
   * @param product
   */
  getProductDetails(country: Country, product: Product): Promise<ProductDetails> {
    return this.productDetailsModelService.getProductDetails(country, product);
  }

  /**
   * Get all
   * @param country
   */
  getAvailableProducts(country: Country, orderIndex: number, discount?: Discount, deliveryDate?: any): Promise<Product[]> {
    return this.productModelService.getAvailableProducts(country, orderIndex, discount, deliveryDate);
  }

  /**
   * Get a number of products defined by 'pageSize'
   * @param params
   */
  getAvailableRangeProducts(params: RangeProductParams): Promise<{ availableProducts: Product[]; availableFilters: RangeProductFilter[] }> {
    return this.rangeProductModelService.getAvailableRangeProducts(params);
  }

  /**
   * Get products for quick shop
   * @param country
   * @param ids
   * @param orderIndex
   * @param discount
   */
  getQuickShopProducts(country: Country, ids: number[], orderIndex: number = 0, discount?: Discount): Promise<Product[]> {
    return this.getAvailableProducts(country, orderIndex, discount).then((products): Product[] => {
      const foundProducts = [];

      ids.forEach((id): void => {
        const found = products.find((p): boolean => id === p.id);
        if (found) {
          foundProducts.push(found);
        }
      });

      return foundProducts;
    });
  }

  /**
   * Get a product that is no longer in the products API (it's probably old)
   * @param product
   */
  getOlderProduct(product: Product): Promise<Product> {
    return this.productModelService.getOlderProduct(product);
  }

  /**
   * Checks whether there are any products avaialble with a specific tag
   * @param country
   * @param tag
   */
  areAnyProductsWithTagAvailable(country: Country, tag: string): Promise<boolean> {
    return this.getAvailableProducts(country, 0).then((products): boolean =>
      products.some((product): boolean => product.tags.includes(tag))
    );
  }

  /**
   * Checks whether product is multi category - hardcoded for the purpose
   * of experiment INT_MOTHERSDAY_LABEL
   * @param collectionId
   */
  isMultiCategoryProduct(collectionId: number): boolean {
    const collectionsToExclude = [2320, 1957, 1660, 1528, 1399, 1397, 1396];
    return collectionsToExclude.includes(collectionId);
  }
}
