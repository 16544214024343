import { Injectable } from '@angular/core';
import { PurchaseModelService } from 'Checkout/models/purchase-model.service';
import { BehaviorSubject } from 'rxjs';
import { Purchase } from 'Shared/classes/purchase';
import { Order } from 'Shared/classes/order';
import { PusherModelService } from 'Checkout/models/pusher-model.service';
import { Discount } from 'Shared/classes/discount';
import { Credit } from 'Shared/classes/credit';
import { LocalStorageService } from 'Shared/services/local-storage.service';
import { DiscountService } from 'Checkout/services/discount.service';
import { Country } from 'Shared/classes/country';
import { Error } from 'Shared/classes/error';
import { t } from 'Shared/utils/translations';
import { Card, CardType } from 'Shared/classes/card';
import { BankRedirect } from 'Shared/classes/bank-redirect';
import { StripeService } from 'Shared/services/stripe.service';
import { GiftVoucher } from 'Shared/classes/gift-voucher';
import { KeyIvrBorderDashboardAuth } from 'Shared/types/backend-api/purchase-api.typings';
import { FeaturesService } from 'Shared/services/features.service';
import { CheckoutService } from 'Checkout/services/checkout.service';
import { AnalyticsService } from 'Shared/services/analytics.service';
import { PaymentIntent } from '@stripe/stripe-js';

export { Purchase, Order };

interface PurchaseServiceOptions {
  setAsCurrent?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  public purchase$ = new BehaviorSubject<Purchase>(new Purchase());
  private pollIntervalObj;

  /**
   * Constructor
   * @param purchaseModelService
   * @param pusherModelService
   * @param localStorageService
   * @param discountService
   * @param stripeService
   * @param featuresService
   * @param checkoutService
   */
  constructor(
    private purchaseModelService: PurchaseModelService,
    private pusherModelService: PusherModelService,
    private localStorageService: LocalStorageService,
    private discountService: DiscountService,
    private stripeService: StripeService,
    private featuresService: FeaturesService,
    private checkoutService: CheckoutService,
    private analyticsService: AnalyticsService
  ) {}

  /**
   * Set current purchase
   * @param purchase
   */
  public setAsCurrent(purchase: Purchase): Purchase {
    this.localStorageService.set('purchaseId', purchase.id || '');
    this.localStorageService.set('purchaseToken', purchase.token || '');
    this.purchase$.next(purchase);
    return purchase;
  }

  /**
   * Get the purchase as object, not an observable
   */
  public getPurchase(): Purchase {
    return this.purchase$.getValue();
  }

  /**
   * Get the purchase
   * @param purchase
   */
  public get(purchase: Purchase, options: PurchaseServiceOptions = {}): Promise<Purchase> {
    return this.purchaseModelService.get(purchase).then((resPurchase) => {
      resPurchase.token = resPurchase.token || purchase.token;
      return options.setAsCurrent ? this.setAsCurrent(resPurchase) : resPurchase;
    });
  }

  /**
   * Update a purchase
   * @param purchase
   * @param options
   */
  public update(purchase: Purchase, options: PurchaseServiceOptions = {}): Promise<Purchase> {
    return this.purchaseModelService.update(purchase).then((resPurchase) => {
      resPurchase.token = resPurchase.token || purchase.token;
      return options.setAsCurrent ? this.setAsCurrent(resPurchase) : resPurchase;
    });
  }

  /**
   * Validate that the purchase is still valid
   * @param purchase
   * @param card
   * NOTE: Function seems messy due to Stripe Element roll out - ideally card would not be needed here in the future
   */
  public validate(purchase: Purchase, card?: Card): Promise<boolean> {
    if (card) {
      return this.purchaseModelService.validate(purchase, card).then(() => true);
    } else {
      return this.purchaseModelService.validate(purchase).then(() => true);
    }
  }

  /**
   * Validate that the purchase is still valid
   * @param purchase
   * @param card
   */
  public restore(purchase: Purchase): Promise<Purchase> {
    return this.purchaseModelService.restore(purchase).then((resPurchase) => {
      resPurchase.token = resPurchase.token || purchase.token;
      return this.setAsCurrent(resPurchase);
    });
  }

  /**
   * Reset the purchase
   */
  public reset(): Purchase {
    return this.setAsCurrent(new Purchase());
  }

  /**
   * Apply credit to the current purchase
   * @param apply
   */
  applyCredit(apply: boolean): Promise<Purchase> {
    const purchase = this.getPurchase();

    // No need to send the whole payload here, just the minimal required
    const toUpdate = new Purchase();
    toUpdate.id = purchase.id;
    toUpdate.token = purchase.token;
    toUpdate.credit = new Credit(apply);
    toUpdate.discount = purchase.discount ? new Discount(purchase.discount.code) : undefined;
    toUpdate.giftVoucher = purchase.giftVoucher ? new GiftVoucher(purchase.giftVoucher.code) : undefined;
    return this.purchaseModelService.update(toUpdate).then((resPurchase) => {
      resPurchase.token = resPurchase.token || purchase.token;
      return this.setAsCurrent(resPurchase);
    });
  }

  /**
   * Check the discount, create a new purchase and set it
   * TODO: This might need to change due to persistent basket
   */
  checkDiscount(discount: Discount, country: Country): Promise<Purchase> {
    return this.discountService.check(discount, country).then((resDiscount) => {
      const purchase = new Purchase();
      purchase.discount = resDiscount;
      return this.setAsCurrent(purchase);
    });
  }

  /**
   * Apply a discount to the purchase
   * TODO: Maybe combine both check discount and
   * @param code
   */
  applyDiscount(code: string): Promise<Purchase> {
    const purchase = this.getPurchase();

    // As we haven't got a purchase id yet, we can just remove it here
    if (!purchase.id && code === null) {
      const current = purchase.clone();
      current.discount = null;
      current.giftVoucher = null;
      const currentPurchase = this.setAsCurrent(current);
      return Promise.resolve(currentPurchase);
    }

    const toUpdate = new Purchase();
    toUpdate.id = purchase.id;
    toUpdate.discount = new Discount(code);
    toUpdate.giftVoucher = new GiftVoucher(code);
    toUpdate.token = purchase.token;
    toUpdate.credit = purchase.credit ? new Credit(!!purchase.credit.use) : undefined;

    if (this.checkoutService.isMultiCodeRedemptionEnabled()) {
      return this.purchaseModelService.addDiscountCode(code, purchase).then((resPurchase) => {
        resPurchase.token = resPurchase.token || toUpdate.token;
        return this.setAsCurrent(resPurchase);
      });
    } else {
      return this.purchaseModelService.update(toUpdate).then((resPurchase) => {
        resPurchase.token = resPurchase.token || toUpdate.token;
        return this.setAsCurrent(resPurchase);
      });
    }
  }

  /**
   * Remove the discount from the purchase
   * @param {string} code
   * @returns {Promise<Purchase>}
   */
  removeDiscount(code: string): Promise<Purchase> {
    const purchase = this.getPurchase();

    return this.purchaseModelService.removeDiscountCode(code, purchase).then((resPurchase) => {
      resPurchase.token = resPurchase.token || purchase.token;
      return this.setAsCurrent(resPurchase);
    });
  }

  /**
   * Add an order or update a purchase with a new order to the purchase
   * @param order
   */
  addOrUpdateOrder(order: Order): Promise<Purchase> {
    const purchase = this.getPurchase();
    const toUpdate = new Purchase();

    toUpdate.id = purchase.id;
    toUpdate.discount = purchase.discount;
    toUpdate.giftVoucher = purchase.giftVoucher;
    toUpdate.giftVouchers = purchase.giftVouchers;
    toUpdate.token = purchase.token;
    toUpdate.source = purchase.source;
    toUpdate.orders.push(order);
    const promise = toUpdate.id ? this.purchaseModelService.update(toUpdate) : this.purchaseModelService.create(toUpdate);

    return promise.then((resPurchase) => {
      if (!toUpdate.id && purchase.discount?.code && this.checkoutService.isMultiCodeRedemptionEnabled()) {
        this.analyticsService.trackInHeap('codeAppliedAttempt', {
          purchase: resPurchase,
          codeEntered: purchase.discount.code
        });
        return this.purchaseModelService.addDiscountCode(purchase.discount.code, resPurchase).then((resCodePurchase) => {
          resCodePurchase.token = resPurchase.token ?? toUpdate.token;
          return this.setAsCurrent(resCodePurchase);
        });
      } else {
        resPurchase.token = resPurchase.token ?? toUpdate.token;
        return this.setAsCurrent(resPurchase);
      }
    });
  }

  /**
   * Remove an order from a purchase
   * @param order
   */
  removeOrder(order: Order): Promise<{ purchase: Purchase; errors: Error[] }> {
    const toUpdate = this.getPurchase().clone();
    const toUpdateCode = toUpdate.discount?.code || toUpdate.giftVoucher?.code;
    const toUpdateCreditUse = toUpdate?.credit?.use;

    // Collect any errors here
    const errors: Error[] = [];

    // Mark the order we want to delete
    toUpdate.orders = toUpdate.orders
      .filter((ord) => ord.id === order.id)
      .map((ord) => {
        const orderToRemove = new Order();
        orderToRemove.id = ord.id;
        orderToRemove.deleted = true;
        return orderToRemove;
      });

    return this.purchaseModelService
      .update(toUpdate)
      .then((resPurchase) => {
        resPurchase.token = resPurchase.token || toUpdate.token;
        return this.setAsCurrent(resPurchase);
      })
      .then((updatedPurchase: Purchase) => {
        const updatedPurchaseCode = updatedPurchase.discount?.code || updatedPurchase.giftVoucher?.code;
        const updatedPurchaseCreditUse = updatedPurchase?.credit?.use;

        let promise: Promise<any> = Promise.resolve(updatedPurchase);

        if (!updatedPurchaseCode && toUpdateCode !== updatedPurchaseCode) {
          promise = promise
            .then(() => this.applyDiscount(toUpdateCode))
            .catch((e) => {
              errors.push(e);
              return updatedPurchase;
            });
        }

        if (toUpdateCreditUse && !updatedPurchaseCreditUse) {
          promise = promise
            .then(() => this.applyCredit(toUpdateCreditUse))
            .catch((e) => {
              errors.push(e);
              return updatedPurchase;
            });
        }

        return promise;
      })
      .then((purchase) => ({
        purchase: purchase,
        errors: errors
      }));
  }

  /**
   * Attempt to restore a purchase
   * @param original
   */
  restorePurchase(original: Purchase): Promise<{ purchase: Purchase; errors: Error[] }> {
    let promises: Promise<any> = Promise.resolve();

    const errors: Error[] = [];

    // Clear the current purchase, as we can only have one active purchase
    const newPurchase = new Purchase();
    this.setAsCurrent(newPurchase);

    original.orders.forEach((order) => {
      order.address.id = undefined; // Reset to just the id which will create a new address

      // Reset the order Id - these are all new orders
      order.id = undefined;
      promises = promises.then(() => this.addOrUpdateOrder(order)).catch((e) => errors.push(e));
    });

    if (original.discount && original.discount.code) {
      promises = promises.then(() => this.applyDiscount(original.discount.code)).catch((e) => errors.push(e));
    }

    return promises
      .then((purchase: Purchase) => {
        if (!purchase || !purchase.orders.length) {
          return Promise.reject(false);
        }
        return Promise.resolve({
          errors,
          purchase
        });
      })
      .catch(() =>
        Promise.reject({
          errors,
          purchase: undefined
        })
      );
  }

  /**
   * Finalise the payment
   * @param purchase
   * @param card
   * we have two situations
   * 1. this.purchaseModelService.finalize() when order price 0 and if paypal and klarna
   * 2. we use initiate payment endpoint (is for stripe client secret and payment intend id) this.purchaseModelService.initiatePayment()
   */
  finalize(purchase: Purchase, card: Card, storeCardOnPaymentSuccess: boolean): Promise<any> {
    if (purchase.price.price === 0) {
      return this.purchaseModelService.finalize(purchase, card, storeCardOnPaymentSuccess);
    }

    if (['ideal', 'bancontact', 'sofort'].indexOf(card.kind) > -1) {
      const storeBankredirect = this.featuresService.getFeature('SEPA_DEBIT') ? storeCardOnPaymentSuccess : false;
      return this.intiateBankRedirect(purchase, card as BankRedirect, storeBankredirect);
    }

    const purchaseSource = (purchase.source || '').toLowerCase();
    // phone is legacy and not used anymore, the other is if paypal and klarna
    if (purchaseSource === 'phone' || (card.kind !== 'card' && card.kind !== 'sepa_debit')) {
      return this.purchaseModelService.finalize(purchase, card, storeCardOnPaymentSuccess);
    }

    return this.purchaseModelService
      .initiatePayment(purchase, card, storeCardOnPaymentSuccess)
      .then((paymentSecret) => this.stripeService.handlePayment(paymentSecret, card))
      .then((res) => {
        purchase.paymentIntentId = res && res.paymentIntent && res.paymentIntent.id ? res.paymentIntent.id : undefined;
        return this.setAsCurrent(purchase);
      });
  }

  /**
   * Finalise saved card payment
   * @param purchase
   * @param paymentMethod
   */
  finaliseSavedMethodPayment(purchase: Purchase, methodDetails: Card): Promise<any> {
    return this.stripeService.confirmSavedMethodPayment(purchase, methodDetails).then((res: PaymentIntent | Error) => {
      if (res instanceof Error) {
        return this.setAsCurrent(purchase);
      } else {
        purchase.paymentIntentId = res?.id;
        return this.setAsCurrent(purchase);
      }
    });
  }

  /**
   * Finalise the stripe element payment
   * @param purchase
   * @param paymentMethod
   */
  finaliseStripeElementPayment(purchase: Purchase, paymentMethod: CardType): Promise<any> {
    return this.stripeService.confirmStripePaymentElementPayment(paymentMethod, purchase).then((res: PaymentIntent | Error) => {
      if (res instanceof Error) {
        return this.setAsCurrent(purchase);
      } else {
        purchase.paymentIntentId = res?.id;
        return this.setAsCurrent(purchase);
      }
    });
  }

  /**
   * Intiate the bank redirect
   * @param purchase
   * @param card
   */
  intiateBankRedirect(purchase: Purchase, bankRedirect: BankRedirect, storeCardOnPaymentSuccess: boolean): Promise<any> {
    return this.purchaseModelService
      .initiatePayment(purchase, bankRedirect, storeCardOnPaymentSuccess, true)
      .then((paymentSecret: string) =>
        this.stripeService.confirmBankRedirectPayment(paymentSecret, bankRedirect.kind, bankRedirect.paymentDetails)
      );
  }

  /**
   * Get Key Ivr Token with logged agent email
   * @param agent - email
   * @param purchase
   */
  getKeyIvrToken(agent: string, purchase: Purchase): Promise<KeyIvrBorderDashboardAuth> {
    return this.purchaseModelService.startKeyIvrTransaction(agent, purchase);
  }

  /**
   * Wait until the purchase is complete
   */
  waitUntilComplete(shouldConfirm: boolean = true): Promise<Purchase> {
    const purchase = this.getPurchase();
    return Promise.race([this.pusherModelService.listenForResponse(), this.pollUntilComplete(purchase, shouldConfirm)]).then(() => {
      this.pusherModelService.stopListening();
      purchase.setState('complete');
      this.stopPolling();
      return purchase;
    });
  }

  /**
   * Poll until complete
   */
  private pollUntilComplete(
    purchase: Purchase,
    shouldConfirm: boolean = true,
    interval: number = 5000,
    maximum: number = 20
  ): Promise<boolean> {
    let counter = 0;

    // TODO: We should really be using Observables here
    return new Promise((resolve, reject) => {
      this.pollIntervalObj = setInterval(() => {
        counter = counter + 1;

        // This will 'trigger' the backend to check instead of a webhook
        if (counter === 2 && purchase.paymentIntentId && shouldConfirm) {
          this.purchaseModelService.confirm(purchase).catch(() => {});
        }

        if (counter >= maximum) {
          this.stopPolling();
          return reject(
            new Error({
              title: t('js.service.backend.network'),
              code: 'payment',
              kind: 'pollTimeout'
            })
          );
        }
        return this.purchaseModelService
          .get(purchase)
          .then((resPurchase) => {
            if (resPurchase.state === 'complete') {
              this.stopPolling();
              resolve(true);
            }
          })
          .catch((e) => {
            this.stopPolling();
            return reject(e);
          });
      }, interval);
    });
  }

  /**
   * Stop polling
   */
  private stopPolling(): void {
    if (this.pollIntervalObj) {
      clearInterval(this.pollIntervalObj);
      this.pollIntervalObj = null;
    }
  }
}
