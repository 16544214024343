import { Injectable } from '@angular/core';
import { OrderModelService } from 'Shared/models/order-model.service';
import { Order } from 'Shared/classes/order';
import { DeliveryInvoice } from 'Shared/classes/invoice';
import { APIPaginatedResponse, APIPaginationOptions } from 'Shared/types/backend-api/orders-api.typings';
import { Discount } from 'Shared/classes/discount';
import { Reason } from 'Shared/classes/reasons';
import { Product } from 'Shared/classes/product';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private orderModel: OrderModelService) {}

  /**
   * Get all
   * @param {boolean} showReferralDiscount
   */
  getAll(showReferralDiscount?: boolean): Promise<Order[]> {
    return this.orderModel
      .getAll()
      .then((orders: Order[]): Order[] => (orders ?? []).map((o): Order => this.filterReferralDiscounts(o, showReferralDiscount)));
  }

  /**
   * Get all active orders
   * @param {boolean} showReferralDiscount
   */
  getAllActive(showReferralDiscount?: boolean): Promise<Order[]> {
    return this.orderModel
      .getAllActive()
      .then((orders: Order[]): Order[] => (orders ?? []).map((o): Order => this.filterReferralDiscounts(o, showReferralDiscount)));
  }

  /**
   * Get all completed orders
   * @param {paginationOptions} (Optional) paginationOptions
   */
  getAllCompleted(paginationOptions?: APIPaginationOptions): Promise<APIPaginatedResponse<Order[]>> {
    return this.orderModel.getAllCompleted(paginationOptions);
  }

  /**
   * Get an order
   * @param order
   * @param {boolean} showReferralDiscount
   */
  get(order: Order, showReferralDiscount?: boolean): Promise<Order> {
    return this.orderModel
      .get(order)
      .then((orderResponse: Order): Order => this.filterReferralDiscounts(orderResponse, showReferralDiscount));
  }

  /**
   * Get an order without deliveries
   * @param order
   * @param {boolean} showReferralDiscount
   */
  getInfo(order: Order, showReferralDiscount?: boolean): Promise<Order> {
    return this.orderModel
      .getInfo(order)
      .then((orderResponse: Order): Order => this.filterReferralDiscounts(orderResponse, showReferralDiscount));
  }

  /**
   * Cancel an order
   * @param order
   * @param reason
   */
  cancel(order: Order, reason?: Reason, reasonText?: string): Promise<unknown> {
    return this.orderModel.cancel(order, reason, reasonText);
  }

  /**
   * Order
   * @param order
   */
  update(order: Order): Promise<Order> {
    return this.orderModel.update(order);
  }

  /**
   * Update Bouquet style
   * @param order
   * @param product
   * @returns
   */
  updateStyle(order: Order, product: Product): Promise<unknown> {
    return this.orderModel.updateStyle(order, product);
  }
  /**
   * Pause an order
   * ! Only use for subscriptions
   * @param order
   */
  pause(order: Order, reason?: Reason, reasonText?: string): Promise<unknown> {
    return this.orderModel.pause(order, reason, reasonText);
  }

  /**
   * Resume an order
   * ! Only use for subscriptions
   * @param order
   */
  resume(order: Order): Promise<Order> {
    return this.orderModel.resume(order);
  }

  /**
   * Get invoices
   * @param order
   * @param options
   */
  getInvoices(
    order: Order,
    paginationOptions?: APIPaginationOptions
  ): Promise<{ invoices: DeliveryInvoice[]; paginationOptions: APIPaginationOptions }> {
    return this.orderModel.getInvoices(order, paginationOptions);
  }

  /**
   * Redeem subscription discount code
   * ! Only use for subscriptions
   * @param order
   * @param code
   */
  redeemSubscriptionDiscount(order: Order, code: string): Promise<Discount[]> {
    return this.orderModel.redeemSubscriptionDiscount(order, code);
  }

  /**
   * Remove subscription discount code
   * ! Only use for subscriptions
   * @param order
   */
  removeSubscriptionDiscount(order: Order): Promise<{}> {
    return this.orderModel.removeSubscriptionDiscount(order);
  }

  /**
   * Filter referral discounts
   * TODO: to be removed once BE contain filter
   * @param {Order} order
   * @param {boolean} showReferralDiscount
   * @returns {Order}
   */
  private filterReferralDiscounts(order: Order, showReferralDiscount?: boolean): Order {
    if (order?.id && !showReferralDiscount) {
      order.discounts = order.discounts.filter((d): boolean => !d.referral);
    }

    return order;
  }
}
