import { Injectable } from '@angular/core';
import { WindowRefService } from 'Shared/services/window.service';
import { User } from 'Shared/classes/user';
import { Error as BwError } from 'Shared/classes/error';

@Injectable({
  providedIn: 'root'
})
export class BugsnagService {
  bugsnagClient: any;
  debug: boolean = false;

  constructor(private windowRef: WindowRefService) {
    this.debug = this.windowRef.nativeWindow.location.search.indexOf('analyticsDebug=true') > -1;
  }

  /**
   * Log
   */
  log(...args): void {
    if (this.debug) {
      console.log('<bugsnag>', ...args);
    }
  }

  getClient(): any {
    this.bugsnagClient = this.bugsnagClient || this.windowRef.nativeWindow['bugSnagClient'];
    return this.bugsnagClient;
  }

  /**
   * Log an event in bugsnag
   * @param err
   */
  logEvent(err: BwError): void {
    try {
      const client = this.getClient();
      if (client) {
        client.notify(new Error(`API/${err.code}`), {
          metaData: {
            message: err.message,
            title: err.title,
            code: err.code,
            kind: err.kind || ''
          }
        });
      }
    } catch (e) {}
  }

  setDimension(key, value): void {
    try {
      const client = this.getClient();
      if (client) {
        const obj = {};
        obj[key] = value;
        client.user = Object.assign(client.user, obj);
        this.log('dimension', obj);
      }
    } catch (e) {}
  }

  /**
   * Identidy
   * @param user
   */
  identify(user: User): void {
    try {
      if (this.bugsnagClient && user && user.slug) {
        const client = this.getClient();
        if (client && user && user.slug) {
          client.user = {
            id: user.slug
          };
          this.log('identify', user.slug);
        }
      }
    } catch (e) {}
  }
}
