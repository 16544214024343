import * as dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import { APIJSONRequest, APISerialisedJSONResponse, BackendService } from 'Shared/services/backend.service';
import { UserService } from 'Shared/services/user.service';
import { Order, Subscription } from 'Shared/classes/order';
import { AddressModelService } from 'Shared/models/address-model.service';
import { DeliveryModelService } from 'Shared/models/delivery-model.service';
import { Product } from 'Shared/classes/product';
import { CurrencyCode, Price } from 'Shared/classes/price';
import { Addon } from 'Shared/classes/addon';
import { ShippingOption } from 'Shared/classes/shipping-option';
import { Discount } from 'Shared/classes/discount';
import { ProductModelService } from 'Checkout/models/product-model.service';
import { AddonModelService } from 'Checkout/models/addon-model.service';
import { GiftCard } from 'Shared/classes/gift-card';
import { DeliveryInvoice } from 'Shared/classes/invoice';
import { APIPaginatedResponse, APIPaginationOptions, defaultAPIPaginationOptions } from 'Shared/types/backend-api/orders-api.typings';
import { Reason } from 'Shared/classes/reasons';
import { CardModelService } from 'Shared/models/card-model.service';
import { Card } from 'Shared/classes/card';

@Injectable({
  providedIn: 'root'
})
export class OrderModelService {
  constructor(
    private backend: BackendService,
    private userService: UserService,
    private deliveryModelService: DeliveryModelService,
    private addressModelService: AddressModelService,
    private addonModelService: AddonModelService
  ) {}

  /**
   * Update an order
   * @param order
   */
  public updateStyle(order: Order, product: Product): Promise<Subscription> {
    const user = this.userService.getUser();
    return this.backend.put(user, `/2024-07-10/subscriptions/${order.subscription.id}/modify-product`, {
      sku_id: product.id
    });
  }

  /**
   * To Payload - mainly used for my account as different payload required
   * @param order
   */
  public static toPayload(order: Order): APIJSONRequest<'/v2/orders/:orderId'> {
    const addr = order.address ? AddressModelService.toPayload(order.address) : undefined;

    const obj = {
      data: {
        type: 'orders',
        id: order.id,
        attributes: {
          sku_association_id: order.upsoldFrom?.id ?? undefined,
          delivery_weekday: order.subscription?.deliveryDayOfWeek ?? undefined,
          shipping_note: order.note ?? undefined,
          shipping_address_attributes: addr,
          gift_card_image_id: order.giftCard?.cover?.id ?? undefined,
          gift_message: order.giftCard?.message ?? undefined
        }
      }
    } as APIJSONRequest<'/v2/orders/:orderId'>;

    if (order.frequency || order.nextDeliveryDate || order.subscription) {
      obj.data.attributes.use_credit = order.subscription?.useCredit ?? undefined;

      obj.data.attributes.product_attributes = {
        frequency: order.frequency ?? order.subscription?.frequency ?? undefined,
        next_delivery: order.nextDeliveryDate?.format('YYYY-MM-DD') ?? undefined,
        use_credit: order.subscription?.useCredit ?? undefined,
        credit_card_id: order.subscription?.card?.id.toString() ?? undefined
      };
    }

    return obj;
  }

  /**
   * From payload
   * @param res
   */
  // tslint:disable-next-line:cyclomatic-complexity
  public fromPayload(res: APISerialisedJSONResponse<'/v2/orders/:orderId'>): Order {
    const ord = new Order();

    ord.setCreatedAt(res.created_at);
    ord.id = parseInt(res.id, 10);
    ord.purchaseId = res.purchase_id ? parseInt(res.purchase_id, 10) : undefined;
    ord.setFirstDelivery(res.first_delivery);
    ord.address = this.addressModelService.fromPayload(res.shipping_address);
    ord.note = res.shipping_note;
    ord.token = res.token;
    ord.setType(res.product_type);
    ord.applicableRulesDescription = res.applicable_rules_description;

    if (res.next_upcoming_delivery?.id) {
      ord.nextDelivery = this.deliveryModelService.fromPayload(res.next_upcoming_delivery);
    }

    // TODO: Remove next delivery or usage of selective upcoming delivery properties, use whole next delivery object
    if (res.product.next_delivery) {
      ord.setNextDelivery(res.product.next_delivery);
    }

    const { currency, total_cost_pre_discount_pennies, total_cost_pennies, delivery_on } = res.next_upcoming_delivery ?? {};
    ord.nextDeliveryPrice = currency ? new Price(currency, 1, total_cost_pre_discount_pennies, total_cost_pennies) : undefined;
    ord.nextDeliveryDate = delivery_on ? dayjs(delivery_on) : undefined;

    const state = res.product.state || res.state;
    ord.setState(state);

    ord.backendState = state;

    ord.discount =
      res.code_attributes && res.code_attributes.code
        ? new Discount(res.code_attributes.code, undefined, undefined, res.code_attributes.delivery_number_range)
        : undefined;

    ord.discounts = (res.discounts ?? [])
      .map((x) => {
        if (x.code) {
          const d = new Discount(x.code, undefined, undefined, x.delivery_number_range);
          d.codeRedemptionId = x.code_redemption_id ?? undefined;
          d.removable = x.removable;
          d.nearestApplicationMessage = x.nearest_application_message ?? undefined;
          d.redeemedOnDeliveryId = x.redeemed_on_delivery_id ?? undefined;
          d.description = x.campaign_description ?? undefined;
          d.referral = x.referral ?? false;
          return d;
        }
      })
      .filter(Boolean);

    ord.deliveries = (res.product.deliveries || [])
      .map((d) => {
        const delivery = this.deliveryModelService.fromPayload(d);
        delivery.note = delivery.note !== undefined ? delivery.note : res.shipping_note;
        delivery.product = this.mapSelectedProduct(d);
        return delivery;
      })
      .filter((d) => d.state !== 'paused');

    // The orders endpoint has a different payload shape than products api
    ord.product = ProductModelService.fromPayload({
      id: res.sku.id,
      attributes: Object.assign(res.sku, { media: res.sku_media })
    });

    // GEORGE TO IMPLEMENT TYPINGS
    // Note - the Actual product for the association is fetched within the purchase-model.service
    ord.upsoldFrom = res.sku_association_id
      ? {
          id: res.sku_association_id
        }
      : undefined;

    ord.addons = (res.addon_skus || []).map((s) => {
      const { id, ...attributes } = s as APISerialisedJSONResponse<'/v2/orders/:orderId'>['addon_skus'][number];
      const addon = this.addonModelService.fromPayload({ id, attributes });
      addon.isSelected = true;
      addon.isSelectable = false;
      return addon;
    });

    ord.firstDeliveryDate = res.first_delivery ? dayjs(res.first_delivery) : undefined;

    if (res.first_delivery_time && res.first_delivery_finish_time) {
      ord.setTimeslot(res.first_delivery_time, res.first_delivery_finish_time);
    }

    ord.isSelfPurchase = res.is_self_purchase;
    ord.isSensitive = res.is_sensitive;

    // prettier-ignore
    const greetingCardAddon = ord.addons.find((a) => ['premium_gift_card', 'standard_gift_card', 'gift_card'].indexOf(a.type) > -1);

    ord.giftCard = undefined;
    if (greetingCardAddon || res.gift_message || res.gift_card_image_id) {
      ord.giftCard = new GiftCard();
      ord.giftCard.message = res.gift_message;
      // prettier-ignore
      ord.giftCard.cover = greetingCardAddon || (res.gift_card_image_id ? new Addon(res.gift_card_image_id) : undefined);
    }

    ord.note = res.shipping_note;

    // We support both v3 purchases and v2 orders too
    ord.frequency = res.product.frequency !== undefined ? res.product.frequency : res.product_frequency;

    ord.duration = res.product.duration !== undefined ? res.product.duration : res.product_duration;
    ord.duration = ord.duration === 0 ? -1 : ord.duration; // We (Frontend) have -1 instead of 0 for on-going duration

    ord.isLilyFree = res.product.lily_free !== undefined ? res.product.lily_free : res.product_lily_free;

    ord.price = new Price(res.currency, 1, res.total_cost_pre_discount_pennies, res.total_cost_pennies);

    ord.imageUrls = (res.sku_media || []).map((m) => m.url);

    if (res.shipping_option_id) {
      ord.shippingOption = this.mapShippingOption(res.shipping_option, res.shipping_option_id) ?? undefined;
      ord.shippingOption.price = new Price(res?.shipping_option?.price_currency as CurrencyCode, 1, res?.shipping_option?.price_pennies);
    }

    if (ord.type === 'subscription' || ord.type === 'bundle') {
      const isActive = (res.product.state || '').toLowerCase() === 'active';

      const shippingOption = res.product?.shipping_option
        ? this.mapShippingOption(res.product?.shipping_option, res.product.shipping_option_id, true)
        : undefined;

      const card = new Card();
      card.id = res.product?.credit_card_id;
      card.name = res.product?.credit_card_attributes?.name ?? '';

      ord.subscription = {
        id: res.product?.id ?? undefined,
        shippingOption,
        deliveryDayOfWeek: res.product?.delivery_weekday ?? res.delivery_weekday,
        isActive: (res.product.state || '').toLowerCase() === 'active',
        duration: res.product.duration,
        frequency: res.product.frequency,
        useCredit: res.product.use_credit,
        price: res.subscription_sku_amount_pennies ? new Price(res.currency, 1, res.subscription_sku_amount_pennies) : undefined, // original price of a sub, no discounts,
        card: card?.id ? card : undefined
      };

      if (res.product?.failed_payments_data) {
        ord.subscription.failedPaymentsData = {
          pausedDueToFailedPayment: res.product.failed_payments_data.paused_due_to_failed_payment,
          activeWithFailedPayment: res.product.failed_payments_data.active_with_failed_payment,
          noValidPaymentMethod: res.product.failed_payments_data.no_valid_payment_method
        };
      }
    }

    return ord;
  }

  /**
   * Map shipping option and shipping method properties
   * @param res
   * @returns {ShippingOption}
   */
  private mapShippingOption(
    res: APISerialisedJSONResponse<'/v2/orders/:orderId'>['shipping_option'],
    id: number,
    isSubscription = false
  ): ShippingOption {
    const shippingOption = new ShippingOption();
    shippingOption.id = id ?? +res.id;
    shippingOption.name = res?.name ?? undefined;
    shippingOption.successRateMessage = res?.delivery_pill ?? undefined;

    const method = res?.shipping_method;
    shippingOption.hasPhoneNumber =
      typeof res?.show_phone_number_field !== 'undefined'
        ? res?.show_phone_number_field
        : typeof method?.show_phone_number_field !== 'undefined'
        ? method?.show_phone_number_field
        : false;

    shippingOption.hasPhoneNumberRequired =
      typeof res?.phone_number_required !== 'undefined'
        ? res?.phone_number_required
        : typeof method?.phone_number_required !== 'undefined'
        ? method?.phone_number_required
        : false;

    shippingOption.hasGiftCard =
      typeof res?.show_gift_card_fields !== 'undefined'
        ? res?.show_gift_card_fields
        : typeof method?.show_gift_card_fields !== 'undefined'
        ? method?.show_gift_card_fields
        : true;

    if (isSubscription && res?.shipping_method) {
      const method = res?.shipping_method;
      shippingOption.name = method?.display_name ?? undefined;
      shippingOption.description = method?.description ?? undefined;
      shippingOption.maxNoteLength = method?.note_length ?? undefined;
    }

    return shippingOption;
  }

  /**
   * Map product within delivery
   */
  private mapSelectedProduct(delivery: APISerialisedJSONResponse<'/v2/orders/:orderId'>['product']['deliveries'][number]): Product {
    const product: Product =
      delivery.sku_attributes && delivery.sku_attributes.is_selected ? ProductModelService.fromPayload(delivery.sku_attributes) : null;
    if (product) {
      product.imageUrls.push(delivery.sku_attributes.image_url);
    }
    return product;
  }

  /**
   * Get all orders
   */
  public getAll(): Promise<Order[]> {
    const user = this.userService.getUser();
    return this.backend
      .get(user, '/v2/orders' as '/v2/orders', {
        responseIsJsonApi: true,
        sendExperiments: true,
        params: {
          include: [
            'addon_skus',
            'sku',
            'sku.bouquet_images',
            'shipping_address',
            'shipping_option',
            'product',
            'product.deliveries',
            'product.deliveries.shipping_address'
          ].join(',')
        }
      })
      .then((res) => (res || []).map((r) => this.fromPayload(r)));
  }

  /**
   * Format paginated orders response
   * @param {APIPaginatedResponse<OrderAPIResponse[]>}
   * @return {APIPaginatedResponse<Order[]>}
   */
  private fromPagnatedOrderPayload(res: APISerialisedJSONResponse<'/v2/orders:paginated'>): APIPaginatedResponse<Order[]> {
    const { data, paginationOptions } = res;
    return {
      data: data ? data.map((r) => this.fromPayload(r)) : [],
      paginationOptions
    };
  }

  /**
   * Get all active orders
   * @returns {Promise<Order[]>}
   */
  public getAllActive(): Promise<Order[]> {
    const user = this.userService.getUser();
    return this.backend
      .get(user, '/v2/orders' as '/v2/orders', {
        requestIsJsonApi: true,
        responseIsJsonApi: true,
        sendExperiments: true,
        params: {
          'filter[order_api_state]': 'active',
          include: ['addon_skus', 'sku', 'shipping_address', 'product'].join(',')
        }
      })
      .then((res) => (res || []).map((r) => this.fromPayload(r)));
  }

  /**
   * Get all inactive orders
   * @param {APIPaginationOptions} paginationOptions
   * @returns {Promise<APIPaginatedResponse<Order[]>>}
   */
  public getAllCompleted(paginationOptions = defaultAPIPaginationOptions): Promise<APIPaginatedResponse<Order[]>> {
    const user = this.userService.getUser();
    return this.backend
      .get(user, '/v2/orders' as '/v2/orders:paginated', {
        requestIsJsonApi: true,
        responseIsJsonApi: true,
        responseIsPaginate: paginationOptions,
        sendExperiments: true,
        params: {
          'filter[order_api_state]': 'inactive',
          include: ['addon_skus', 'sku', 'shipping_address', 'product'].join(',')
        }
      })
      .then((res) => this.fromPagnatedOrderPayload(res));
  }

  /**
   * Get an order given an id
   * @param orderId
   */
  public get(order: Order): Promise<any> {
    const user = this.userService.getUser();
    return this.backend
      .get(user, `/v2/orders/${order.id}` as '/v2/orders/:orderId', {
        responseIsJsonApi: true,
        sendExperiments: true,
        params: {
          include: [
            'product',
            'addon_skus',
            'product.shipping_option.shipping_method',
            'product.deliveries.shipping_option.shipping_method',
            'product.deliveries.shipping_address',
            'sku',
            'sku.bouquet_images',
            'shipping_address',
            'shipping_option',
            'shipping_option.shipping_method'
          ].join(','),
          include_scheduled_deliveries: true
        }
      })
      .then((res) => (res ? this.fromPayload(res) : {}));
  }

  /**
   * Get order information without deliveries
   * @param order
   * @returns
   */
  public getInfo(order: Order): Promise<any> {
    const user = this.userService.getUser();
    return this.backend
      .get(user, `/v2/orders/${order.id}` as '/v2/orders/:orderId', {
        responseIsJsonApi: true,
        sendExperiments: true,
        params: {
          include: [
            'product',
            'addon_skus',
            'product.shipping_option.shipping_method',
            'sku',
            'sku.bouquet_images',
            'shipping_address',
            'shipping_option',
            'shipping_option.shipping_method'
          ].join(','),
          include_scheduled_deliveries: true
        }
      })
      .then((res) => (res ? this.fromPayload(res) : {}));
  }

  /**
   * Update an order
   * @param order
   */
  public update(order: Order): Promise<any> {
    const user = this.userService.getUser();
    return this.backend.put(user, `/v2/orders/${order.id}` as '/v2/orders/:orderId', OrderModelService.toPayload(order), {
      sendExperiments: true,
      requestIsJsonApi: true
    });
  }

  /**
   * To payload for pause
   * ! Only use for subscriptions
   * @param reason
   */
  static toPausePayload(reason?: Reason, reasonText?: string): APIJSONRequest<'/v2/orders/:orderId/pause'> {
    return reason?.id
      ? {
          data: {
            attributes: {
              reason_id: reason.id,
              additional_reason_text: reasonText
            }
          }
        }
      : {};
  }

  /**
   * Pause an order
   * ! Only use for subscriptions
   * @param order
   */
  public pause(order: Order, reason?: Reason, reasonText?: string): Promise<{}> {
    const user = this.userService.getUser();

    return this.backend.put(
      user,
      `/v2/orders/${order.id}/pause` as unknown as '/v2/orders/:orderId/pause',
      OrderModelService.toPausePayload(reason, reasonText),
      {
        sendExperiments: true
      }
    );
  }

  /**
   * To payload for resume
   * ! Only use for subscriptions
   * @param order
   */
  static toResumePayload(order: Order): APIJSONRequest<'/v2/orders/:orderId/resume'> {
    let payload = {};

    if (order.subscription || order.nextDeliveryDate) {
      payload = {
        data: {
          attributes: {
            product_attributes: {
              frequency: order.subscription?.frequency ?? undefined,
              next_delivery: order.nextDeliveryDate?.format('YYYY-MM-DD') ?? undefined,
              shipping_option_id: order.subscription?.shippingOption?.id ?? undefined
            }
          }
        }
      };
    }

    return payload;
  }

  /**
   * Resume an order
   * ! Only use for subscriptions
   * @param order
   */
  public resume(order: Order): Promise<any> {
    const user = this.userService.getUser();

    return this.backend.put(
      user,
      `/v2/orders/${order.id}/resume` as unknown as '/v2/orders/:orderId/resume',
      OrderModelService.toResumePayload(order),
      {
        sendExperiments: true
      }
    );
  }

  /**
   * To payload for cancel
   * @param order
   */
  static toCancelPayload(order: Order, reason?: Reason, reasonText?: string): APIJSONRequest<'/v2/orders/:orderId/cancel'> {
    return reason?.id
      ? {
          data: {
            type: 'orders',
            id: order.id,
            attributes: {
              reason_id: reason.id,
              additional_reason_text: reasonText
            }
          }
        }
      : {};
  }

  /**
   * Cancel subscription
   * @param order
   */
  public cancel(order: Order, reason?: Reason, reasonText?: string): Promise<{}> {
    const user = this.userService.getUser();
    return this.backend.put(
      user,
      `/v2/orders/${order.id}/cancel` as unknown as '/v2/orders/:orderId/cancel',
      OrderModelService.toCancelPayload(order, reason, reasonText),
      {
        sendExperiments: true,
        requestIsJsonApi: true
      }
    );
  }

  /**
   * Format from payload for invoices api call
   * @param res
   */
  private fromInvoicePayload(res: APISerialisedJSONResponse<'/v2/orders/:orderId/invoices'>): {
    invoices: DeliveryInvoice[];
    paginationOptions: APIPaginationOptions;
  } {
    const { data, paginationOptions } = res;

    const invoices = (data || []).map((d) => ({
      number: d?.number || null,
      url: d?.url || null,
      purchaseId: d?.purchase_id || null,
      deliveryId: d?.delivery_id || null,
      deliveryDate: dayjs(d?.delivery_date) || null,
      price: d?.currency && d?.total_cost_pennies ? new Price(d?.currency, 1, d?.total_cost_pennies) : null
    }));

    return {
      invoices,
      paginationOptions
    };
  }

  /**
   * Get all deliveries invoices on order
   * @param order
   * @param paginationOptions
   * @returns responseIsPaginate: paginationOptions
   */
  public getInvoices(
    order: Order,
    paginationOptions = defaultAPIPaginationOptions
  ): Promise<{ invoices: DeliveryInvoice[]; paginationOptions: APIPaginationOptions }> {
    const user = this.userService.getUser();

    return this.backend
      .get(user, `/v2/orders/${order.id}/invoices` as unknown as '/v2/orders/:orderId/invoices', {
        responseIsJsonApi: true,
        responseIsPaginate: paginationOptions,
        sendExperiments: true
      })
      .then((res) => (res ? this.fromInvoicePayload(res) : { invoices: [], paginationOptions: res.paginationOptions }));
  }

  /**
   * Format applied sub discount
   * ! Only use for subscriptions
   * @param param0
   * @returns Discount[]
   */
  private fromRedeemSubDiscountPayload({
    discounts
  }: APISerialisedJSONResponse<'/v1/orders/:orderId/redeem_ongoing_subscription_discount'>): Discount[] {
    return (discounts || [])
      .map((x) => {
        if (x) {
          const d = new Discount(x.code);
          d.codeRedemptionId = x.code_redemption_id;
          d.description = x.campaign_description;
          d.nearestApplicationMessage = x.nearest_application_message;
          return d;
        }
      })
      .filter(Boolean);
  }

  /**
   * Redeem subscription discount code
   * ! Only use for subscriptions
   * @param order
   * @param code
   */
  public redeemSubscriptionDiscount(order: Order, code: string): Promise<Discount[]> {
    const user = this.userService.getUser();

    return this.backend
      .post(
        user,
        `/v1/orders/${order.id}/redeem_ongoing_subscription_discount` as '/v1/orders/:orderId/redeem_ongoing_subscription_discount',
        {},
        {
          params: {
            code
          }
        }
      )
      .then((res) => this.fromRedeemSubDiscountPayload(res));
  }

  /**
   * Remove subscription discount code
   * ! Only use for subscriptions
   * @param order
   */
  public removeSubscriptionDiscount(order: Order): Promise<{}> {
    const user = this.userService.getUser();
    return this.backend.delete(user, `/v2/orders/${order.id}/discount` as '/v2/orders/:orderId/discount');
  }
}
