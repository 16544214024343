import { Injectable, HostListener } from '@angular/core';
import {
  Router,
  UrlSegmentGroup,
  NavigationEnd,
  RoutesRecognized,
  ActivatedRouteSnapshot,
  ResolveEnd,
  Route,
  CanActivate
} from '@angular/router';
import { State } from 'Shared/classes/state';
import { ActivatedState, ActivatedStateData } from 'Shared/classes/activated-state';
import { Subject } from 'rxjs';
import { WindowRefService } from 'Shared/services/window.service';

import { knownStates } from 'Environments/known-states';
import { MegaNav, MegaNavItemSection, MegaNavItemSectionLink, MegaNavItem } from 'Shared/classes/mega-nav-item';
import { NavBreadcrumbs } from 'Shared/classes/nav-breadcrumbs';
import { NavItem, NavLinks } from 'Shared/classes/nav-item';
import { VisualNavItem, VisualNavItemBreadcrumbs, VisualNavItemSublinks } from 'Shared/classes/visual-nav';

import { StateName as StateNameType, RouteDefinitions as RouteDefintionsType } from 'Shared/services/state.service.typings';
import { LinkValidationService } from 'Shared/services/link-validation.service';

export type StateName = StateNameType;
export type RouteDefinitions = RouteDefintionsType;

/**
 * Service related typings
 */
interface ActiveStateChange {
  from: ActivatedState;
  to: ActivatedState;
}

interface StateGoOptions {
  reload?: boolean;
  location?: boolean;
  skipLocationChange?: boolean;
}

export type StateGoParams<T> = T extends ActivatedState
  ? ActivatedState['data'] & ActivatedState['params']
  : T extends StateName
  ? RouteDefinitions[T]['data'] & RouteDefinitions[T]['params']
  : never;

export interface GenericRouteDefintion {
  data: any;
  params: any;
}

export interface RouteConfig {
  name: StateName | string;
  path?: string;
  pathToMatch?: string;
  data?: {
    [key: string]: any;
  };
  loadChildren?: string;
}

export class StateParams {
  expressOnly: string = '';
  tagOnly: string = '';
  type: string = '';
  bouquet: string = '';
  addon: string = '';
  tag: string = '';
  colour: string = '';
  trackingCode: string = '';
  discountCode: string = '';
  date: string = '';
  openBasket: string = '';
  confirmDate: string = '';
  filters: string = '';
  occasionTypeId: string = '';
  recipientName: string = '';
  occOfferCode: string = '';
  contentToLoad: string = '';
  modalToLaunch: string = '';
  resetToken: string = '';
  purchaseId: string = '';
  redirectTo: string = '';
  redirectToState: string = '';
  availableSkusOnly: string = '';
  ignorePersonalisation: string = '';
  countryId: string = '';
  duration: string = '';
  frequency: string = '';
  postcode: string = '';
  orderId: string = '';
  addonId: string = '';
  collectionId: string = '';
  purchaseSize: string = '';
  positionInBasket: string = '';
  lilyFree: string = '';
  emailSlug: string = '';
  emailPreference: string = '';
  code: string = '';
  orderToken: string = '';
  btn_ref: string = '';
  preview: boolean = false;
  newSpace: boolean = false;
  environment: string = '';
  experiment: string = '';
  variant: string = '';
  analyticsDebug: boolean = false;
  utm_campaign: string = '';
  utm_content: string = '';
  utm_source: string = '';
  utm_terms: string = '';
  utm_referrer: string = '';
  utm_id: string = '';
  utm_medium: string = '';
  contentSegments: string = '';
  token: string = '';
  lfa: string = '';
  source: string = '';
  slug: string = '';
  payment_intent_client_secret: string = '';
  paymentResult: string = '';
  redirectOnSuccess: string = '';
  iamCampaign: boolean = false;
  purchaseToken: string = '';
  restorePurchase: boolean = false;
  skipCarousel: boolean = false;
  showProductDetails: boolean = false;
  sortBy: string = '';
  skipInspiration: boolean = false;
  name: string = '';
  deliveryId: string = '';
  deliveryTrackingToken: string = '';
  referrer: string = '';
  skuId: string = '';
  // TODO: Remove once new products service is live
  newProductsService: boolean = false;
  productCardModalUsed: boolean = false;
  orderAction: string = '';
  // Mention me parameters
  fullname: string = '';
  email: string = '';
  referrerSource: string = '';
  showReferrals: boolean = false;
  referralCode: string = '';
  untrustworthyLoginTokenExpired: boolean = false;
  redirectStatus: string = '';
  bankRedirect: string = '';
  chargeId: string = '';
  paymentIntent: string = '';
  hasFailedPayment: boolean = false;
  isOrderPaused: boolean = false;
  device_identifier: string = '';
  source_device_type: string = '';
  showRewards: boolean = false;
}

/**
 * At the moment, this file is acting like a stub/mock in order to compile.
 *
 * There are a few things we need to do to complete this
 * - Potentially add two new router outlets, one for the header and one for the footer (not done)
 */

@Injectable({
  providedIn: 'root'
})
export class StateService {
  routeConfig: RouteConfig[];

  // Stores the full users state history, adding to the end on each change.
  history: ActivatedState[] = [];

  stateData: any = {};
  dataForNextState: any = {};

  private window: any;

  public onBefore$ = new Subject<any>();
  public onSuccess$ = new Subject<any>();
  public onAppLoad$ = new Subject<any>();
  public onAppClose$ = new Subject<any>();
  public onResolveEnd$ = new Subject<any>();
  public initial: ActivatedState;
  public isInitialPage: boolean = true;

  private to: ActivatedState;
  private current: ActivatedState;
  private from: ActivatedState;

  public activeChildNavPath: MegaNavItemSectionLink[] | MegaNavItemSection[] | MegaNavItem[];
  // If user interacts with the breacrumbs then set active visual nav path
  public activeVisualNavPath: VisualNavItemBreadcrumbs[];

  /**
   * Constructor
   */
  constructor(private linkValidation: LinkValidationService, private router: Router, private windowRef: WindowRefService) {
    this.window = windowRef.nativeWindow;

    // TODO: UNCOMMENT the below when we are ready for lazy-loading modules
    this.routeConfig = knownStates || [];
    // this.routeConfig = this.router.config as State[];

    // In order to re-load components on a URL refresh
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // Simple method to setup the event observables
    this.initEventObservables();

    window.addEventListener('beforeunload', () => {
      this.onAppClose$.next({});
    });
  }

  /**
   * On Routes Recognized, start the custom routing events
   * @param routeEvent
   */
  private onRoutesRecognized(activatedState: ActivatedState): void {
    this.to = activatedState;
    this.to.data = Object.assign({}, this.to.data, this.stateData, this.dataForNextState);

    this.dataForNextState = {};
    this.stateData = {}; // clear it down afterwards

    // Check for valid forward redirects so that if a non B&W group domain is detected,
    // we can redirect to the correct domain
    this.checkForwardRedirects(activatedState);

    if (!this.from) {
      // If params have been maniuplated during app.component, copy them across
      if (this.initial) {
        ['params', 'data', 'pathParams', 'queryParams'].forEach((p) => {
          this.to[p] = Object.assign(this.to[p], this.initial[p]);
        });
      }
      this.onAppLoad$.next({
        to: this.to
      });
    } else if (this.from) {
      this.to.from = this.from.clone();
      this.isInitialPage = false;
      this.to.data = Object.assign({}, this.to.data, { navPath: this.activeChildNavPath });

      delete this.to.from.from; // To reduce memory leaks
      delete this.from.from; // To reduce memory leaks

      this.onBefore$.next({
        from: this.from,
        to: this.to
      });
    }
  }

  private checkForwardRedirects(activatedState: ActivatedState): void {
    // Here we check all variants of the forward param to see if they are valid
    // This is because malicious attempts could be made on any of the below params
    if (activatedState.params['forward']) {
      this.sanitizeForwardParams(activatedState.params);
    }
    if (activatedState.queryParams['forward']) {
      this.sanitizeForwardParams(activatedState.queryParams);
    }
    if (this.initial?.params['forward']) {
      this.sanitizeForwardParams(this.initial?.params);
    }
    if (this.initial?.queryParams['forward']) {
      this.sanitizeForwardParams(this.initial?.queryParams);
    }
  }

  private sanitizeForwardParams(forwardObj): void {
    const validRedirect = this.linkValidation.validateLink(forwardObj['forward']);
    if (!validRedirect) {
      forwardObj['forward'] = '';
    }
  }

  /**
   * On Resolve End
   */
  private onResolveEnd(): void {
    this.onResolveEnd$.next({
      from: this.to.from ? this.to.from.clone() : undefined,
      to: this.to
    });
  }

  /**
   * On Navigation End
   */
  private onNavigationEnd(): void {
    const current = this.getTo().clone();
    this.current = current;
    this.history.push(current);
    this.onSuccess$.next({
      from: current && current.from ? current.from.clone() : undefined,
      to: this.to
    });
    this.from = current.clone();
  }

  /**
   * Init the subscribers so that other sevices can hook as similar to the ui-router as before
   */
  public initEventObservables(): void {
    this.router.events.subscribe((routeEvent) => {
      if (routeEvent instanceof RoutesRecognized) {
        const statePath = this.snapshotToStatePath(routeEvent.state.root);
        const toState = this.statePathAsActivatedState(statePath, routeEvent.state.root.firstChild.data);
        this.onRoutesRecognized(toState);
      } else if (routeEvent instanceof ResolveEnd && this.to) {
        this.onResolveEnd();
      } else if (routeEvent instanceof NavigationEnd && this.to) {
        this.onNavigationEnd();
      }
    });
  }

  /**
   * Do a fake navigation, as if it came from angular's own router
   * @param state
   */
  fakeNavigateToState(state: ActivatedState): void {
    this.onRoutesRecognized(state);
    this.onResolveEnd();
    this.onNavigationEnd();
  }

  /**
   * Scroll to an Element on the page using the anchor from Url
   */
  public scrollToElement(): void {
    setTimeout(() => {
      try {
        const queryParam = this.getCurrent().params;
        if (queryParam && queryParam['scrollTo']) {
          this.windowRef.scrollToElem(`#${queryParam['scrollTo']}`);
        }
      } catch (e) {}
    }, 1000);
  }

  /**
   * Create the route
   * @param name
   * @param url
   * @param params
   */
  createRoute(name: StateName, url: string, params: any = {}): ActivatedState {
    return new ActivatedState(name, url, {}, params);
  }

  /**
   * Convert a snapshot to an activated state
   * @param snapshot
   */
  private snapshotToStatePath(snapshot: ActivatedRouteSnapshot): State[] {
    let route = snapshot;
    const statePath = [];
    // First create a "state path" of activated routes (from parent, down to the child)
    do {
      route = route.firstChild;
      const state = new ActivatedState(
        ((route.routeConfig as State) || {}).name,
        new UrlSegmentGroup(route.url, {}).toString(),
        route.params,
        route.queryParams,
        route.data
      );
      statePath.push(state);
    } while (route.firstChild);

    return statePath;
  }

  private statePathAsActivatedState(statePath: any[], initialData: any): ActivatedState {
    // Combine the data into a master route, the child exending the parent's data/params
    const fullState = statePath.reduce(
      (acc, state) => {
        acc.name.push(state.name);
        acc.url.push(state.url);
        return {
          name: acc.name,
          data: Object.assign({}, acc.data, state.data),
          pathParams: Object.assign(acc.pathParams || {}, state.pathParams),
          queryParams: Object.assign(acc.queryParams || {}, state.queryParams),
          url: acc.url
        };
      },
      {
        name: [],
        url: []
      }
    );

    const activatedState = new ActivatedState(
      fullState.name.filter((s) => s).join('.'),
      fullState.url.filter((s) => s).join('/'),
      fullState.pathParams,
      fullState.queryParams,
      fullState.data,
      statePath
    );

    activatedState.data = Object.assign({}, initialData);

    return activatedState;
  }

  /**
   * Gets the initial (ie when the app first loads) state
   */
  getInitial<T extends StateName>(): ActivatedState<T> {
    return this.initial;
  }

  /**
   * Sets the initial state
   * @param state
   */
  setInitial(state: ActivatedState): void {
    this.initial = state;
  }

  /**
   * Given a state name and params, return a url
   * @param name
   * @param params
   */
  getUrlForState(name: StateName, params?: any): string {
    const url = this.href(name, params) || '';
    return url.substr(-1) === '/' ? url.slice(0, -1) : url; // if last character is / remove it
  }

  /**
   * Get the last state the user was on
   */
  getLastState(): ActivatedState | undefined {
    const reverseHistory = (this.history || []).slice().reverse();
    return reverseHistory[1]; // The 'current' state will be position 0;
  }

  /**
   * Gets the current State
   */
  getCurrent<T extends StateName>(): ActivatedState<T> {
    const state = this.current || this.to || this.initial;
    return state;
  }

  /**
   * Get the next state
   */
  getTo<T extends StateName>(): ActivatedState<T> {
    return this.to;
  }

  /**
   * Get the from state
   */
  getFrom<T extends StateName>(): ActivatedState<T> {
    return this.from;
  }

  /**
   * Gets the path for a given state name
   * @param state
   */
  private getPathFromStateName(state: StateName): string[] {
    // since all the content pages have the same wildcard /**  path in the router config
    // we can't use that path due to tracking issues. Hence we access the path on the this.to
    if (state === 'content') {
      const toUrl = this.to.url;
      return toUrl.split('/');
    }

    // Since homepage has no path set on the config me need to return the default homepage page manually here.
    if (state === 'homepage') {
      return ['/'];
    }

    const stateSegments = state.split('.');
    // Find the children of state if it has any
    let result = this.findChildren(stateSegments, this.routeConfig);

    // if no children found then look at the parents and find a state name that matches
    if (!result) {
      // router config contains all the routes of our site
      const foundState = this.routeConfig.find((s) => s.name === state);
      result = foundState ? [foundState] : [];
    }

    const mappedUrl = result.map((data) => data.path);
    return mappedUrl;
  }

  /**
   * given a state name or Activated State go to that state
   * @param name
   * @param params
   * @param options
   */
  go<T extends StateName | ActivatedState>(to: T, params?: StateGoParams<T>, options: StateGoOptions = {}): any {
    // If the state passed in is an "activated state" & not a state name
    if (to instanceof ActivatedState || typeof to !== 'string') {
      const toState = to as ActivatedState;
      // If RELOADING an Activated State
      if (options.reload) {
        this.router.navigated = false;
        const joinParams = Object.assign({}, toState.queryParams, params);

        return this.router.navigate(toState.url.split('/'), {
          queryParams: joinParams,
          replaceUrl: true
        });
      }

      this.setCurrentStateData(toState);

      // If NOT RELOADING
      return this.router.navigate(toState.url.split('/'), {
        replaceUrl: !!options.location,
        skipLocationChange: !!options.skipLocationChange,
        queryParams: Object.assign({}, toState.queryParams, params)
      });
    }

    // Given a state "account.orders.childern" find the path to that state
    const mappedUrl = this.getPathFromStateName(to as StateName);

    // If state returned is empty, force rediredct to 404 component.
    if (mappedUrl.length <= 0) {
      return this.router.navigate(['404']);
    }

    // Given the mappedUrl and params create a url with appropriate path params/query params & data objects
    const state = params ? this.createUrlWithParams(params, mappedUrl.join('/')) : mappedUrl;

    if (options.reload) {
      this.router.navigated = false;
      return this.router.navigate(state.url.split('/'), {
        queryParams: params,
        replaceUrl: true
      });
    }

    // So that we can attach it to the "this.to" & "this.from" properties of when route transition occurs.
    this.setCurrentStateData(state);

    return this.router.navigate(params ? state.url.split('/') : mappedUrl, {
      replaceUrl: !!options.location,
      skipLocationChange: !!options.skipLocationChange,
      queryParams: state.queryParams
    });
  }

  /**
   * Given params & a path generate a URL
   * @param params
   * @param path
   */
  private createUrlWithParams(params: any = {}, path: string): any {
    const queryParamClone = Object.assign({}, params);
    const paramKeys = Object.keys(params);
    const queryParamsOptionKeys = Object.keys(new StateParams());
    let urlWithParams = path;
    const queryParams = {};
    const data = {};
    // Step 1: First update the url path params (i.e my-account/orders/:orderId)
    paramKeys.forEach((key) => {
      // to make sure the correct param is matched. "string.replace" also does partial matches which can cause issues
      if (urlWithParams.split('/').indexOf(`:${key}`) > -1) {
        urlWithParams = urlWithParams.replace(`:${key}`, params[key]);
        delete queryParamClone[key];
      }
    });

    // Step 2: Figure out which of remaining params needs to be sorted into "query params" & "data" object.
    // Kinda a like sorting hat. (Harry Potter Reference)
    Object.keys(queryParamClone)
      .filter((key) => path.split('/').indexOf(`:${key}`) === -1)
      .forEach((options) => {
        if (queryParamsOptionKeys.indexOf(options) > -1) {
          return (queryParams[options] = params[options]);
        }

        if (queryParamsOptionKeys.indexOf(options) === -1) {
          const dataParams = (data[options] = params[options]);
          delete queryParamClone[options];
          return dataParams;
        }

        return undefined;
      });

    // return a nice object containing a url with path params, query params and data
    return {
      data,
      queryParams: queryParamClone,
      url: urlWithParams
    };
  }

  /**
   * Find the children in the states
   * @param toMatch
   * @param children
   */
  private findChildren(toMatch: String[], children: RouteConfig[]): RouteConfig[] {
    let states = children;
    const path: RouteConfig[] = [];
    for (let i = 0; i < toMatch.length; i++) {
      const found = states.find((child) => child.name === toMatch[i]);
      if (!found) {
        return undefined;
      }
      path.push(found);

      states = found['children'] || [];
    }
    return path;
  }

  /**
   * Replace the URL with the state
   * @param state
   */
  replaceURLWithState(state: ActivatedState): void {
    const url = this.href(state.name, state.params);
    this.window.history.replaceState(null, null, url);
  }

  /**
   * Go to a state that has a URL
   * @param url eg /flower-journal
   * @param params - Key value object
   */
  goToUrl(url: string, params: any = {}): Promise<any> {
    const urlTree = this.router.createUrlTree([url], { queryParams: params });
    return this.router.navigateByUrl(urlTree);
  }

  /**
   * Reload the current state
   * @param state
   * @param params
   */
  reload(): any {
    this.router.navigated = false;
    const currentState = this.getCurrent();
    // reload the state and remove all query params
    return this.router.navigate(currentState.url.split('/'), {
      queryParams: currentState.params,
      replaceUrl: true
    });
  }

  /**
   * Return the href for the state name and params
   * @param name
   * @param params
   */
  href(name: StateName, params?: any): string {
    const mappedUrl = this.getPathFromStateName(name);
    const state = this.createUrlWithParams(params, mappedUrl.join('/'));

    const remainingQueryParams = Object.keys(state.queryParams)
      .map((key) => `${key}=${state.queryParams[key]}`)
      .join('&');

    if (remainingQueryParams && remainingQueryParams.length) {
      state.url = `${state.url}?${remainingQueryParams}`;
    }

    return state.url;
  }

  /**
   * Get the current url
   */
  currentUrl(): string {
    return this.router.url;
  }

  /**
   * Add data to the next state
   * @param data
   */
  addDataToNextState(data): void {
    this.dataForNextState = Object.assign({}, this.dataForNextState, data);
  }

  /**
   * Add the data to the current state
   * @param data
   */
  addDataToCurrentState(data: any): void {
    const current = this.getCurrent(); // Changes the property "by reference"
    current.data = Object.assign({}, current.data, data);
    this.current = current;
  }

  /**
   * Add data to the "to" state
   * @param data
   */
  addDataToToState(data: any): void {
    const to = this.getTo();
    to.data = Object.assign({}, to.data, data);
  }

  /**
   * Set the data ready to be picked up later during the
   * @param state
   */
  private setCurrentStateData(state): void {
    if (state) {
      Object.assign(this.stateData, state.data);
    }
  }
}
