import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ValidationService } from 'Shared/services/validation.service';
import { OasysButtonModule, OasysFormErrorsModule, OasysFormGroupModule, OasysLayoutModule, OasysTextInputModule } from 'oasys-lib';
import { NgFor, NgIf } from '@angular/common';

import { LoadingSpinnerComponent } from 'Shared/components/loading-spinner/loading-spinner.component';

import { UserLookupService } from 'Shared/services/user-lookup.service';

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgFor,
    NgIf,
    OasysLayoutModule,
    OasysButtonModule,
    OasysFormGroupModule,
    OasysTextInputModule,
    OasysFormErrorsModule,
    LoadingSpinnerComponent
  ],
  selector: 'bw-auth-email-form',
  templateUrl: './auth-email-form.component.html'
})
export class AuthEmailFormComponent implements OnInit, OnDestroy {
  @Output() didSubmit: EventEmitter<{ isRewardsMember?: boolean; validateForm?: boolean }> = new EventEmitter<{
    isRewardsMember?: boolean;
    validateFormControl?: boolean;
  }>();
  @Output() registerNewUser: EventEmitter<{ email: string; validateForm?: boolean }> = new EventEmitter<{
    email: string;
    validateForm?: boolean;
  }>();

  form: FormGroup;
  isSubmitted: boolean = false;
  loading: boolean = false;

  constructor(private userLookupService: UserLookupService) {}

  /**
   * On Init
   */
  ngOnInit(): void {
    this.buildForm();
  }

  /**
   * On Destroy
   */
  ngOnDestroy(): void {
    this.form.markAsPristine();
    this.form.reset();
  }

  /**
   * Submit and check email by user
   */
  submit(): Promise<void> {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.isSubmitted = true;

    if (this.form.invalid) {
      return Promise.resolve();
    }
    this.loading = true;
    const email = this.form.get('email').value;

    return this.userLookupService
      .checkUserByEmail(email)
      .then(({ loyaltySchemeMembership }): void =>
        this.didSubmit.emit({
          isRewardsMember: loyaltySchemeMembership,
          validateForm: false
        })
      )
      .catch((): void => this.registerNewUser.emit({ email: email, validateForm: false }))
      .finally((): void => {
        this.loading = false;
      });
  }

  /**
   * Build form
   */
  private buildForm(): void {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, ValidationService.email()])
    });
  }
}
