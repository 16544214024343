import { NgIf, NgForOf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { OasysLayoutModule, OasysIconModule } from 'oasys-lib';

@Component({
  standalone: true,
  imports: [OasysLayoutModule, OasysIconModule, NgIf, NgForOf],
  selector: 'bw-confirmation-auth-rewards',
  templateUrl: './confirmation-auth-rewards.component.html'
})
export class ConfirmationAuthRewardsComponent {
  @Input() loyaltyWelcomePoints: number;
  @Input() loyaltyOrderPoints: number;
  @Input() showTandC: boolean = false;
}
